import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import Layout from '../views/Layout';

import LoginLayout from '../views/Login/LoginLayout';
//import LoginPage from '../views/Login/Login'
const LoginPage = () => import('../views/Login/Login');
const ForgotPasswordPage = () => import('../views/Login/ForgotPassword');

const HomePage = () => import('../views/Home/Home');

import UserList from "../views/Users/UserList.vue";

import ResetPassword from "../views/Login/ResetPassword.vue";

import ConstituentModule from './modules/constituent';

const ProfilePage = () => import('../views/Profile/Profile');
//const IndividualListDrawer = () => import('../views/Individual/IndividualListDrawer')

import ReportModule from './modules/reports';
import { auth, isAdmin } from './middlewares/auth-middleware';
import BatchModule from './modules/batch'
import ConfigModule from './modules/config'

Vue.use(VueRouter)

const routes = [
    {
        path: "",
        component: Layout,
        children: [
            {
                path: 'my-profile',
                name: 'MyProfile',
                component: ProfilePage
            },
            ConstituentModule,
            ReportModule,
            BatchModule,
            ConfigModule,
            {
                path: 'users',
                name: 'UserList',
                component: UserList,
                meta: {
                    middleware: [isAdmin]
                }
            },
            {
                path: 'config',
                name: 'Config',
                component: () => import('../views/Config/Config.vue')
            },
            {
                path: '',
                name: 'Home',
                components: {
                    default: HomePage
                }
            },
        ],
        meta: {
            middleware: [auth],
            requiresAuth: true
        }
    },
    // { path: '**', redirect: { name: 'Home' } },
    {
        path: '/login',
        component: LoginLayout,
        children: [
            {
                path: "",
                name: "Login",
                components: {
                    default: LoginPage
                }
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                component: ForgotPasswordPage
            },
            {
                path: '/resetpassword',
                name: 'ResetPassword',
                component: ResetPassword
            },
            {
                path: '/activate',
                name: 'ResetPassword',
                component: ResetPassword
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {

    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) {
        return context.next;
    }

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    store.dispatch('init').then(() => {
        var middlewares = to.matched
            .filter(x => Object.keys(x.meta).some(y => y == 'middleware'))
            .map(x => x.meta.middleware);
        middlewares = middlewares.flatMap(x => x);
        middlewares = middlewares.filter((value, index, self) => self.indexOf(value) === index);
        if (middlewares.length) {

            // const middleware =Array.isArray(to.meta.middleware)
            //     ? to.meta.middleware
            //     : [to.meta.middleware];

            const context = {
                from,
                next,
                router,
                to,
            };
            const nextMiddleware = nextFactory(context, middlewares, 1);

            return middlewares[0]({ ...context, next: nextMiddleware });
        } else {
            return next();
        }
    })
});

export default router