// import * as types from '../mutation-types'
// import * as routes from '../../router/route-names'
import i18n from '../../i18n'
import { assignIfUndefined } from "../../utils/object";


const DEFAULT_SNACKBAR = {
    closable: true,
    color: "primary",
    message: null,
    timeout: 3000,
    title: null
}

const DEFAULT_DIALOG = {
    width: "30%",
    persistent: false,
    fullscreen: false,
    transitionType: "scale-transition"
}

export const state = {
    snackbar: DEFAULT_SNACKBAR,
    dialogs: [],
};
export const getters = {
    snackbar: state => state.snackbar,
    dialogs: state => state.dialogs
};
export const mutations = {
    showSnackbar(state, { closable, color, message, timeout, title }) {
        state.snackbar = Object.assign(DEFAULT_SNACKBAR, { closable, color, message, timeout, title })
    },
    clearSnackbar(state) {
        state.snackbar.message = null
        clearTimeout(state.snackbar.timeout)

    },
    addDialog(state, { componentName, parameter, width, persistent, fullscreen, transitionType, id, title, cssClass }) {
        var dialog = assignIfUndefined({
            component: componentName,
            parameter: parameter,
            width: width,
            maxwidth: width,
            persistent,
            fullscreen,
            transitionType,
            id,
            title,
            cssClass
        }, DEFAULT_DIALOG)
        state.dialogs.push(dialog);
    },
    updateDialog(state, { id, parameter, width, persistent, fullscreen, transitionType, title }) {
        if (id == null) return;
        var dialogIndex = state.dialogs.findIndex(x => x.id == id)
        if (dialogIndex >= 0) {
            var original = Object.assign({}, state.dialogs[dialogIndex]);
            var nDialog = assignIfUndefined({ parameter, width, persistent, fullscreen, transitionType, title }, original)
            state.dialogs.splice(dialogIndex, 1, nDialog)
        }
    },
    hideDialog(state, id) {
        if (id != null) {
            var index = state.dialogs.findIndex(x => x.id == id)
            state.dialogs.splice(index, 1);
        }
        else
            state.dialogs.splice(state.dialogs.length - 1)
    }
};
export const actions = {
    addDialog({ commit }, { componentName, parameter, width, persistent, fullscreen, transitionType, title, cssClass }) {
        return new Promise((resolve, reject) => {
            var id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
            commit('addDialog', { componentName, parameter, width, persistent, fullscreen, transitionType, id, title, cssClass })

            resolve(id)
        })
    },
    showGlobalSearch({ commit, dispatch }) {
        return dispatch('addDialog', { componentName: 'dialog-global-search', persistent: true, title: i18n.t('modal.globalSearch.title'), fullscreen: false, width: '100%', cssClass: 'h-95 mt-5p' })
    }
};
