<template>
  <div>
    <v-navigation-drawer absolute temporary v-model="isOpenDrawer" height="92vh" width="25vw">
      <div class="d-flex justify-space-between report_filters-top_buttons">
        <v-btn class="mt-2" icon plain :to="{ name: 'builder' }">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div>
          <v-btn class="mt-3 mr-2" depressed color="secondary" small @click="launchSearch()">
            {{ $t("appbar.searchField.title") }}
          </v-btn>
        </div>
      </div>

      <div class="px-3 mt-12">
        <h3>{{ $t("page.reports.membership.fields") }}</h3>

        <div class="common-details_container mt-2">
          <v-row class="common_container">
            <!--#region Standing  -->
            <!-- <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.standing") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.standing.value"
                :items="booleanOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col> -->
            <!--#endregion -->
            <!--#region Program  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.program") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.program.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.program.operator == 1 || filters.program.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.program.values"
                :items="programs"
                item-text="name"
                item-value="id"
                outlined
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Category  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.category") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.category.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.category.operator == 1 || filters.category.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.category.values"
                :items="categories"
                item-text="name"
                item-value="id"
                outlined
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Transaction Type  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.transactionType") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.transactionType.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.transactionType.operator == 1 || filters.transactionType.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.transactionType.values"
                :items="transactionTypes"
                item-text="name"
                item-value="id"
                outlined
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Join Date  -->
            <v-col cols="12 pb-0">
              <span class="common_labels">{{ $t("page.reports.membership.joinDate") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.from") }}</span>
              <calendar-field v-model="filters.joinDate.startDate" :valeur="filters.joinDate.startDate" :dateFormat="dateFormat"> </calendar-field>
            </v-col>
            <v-col cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.to") }}</span>
              <calendar-field v-model="filters.joinDate.endDate" :valeur="filters.joinDate.endDate" :dateFormat="dateFormat"> </calendar-field>
            </v-col>
            <!--#endregion -->
            <!--#region Start Date  -->
            <v-col cols="12 pb-0">
              <span class="common_labels">{{ $t("page.reports.membership.startDate") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.from") }}</span>
              <calendar-field v-model="filters.startDate.startDate" :valeur="filters.startDate.startDate" :dateFormat="dateFormat"> </calendar-field>
            </v-col>
            <v-col cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.to") }}</span>
              <calendar-field v-model="filters.startDate.endDate" :valeur="filters.startDate.endDate" :dateFormat="dateFormat"> </calendar-field>
            </v-col>
            <!--#endregion -->
            <!--#region Expiry Date  -->
            <v-col cols="12 pb-0">
              <span class="common_labels">{{ $t("page.reports.membership.expiryDate") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.from") }}</span>
              <calendar-field v-model="filters.expiryDate.startDate" :valeur="filters.expiryDate.startDate" :dateFormat="dateFormat">
              </calendar-field>
            </v-col>
            <v-col cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.to") }}</span>
              <calendar-field v-model="filters.expiryDate.endDate" :valeur="filters.expiryDate.endDate" :dateFormat="dateFormat"> </calendar-field>
            </v-col>
            <!--#endregion -->
            <!--#region Dues  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.dues") }}</span>
            </v-col>
            <v-col cols="12 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.dues.operator"
                :items="numericOptions"
                item-text="name"
                item-value="id"
                outlined
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.dues.operator != 3 && filters.dues.operator != 8 && filters.dues.operator != 9" cols="12 py-0">
              <v-text-field
                class="common_txtfield"
                v-model.number="filters.dues.value"
                outlined
                @keypress.native="onlyAcceptNumber"
                dense
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col v-if="filters.dues.operator == 3" cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.from") }}</span>
              <v-text-field
                class="common_txtfield"
                v-model.number="filters.dues.lowerValue"
                outlined
                @keypress.native="onlyAcceptNumber"
                dense
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col v-if="filters.dues.operator == 3" cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.to") }}</span>
              <v-text-field
                class="common_txtfield"
                v-model.number="filters.dues.upperValue"
                outlined
                @keypress.native="onlyAcceptNumber"
                dense
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <!--#endregion -->
            <!--#region Campaign  -->
            <!-- <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.campaign") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.campaign.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.campaign.operator == 1 || filters.campaign.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.campaign.values"
                :items="campaignsList"
                item-text="name"
                item-value="id"
                outlined
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col> -->
            <!--#endregion -->
            <!--#region Fund  -->
            <!-- <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.fund") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.fund.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.fund.operator == 1 || filters.fund.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.fund.values"
                :items="fundsList"
                item-text="name"
                item-value="id"
                outlined
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col> -->
            <!--#endregion -->
            <!--#region Appeal  -->
            <!-- <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.appeal") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.appeal.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.appeal.operator == 1 || filters.appeal.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.appeal.values"
                :items="appealsList"
                item-text="name"
                item-value="id"
                outlined
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col> -->
            <!--#endregion -->
            <!--#region Package  -->
            <!-- <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.package") }}</span>
            </v-col>
            <v-col cols="6 py-0">
                <v-select
                  class="common_txtfield"
                  v-model="filters.package.operator"
                  :items="dropdownOptions"
                  item-text="name"
                  item-value="id"
                  outlined
                  clearable
                  dense
                  persistent-placeholder
                ></v-select>
            </v-col>
            <v-col v-if="filters.package.operator== 1 || filters.package.operator == 2" cols="6 py-0">
                <v-select
                  class="common_txtfield"
                  v-model="filters.package.values"
                  :items="packagesList"
                  item-text="name"
                  item-value="id"
                  outlined
                  multiple
                  dense
                  persistent-placeholder
                ></v-select>
            </v-col> -->
            <!--#endregion -->
            <!--#region Only most Recent  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.onlyShowRecentDetail") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.onlyShowRecentDetail.value"
                :items="booleanOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
          </v-row>
        </div>

        <h3>Constituent fields</h3>

        <div class="common-details_container">
          <v-row class="common_container">
            <!--#region Country  -->
            <v-col cols="12 pb-0">
              <span class="common_labels">{{ $t("page.reports.membership.country") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.country.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.country.operator == 1 || filters.country.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.country.values"
                :items="countries"
                item-text="name"
                item-value="id"
                outlined
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Language  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.language") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.preferredLanguage.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.preferredLanguage.operator == 1 || filters.preferredLanguage.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.preferredLanguage.values"
                :items="languages"
                item-text="name"
                item-value="id"
                outlined
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Const Code  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.constituentId") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.constituentCode.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.constituentCode.operator == 1 || filters.constituentCode.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.constituentCode.values"
                :items="constituentCodes"
                item-text="name"
                item-value="id"
                outlined
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.constituent.isDeceased") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.isDeceased.value"
                :items="booleanOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.constituent.isDoNotSolicit") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.isDoNotSolicit.value"
                :items="booleanOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.constituent.isGivingAnonymously") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.isGivingAnonymously.value"
                :items="booleanOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
          </v-row>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from "../../../plugins/axios";
import { orderList } from "../../../utils/functions";
import CalendarField from "../../../components/Shared/ACalendarField.vue";

export default {
  name: "ReportMembershipFilters",
  components: { CalendarField },
  props: ["drawer", "value"],
  data() {
    return {
      dropdownOptions: [],
      numericOptions: [],
      // dropdowns list
      programs: [],
      categories: [],
      transactionTypes: [],
      campaignsList: [],
      appealsList: [],
      packagesList: [],
      countries: [],
      languages: [],
      constituentCodes: []
    };
  },
  computed: {
    booleanOptions() {
      return [
        { id: 1, name: this.$t("app.common.yes") },
        { id: 2, name: this.$t("app.common.no") }
      ];
    },
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    },
    isOpenDrawer: {
      get() {
        return this.drawer;
      },
      set(v) {
        if (!v) {
          this.$emit("hideFilters");
        }
      }
    },
    filters: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  watch: {},
  methods: {
    launchSearch() {
      this.$emit("search");
      this.isOpenDrawer = false;
    },
    loadPrograms() {
      axios
        .get("/membershipProgram/selectList")
        .then(res => {
          this.programs = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadFundsList() {
      axios
        .get("/fund/selectlist")
        .then(res => {
          this.fundsList = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadCampaignsList() {
      axios
        .get("/campaign/selectlist")
        .then(res => {
          this.campaignsList = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadAppealsList() {
      axios
        .get("/appeal/selectlist")
        .then(res => {
          this.appealsList = res.data;
        })
        .catch();
    },
    loadPackagesList() {
      axios
        .get("/package/selectlist")
        .then(res => {
          this.packagesList = res.data;
        })
        .catch();
    },
    loadCategories() {
      axios
        .get("/membershipCategory/selectList")
        .then(res => {
          this.categories = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadTransactionTypes() {
      axios
        .get("/transactionType")
        .then(res => {
          this.transactionTypes = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadCountryList() {
      axios
        .get("/country")
        .then(res => {
          this.countries = res.data;
        })
        .catch();
    },
    loadLanguageList() {
      axios
        .get("/language")
        .then(res => {
          this.languages = res.data;
        })
        .catch();
    },
    loadConstituentCodeList() {
      axios
        .get("/constituentcode/selectlist")
        .then(res => {
          this.constituentCodes = res.data;
        })
        .catch();
    },
    loadDropdownOperators() {
      axios
        .get("/membership/filters/dropdown-operators")
        .then(res => {
          this.dropdownOptions = res.data;
        })
        .catch();
    },
    loadNumericOperators() {
      axios
        .get("/membership/filters/numeric-operators")
        .then(res => {
          this.numericOptions = res.data;
        })
        .catch();
    }
  },
  created() {
    this.loadPrograms();
    this.loadCategories();
    this.loadTransactionTypes();
    this.loadCountryList();
    this.loadLanguageList();
    this.loadConstituentCodeList();
    this.loadDropdownOperators();
    this.loadNumericOperators();
  }
};
</script>
