<template>
    <tr>
      <td>
        <FindConstituentDialog @updateConstituent="updateConstituent" :showFind="showFind" @showFind='showFind=!showFind'></FindConstituentDialog>
        <div @click="showFind= true" @keypress="showFind= true" style="width: 180px;">
          <v-text-field

              prepend-icon="mdi-account-search"
              v-model="constituentName"
              :rules="[v => !!v || $t('dialog.error.required')]"
              :label="$t('batch.templates.pickConstituent')"
          ></v-text-field>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-menu
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  dense
                  :rules="[v => !!v || $t('dialog.error.required')]"
                  v-model="form.date"
                  clearable
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="form.date"
            ></v-date-picker>
          </v-menu>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              style="width: auto;"
              :rules="[v => !!v || $t('dialog.error.required')]"
              dense
              item-value="id"
              item-text="name"
              v-model="form.donationTypeId"
              :loading="loading"
              :items="donationTypeIds"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              :rules="[v => !!v || $t('dialog.error.required')]"
              v-model="form.transactionSubTypeId"
              :loading="loading"
              :items="subtypes"
              item-value="id"
              item-text="name"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              item-value="id"
              item-text="name"
              v-model="form.campaignId"
              :loading="loading"
              :items="campaigns"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              v-model="form.fundId"
              :loading="loading"
              :items="funds"
              item-value="id"
              item-text="name"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              :disabled="form.campaignId === null"
              v-model="form.appealId"
              :loading="loading"
              :items="appeals"
              item-value="id"
              item-text="name"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              :disabled="form.appealId === null"
              v-model="form.packageId"
              :loading="loading"
              :items="packages"
              item-value="id"
              item-text="name"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-text-field
              :rules="[v => !!v || $t('dialog.error.required')]"
              type="number" v-model="form.amount"
              dense></v-text-field>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              v-model="form.acknowledgeStatusId"
              :rules="[v => !!v || $t('dialog.error.required')]"
              :loading="loading"
              :items="acknowledges"
              item-value="id"
              item-text="name"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-text-field
            dense type="number"
            :rules="[v => !!v || $t('dialog.error.required')]"
            v-model="form.receiptAmount">
          </v-text-field>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              :rules="[v => !!v || $t('dialog.error.required')]"
              v-model="form.receiptStatusId"
              :loading="loading"
              :items="receiptstatus"
              item-value="id"
              item-text="name"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-text-field v-model="form.receiptNumber" dense></v-text-field>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-menu
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  dense
                  clearable
                  v-model="form.receiptDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="form.receiptDate"
            ></v-date-picker>
          </v-menu>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              :rules="[v => !!v || $t('dialog.error.required')]"
              v-model="form.transactionTypeId"
              :loading="loading"
              :items="transactionTypes"
              item-value="id"
              item-text="name"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-text-field v-model="form.chequeNumber" dense></v-text-field>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-menu
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  dense
                  clearable
                  v-model="form.chequeDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="form.chequeDate"
            ></v-date-picker>
          </v-menu>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-text-field v-model="form.referenceNumber" dense></v-text-field>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-menu
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  dense
                  clearable
                  v-model="form.referenceDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="form.referenceDate"
            ></v-date-picker>
          </v-menu>
        </div>
      </td>
<!--      <td style="width: 180px;">-->
<!--        <div>-->
<!--          <v-icon @click="setShowNote()" :color="form.referenceComment ? 'secondary' : 'grey-2'">-->
<!--            mdi-notebook-check-->
<!--          </v-icon>-->
<!--        </div>-->
<!--        -->
<!--      </td>-->
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              v-model="form.letterCodeId"
              :loading="loading"
              :items="letterCodes"
              item-value="id"
              item-text="name"
          ></v-autocomplete>
        </div>
      </td>
      <td class="d-flex justify-center " style="border: none">
        <div>
          <v-checkbox
              dense
              v-model="form.isAnonymous"
          ></v-checkbox>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              :rules="[v => !!v || $t('dialog.error.required')]"
              v-model="form.budgetCodeId"
              :loading="loading"
              :items="budgetCodes"
              item-value="id"
              item-text="name"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-autocomplete
              dense
              :rules="[v => !!v || $t('dialog.error.required')]"
              v-model="form.glPostStatusId"
              :loading="loading"
              :items="glPostStatues"
              item-value="id"
              item-text="name"
          ></v-autocomplete>
        </div>
      </td>
      <td>
        <div style="width: 180px;">
          <v-menu
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  dense
                  clearable
                  v-model="form.glPostDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="form.glPostDate"
            ></v-date-picker>
          </v-menu>
        </div>
      </td>
      <td class="d-flex justify-center" style="border: none">
        <v-btn
            icon
            color="primary"
            class="ma-2 white--text"
            @click="$emit('deleteRow', form.id)"
        >
          <v-icon> mdi-delete</v-icon>
        </v-btn>
      </td>
    </tr>
</template>
<script>
import axios from "../../plugins/axios";
import FindConstituentDialog from "../Dialogs/Batch/FindConstituentDialog";

export default {
  props: {
    item: Object,
    donationTypeIds:Array,
    subtypes:Array,
    campaigns:Array,
    funds: Array,
    receiptstatus: Array,
    transactionTypes: Array,
    letterCodes: Array,
    glPostStatues: Array,
    acknowledges: Array,
    budgetCodes: Array,
  },
  components: {
    FindConstituentDialog
  },
  data(){
    return{
      form: {
        constituentId: null,
        date: null,
        donationTypeId: null,
        transactionSubTypeId: null,
        campaignId: null,
        fundId: null,
        appealId: null,
        packageId: null,
        amount: null,
        acknowledgeStatusId: null,
        receiptAmount: null,
        receiptStatusId: null,
        receiptDate: null,
        transactionTypeId: null,
        chequeNumber: null,
        chequeDate: null,
        referenceNumber: null,
        referenceDate: null,
        referenceComment: null,
        letterCodeId: null,
        budgetCodeId: null,
        isAnonymous: false,
        glPostStatusId: null,
        membershipProgramId: null,
        membershipCategoryId:null,
        membershipDetailTypeId: null,
        membershipExpireDate: null
      },
      constituentSelected: null,
      constituentName: "",
      showFind: false,
      appeals: [],
      packages: [],
      loading: false,
      valid: true,
      isSelected:false
    }
  },
  computed: {
  },
  created() {
    this.loading = true
    this.form = this.item
    this.loading = false
  },
  watch: {
    "form.campaignId"() {
      if (this.form.campaignId !== null) {
        this.loadAppeals()
      }
    },
    "form.appealId"() {
      if (this.form.appealId !== null) {
        this.loadPackages()
      }
    },
    form: {
      handler: function (newItem, oldCar) {
        this.$emit('updateItem', newItem)
      },
      deep: true
    },
    item: {
      handler: function (newItem) {
        this.form = newItem;
      },
      deep: true
    }
  },
  methods: {
    updateConstituent(constituent){
      this.form.constituentId = constituent.id
      this.constituentSelected = constituent
      this.constituentName = this.constituentSelected.name
      this.showFind = false
    },
    setShowNote() {
      this.$store.commit("batch/showNote", true);
    },
    updateNote(value) {
      this.form.referenceComment = value
    },
    loadAppeals() {
      axios
          .get('Appeal/campaign/' + this.form.campaignId)
          .then(res => {
            if (res && res.data) {
              this.appeals = res.data;
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    loadPackages() {
      axios
          .get('Package/appeal/' + this.form.appealId)
          .then(res => {
            if (res && res.data) {
              this.packages = res.data;
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
  },
}
</script>