

export const getQuarter = (d) => {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
};

export const orderList = (array, field, desc) => {
    if (array) {
        return array.sort((a, b) => {
            if (a[field] > b[field]) {
                return desc ? 1 : -1;
            }
            if (a[field] < b[field]) {
                return desc ? -1 : 1;
            }
            return 0;
        });
    }

};

export const formatFinancialAmounts = (value) => {
    return value.toLocaleString('en-US', {minimumFractionDigits: 2})
}