

/**
 * Fill an object with all non null/undefined values of source to target
 * Does not deep copy object
 * @param {object} target 
 * @param {object} source 
 * @returns {object} target filled with non null/undefined values of source
 */
export const assignNonNull = (target, source) => {
    for (var k in source) {
        var val = source[k];

        if (val == null && !(k in target)) {
            continue;
        }

        target[k] = val;
    }
    return target;
}

/**
 * Fill an object where the keys are undefined from a source object
 * Does not deep copy object
 * @param {object} target 
 * @param {object} source 
 * @returns {object} target filled with non null/undefined values of source
 */
export const assignIfUndefined = (target, source) => {

    for (var k in source) {
        var val = source[k];

        if (target[k] == undefined) {
            target[k] = val
        }
    }

    return target
}