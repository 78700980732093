<template>
  <v-dialog v-model="openDialog" width="500px" :key="openDialog" persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ $t('component.fundDetails.addCampaign') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="openDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-progress-linear
        :active="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-text class="pb-0 pt-4">
        <v-container class="common_container">
          <span class="common_labels">{{ $t('component.fundDetails.selectedCampaign') }}</span>
          <v-autocomplete
            v-model="campaignToAdd"
            :items="campaignList"
            item-text="name"
            item-value="id"
            return-object
            outlined
            dense
          >
          </v-autocomplete>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-container class="pt-0">
          <v-btn class="float-right mr-2" small color="secondary" @click="save">
            {{ $t("app.actions.save") }}
          </v-btn>
        </v-container>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from "../../plugins/axios";

export default {
  props: ['addCampaignDialog'],
  data() {
    return {
      openDialog: false,
      campaignList: [],
      campaignToAdd: {},
      loading: false
    }
  },
  computed: { 
    ...mapGetters({
      fundId: "fund/selected"
    }),
  },
  watch: { 
    openDialog(v) {
      this.$emit("input", v);
    },
    addCampaignDialog(v) {
      this.openDialog = v;
    },
  },
  methods: {
    loadCampaignSelectList() {
      this.loading = true;
      axios
        .post('campaign/search', { getActive: true })
        .then(res => {
          if(res && res.data) {
            this.campaignList = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    save() {
      const campaignId = this.campaignToAdd.id;
      this.campaignToAdd.defaultFundId = this.fundId;

      axios
        .put(`campaign/${campaignId}`, this.campaignToAdd)
        .then(res => {
          if(res && res.data) {
            this.$store.commit('fund/setCampaignKey');
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.openDialog = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    }
  },
  created() {
    this.loadCampaignSelectList();
  }
};
</script>