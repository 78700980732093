
export const state = {
    currentUser: { id: "02f7050d-0d45-45b8-b0a3-751a38f10a74", firstName: "John", lastName: 'Doe' }
};
export const mutations = {}
export const actions = {}
export const getters = {
    profile: state => state.currentUser,
    fullname: state => state.currentUser.firstName + " " + state.currentUser.lastName,
    initial: state => state.currentUser.firstName[0] + state.currentUser.lastName[0]
}