export const state = {
    selected: null,
    selectedDetail: null,
    selectedInstallment: null,
    isEdit: false,
    isDetail: false,
    isEditDetail: false,
    isEditInstallment: false,
    pledgeKey: 0,
    installmentKey: 0,
    editDialog: false,
    editDialogDetail: false,
    editDialogInstallment: false,
    editedIndex: -1,
    editedInstallmentIndex: -1,
    originalAmount: null,
    newAmount: null
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    clearEditedIndex(state) {
        state.editedIndex = -1;
    },
    clearSelectedDetail(state) {
        state.selectedDetail = null;
    },
    clearSelectedInstallment(state) {
        state.selectedInstallment = null;
    },
    setSelected(state, pledgeId) {
        state.selected = pledgeId;
    },
    setSelectedDetail(state, pledgeDetailId) {
        state.selectedDetail = pledgeDetailId;
    },
    setSelectedInstallment(state, pledgeInstallmentId) {
        state.selectedInstallment = pledgeInstallmentId;
    },
    setEditedIndex(state, editedIndex) {
        state.editedIndex = editedIndex;
    },
    setEditedInstallmentIndex(state, editedInstallmentIndex) {
        state.editedInstallmentIndex = editedInstallmentIndex;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setDetail(state, isDetail) {
        state.isDetail = isDetail;
    },
    setEditDetail(state, isEditDetail) {
        state.isEditDetail = isEditDetail;
    },
    setEditInstallment(state, isEditInstallment) {
        state.isEditInstallment = isEditInstallment;
    },
    setPledgeKey(state) {
        state.pledgeKey = state.pledgeKey + 1;
    },
    setInstallmentKey(state) {
        state.installmentKey = state.installmentKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
    setEditDialogDetail(state, editDialogDetail) {
        state.editDialogDetail = editDialogDetail;
    },
    setEditDialogInstallment(state, editDialogInstallment) {
        state.editDialogInstallment = editDialogInstallment;
    },
    setOriginalAmount(state, originalAmount) {
        state.originalAmount = originalAmount;
    },
    setNewAmount(state, newAmount) {
        state.newAmount = newAmount;
    }
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    selectedDetail: state => state.selectedDetail,
    selectedInstallment: state => state.selectedInstallment,
    isEdit: state => state.isEdit,
    isDetail: state => state.isDetail,
    isEditDetail: state => state.isEditDetail,
    isEditInstallment: state => state.isEditInstallment,
    pledgeKey: state => state.pledgeKey,
    installmentKey: state => state.installmentKey,
    editDialog: state => state.editDialog,
    editDialogDetail: state => state.editDialogDetail,
    editDialogInstallment: state => state.editDialogInstallment,
    editedIndex: state => state.editedIndex,
    editedInstallmentIndex: state => state.editedInstallmentIndex,
    originalAmount: state => state.originalAmount,
    newAmount: state => state.newAmount
}