import Vue from "vue";
import VuetifyLib from "vuetify/lib";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

VuetifyLib.config.silent = process.env.NODE_ENV == "production";

export default new Vuetify({
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#ED174C",
        primarydark: "#ae123d",
        primarylight: "#ffc5c5",
        secondary: "#1F3F78",
        secondarylight: "#008cb1",
        thirdary: "#798E9A",
        gray: "#656565",
        graylight: "#eaeef0"
      }
    }
  }
});
