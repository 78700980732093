export const state = {
    selectedConstituent: null,
    noteValue: "",
    showFind: false,
    showNote: false
};

export const mutations = {
    clearSelected(state) {
        state.selectedConstituent = null;
    },
    setSelectedConstituent(state, constituentID) {
        console.log(constituentID)
        state.selectedConstituent = constituentID;
    },
    setNoteValue(state, noteValue){
        state.noteValue = noteValue;
    },
    showFind(state, showFind) {
        state.showFind = showFind;
    },
    showNote(state, showNote) {
        state.showNote = showNote;
    },
};

export const actions = {};

export const getters = {
    selectedConstituent: state => state.selectedConstituent,
    showFind: state => state.showFind,
    showNote: state => state.showNote,
    noteValue: state => state.noteValue
}