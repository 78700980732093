<template>
  <v-card height="92vh" elevation="0" class="report_result-table_container">
    <v-row class="justify-space-between mt-0">
      <v-col cols="10">
        <v-btn class="mr-2" icon plain :to="{ name: 'builder' }">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="font-weight-bold">{{ $t("page.reports.donation.NewReportTitle") }}</span>
      </v-col>
      <v-col cols="2" class="d-flex justify-end pt-1 align-center">
        <v-btn depressed color="secondary" small @click="drawer = true">
          {{ $t("app.actions.filters") }}
        </v-btn>
        <v-btn :loading="loadingExcel" icon color="green" large @click="exportData">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <report-donation-filters @search="search" @hideFilters="drawer = false" :drawer="drawer" v-model="filters"></report-donation-filters>
    <report-donation-results class="pa-2" @changePagination="changePagination" :result="result" :loading="loading"></report-donation-results>
  </v-card>
</template>
<script>
import axios from "../../../plugins/axios";
import ReportDonationFilters from "./ReportDonationFilters.vue";
import ReportDonationResults from "./ReportDonationResults.vue";

export default {
  name: "ReportDonation",
  components: { ReportDonationFilters, ReportDonationResults },
  data() {
    return {
      result: null,
      mounted: false,
      options: {},
      drawer: false,
      loading: false,
      loadingExcel: false,

      filters: {
        transactionDate: {
          startDate: null,
          endDate: null
        },
        donationType: {
          operator: null,
          values: []
        },
        donationSubtype: {
          operator: null,
          values: []
        },
        amount: {
          operator: 3,
          value: null,
          lowerValue: null,
          upperValue: null
        },
        isAnonymous: {
          value: false
        },
        isDeceased: {
          value: false
        },
        isDoNotSolicit: {
          value: false
        },
        isGivingAnonymously: {
          value: false
        },
        isMatchingGift: false,
        acknowledgeStatus: {
          operator: null,
          values: []
        },
        campaign: {
          operator: null,
          values: []
        },
        fund: {
          operator: null,
          values: []
        },
        appeal: {
          operator: null,
          values: []
        },
        package: {
          operator: null,
          values: []
        },
        letterCode: {
          operator: null,
          values: []
        },
        budgetCode: {
          operator: null,
          values: []
        },
        glPostStatus: {
          operator: null,
          values: []
        },
        glPostDate: {
          startDate: null,
          endDate: null
        },
        receiptStatus: {
          operator: null,
          values: []
        },
        receiptAmount: {
          operator: 3,
          value: null,
          lowerValue: null,
          upperValue: null
        },
        receiptDate: {
          startDate: null,
          endDate: null
        },
        payMethod: {
          operator: null,
          values: []
        },
        country: {
          operator: null,
          values: []
        },
        preferredLanguage: {
          operator: null,
          values: []
        },
        constituentCode: {
          operator: null,
          values: []
        }
      }
    };
  },
  computed: {
    title() {
      return this.$t("page.reports.donation.titleReport");
    }
  },
  watch: {},
  methods: {
    changePagination(value) {
      this.options = value;
      if (this.mounted) {
        this.search();
      } else {
        this.mounted = true;
      }
    },
    exportData() {
      this.loadingExcel = true;
      const filters = this.buildPayload();
      axios
        .post("/donation/export", filters, { responseType: "blob" })
        .then(res => {
          const fileName = this.title;
          this.loadingExcel = false;
          this.downloadExcel(fileName, res.data);
          this.$store.commit("notification/showSnackbar", {
            message: "Success"
          });
        })
        .catch(err => {
          this.loadingExcel = false;
          console.log(err.message);
          this.$store.commit("notification/showSnackbar", {
            message: `Fail: ${err.message}`
          });
        });
    },
    downloadExcel(fileName, data) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
    search() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const sortOrder = sortDesc ? "DESC" : "ASC";
      const filters = this.buildPayload();
      axios
        .post("/donation/search", filters, { params: { page, size: itemsPerPage, sortBy, sortOrder } })
        .then(res => {
          this.result = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    buildPayload() {
      const filters = {};

      if (this.filters.isMatchingGift) {
        filters.isMatchingGift = this.filters.isMatchingGift === 1 ? true : false;
      }
      if (this.filters.isAnonymous.value) {
        filters.isAnonymous = {};
        filters.isAnonymous.value = this.filters.isAnonymous.value === 1 ? true : false;
      }
      if (this.filters.isDeceased.value) {
        filters.isDeceased = {};
        filters.isDeceased.value = this.filters.isDeceased.value === 1 ? true : false;
      }
      if (this.filters.isDoNotSolicit.value) {
        filters.isDoNotSolicit = {};
        filters.isDoNotSolicit.value = this.filters.isDoNotSolicit.value === 1 ? true : false;
      }
      if (this.filters.isGivingAnonymously.value) {
        filters.isGivingAnonymously = {};
        filters.isGivingAnonymously.value = this.filters.isGivingAnonymously.value === 1 ? true : false;
      }
      if (this.filters.transactionDate.startDate || this.filters.transactionDate.endDate) {
        filters.transactionDate = {};
        filters.transactionDate.startDate = this.filters.transactionDate.startDate;
        filters.transactionDate.endDate = this.filters.transactionDate.endDate;
      }
      if (this.filters.receiptDate.startDate || this.filters.receiptDate.endDate) {
        filters.receiptDate = {};
        filters.receiptDate.startDate = this.filters.receiptDate.startDate;
        filters.receiptDate.endDate = this.filters.receiptDate.endDate;
      }
      if (this.filters.glPostDate.startDate || this.filters.glPostDate.endDate) {
        filters.glPostDate = {};
        filters.glPostDate.startDate = this.filters.glPostDate.startDate;
        filters.glPostDate.endDate = this.filters.glPostDate.endDate;
      }

      if (this.filters.donationType.values.length != 0 && this.filters.donationType.operator) {
        filters.donationType = {};
        filters.donationType.operator = this.filters.donationType.operator;
        filters.donationType.values = this.filters.donationType.values;
      }
      if (this.filters.donationSubtype.values.length != 0 && this.filters.donationSubtype.operator) {
        filters.donationSubtype = {};
        filters.donationSubtype.operator = this.filters.donationSubtype.operator;
        filters.donationSubtype.values = this.filters.donationSubtype.values;
      }
      if (this.filters.acknowledgeStatus.values.length != 0 && this.filters.acknowledgeStatus.operator) {
        filters.acknowledgeStatus = {};
        filters.acknowledgeStatus.operator = this.filters.acknowledgeStatus.operator;
        filters.acknowledgeStatus.values = this.filters.acknowledgeStatus.values;
      }
      if (this.filters.campaign.values.length != 0 && this.filters.campaign.operator) {
        filters.campaign = {};
        filters.campaign.operator = this.filters.campaign.operator;
        filters.campaign.values = this.filters.campaign.values;
      }
      if (this.filters.fund.values.length != 0 && this.filters.fund.operator) {
        filters.fund = {};
        filters.fund.operator = this.filters.fund.operator;
        filters.fund.values = this.filters.fund.values;
      }
      if (this.filters.appeal.values.length != 0 && this.filters.appeal.operator) {
        filters.appeal = {};
        filters.appeal.operator = this.filters.appeal.operator;
        filters.appeal.values = this.filters.appeal.values;
      }
      if (this.filters.package.values.length != 0 && this.filters.package.operator) {
        filters.package = {};
        filters.package.operator = this.filters.package.operator;
        filters.package.values = this.filters.package.values;
      }
      if (this.filters.letterCode.values.length != 0 && this.filters.letterCode.operator) {
        filters.letterCode = {};
        filters.letterCode.operator = this.filters.letterCode.operator;
        filters.letterCode.values = this.filters.letterCode.values;
      }
      if (this.filters.budgetCode.values.length != 0 && this.filters.budgetCode.operator) {
        filters.budgetCode = {};
        filters.budgetCode.operator = this.filters.budgetCode.operator;
        filters.budgetCode.values = this.filters.budgetCode.values;
      }
      if (this.filters.glPostStatus.values.length != 0 && this.filters.glPostStatus.operator) {
        filters.glPostStatus = {};
        filters.glPostStatus.operator = this.filters.glPostStatus.operator;
        filters.glPostStatus.values = this.filters.glPostStatus.values;
      }
      if (this.filters.receiptStatus.values.length != 0 && this.filters.receiptStatus.operator) {
        filters.receiptStatus = {};
        filters.receiptStatus.operator = this.filters.receiptStatus.operator;
        filters.receiptStatus.values = this.filters.receiptStatus.values;
      }
      if (this.filters.payMethod.values.length != 0 && this.filters.payMethod.operator) {
        filters.payMethod = {};
        filters.payMethod.operator = this.filters.payMethod.operator;
        filters.payMethod.values = this.filters.payMethod.values;
      }
      if (this.filters.country.values.length != 0 && this.filters.country.operator) {
        filters.country = {};
        filters.country.operator = this.filters.country.operator;
        filters.country.values = this.filters.country.values;
      }
      if (this.filters.preferredLanguage.values.length != 0 && this.filters.preferredLanguage.operator) {
        filters.preferredLanguage = {};
        filters.preferredLanguage.operator = this.filters.preferredLanguage.operator;
        filters.preferredLanguage.values = this.filters.preferredLanguage.values;
      }
      if (this.filters.constituentCode.values.length != 0 && this.filters.constituentCode.operator) {
        filters.constituentCode = {};
        filters.constituentCode.operator = this.filters.constituentCode.operator;
        filters.constituentCode.values = this.filters.constituentCode.values;
      }

      if (this.filters.amount.lowerValue || this.filters.amount.upperValue) {
        filters.amount = {};
        filters.amount.operator = this.filters.amount.operator;
        filters.amount.upperValue = this.filters.amount.upperValue;
        filters.amount.lowerValue = this.filters.amount.lowerValue;
      }
      if (this.filters.amount.value) {
        filters.amount = {};
        filters.amount.operator = this.filters.amount.operator;
        filters.amount.value = this.filters.amount.value;
      }
      if (this.filters.receiptAmount.lowerValue || this.filters.receiptAmount.upperValue) {
        filters.receiptAmount = {};
        filters.receiptAmount.operator = this.filters.receiptAmount.operator;
        filters.receiptAmount.upperValue = this.filters.receiptAmount.upperValue;
        filters.receiptAmount.lowerValue = this.filters.receiptAmount.lowerValue;
      }
      if (this.filters.receiptAmount.value) {
        filters.receiptAmount = {};
        filters.receiptAmount.operator = this.filters.receiptAmount.operator;
        filters.receiptAmount.value = this.filters.receiptAmount.value;
      }

      return filters;
    }
  },
  created() {}
};
</script>
