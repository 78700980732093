<template>
  <v-list class="constituents-drawer_container">
    <v-list-item>
      <v-list-item-icon>
        <v-icon color="primary">mdi-cog-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="primary--text bold">
        {{ $t('appbar.links.configure') }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      class="constituents-drawer_items"
      exact
      exact-active-class="v-list-item--active"
      link
      :to="{ name: route.link }"
      v-for="(route, index) in entries"
      :key="index"
      :disabled="route.disabled"
    >
<!--      <v-list-item-icon>-->
<!--        <v-icon>{{ route.icon }}</v-icon>-->
<!--      </v-list-item-icon>-->
      <v-list-item-content>
        <v-list-item-title>{{ route.label }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: "ConfigMenu",
  data() {
    return {
    };
  },
  computed: {
    entries(){
      return [
        {
          label: this.$t("page.campaignConfig.title"),
          link: "configCampaing",
          disabled:false
        },
        {
          label: this.$t("page.appealConfig.title"),
          link: "configAppeal",
          disabled:false
        },
        {
          label: this.$t("page.fundConfig.title"),
          link: "configFun",
          disabled:false
        },
        {
          label: this.$t("page.packConfig.title"),
          link: "configPack",
          disabled:false
        },
        {
          label: this.$t("page.benefitConfig.title"),
          link: "configBenefit",
          disabled:false
        },
        {
          label: this.$t("page.programConfig.title"),
          link: "configProgram",
          disabled:false
        },
        {
          label: this.$t("page.categoryConfig.title"),
          link: "configCategory",
          disabled:false
        },
        {
          label: this.$t("page.eventConfig.title"),
          link: "configEvent",
          disabled:false
        },
        {
          label: this.$t("page.professionConfig.title"),
          link: "configProfession",
          disabled:false
        },
        {
          label: this.$t("page.codeConfig.title"),
          link: "configCode",
          disabled:false
        },
        {
          label: this.$t("page.contactTypeConfig.title"),
          link: "configContactType",
          disabled:false
        },
      ]
    },
    hasId() {
      let constituentId = this.$route.params["constituentId"];
      return constituentId != null && constituentId != "new";
    },
    isIndividual() {
      return this.$store.getters["constituent/constituentType"] == "Individual";
    },
    routes() {
      let r = [
        {
          text: this.$t("page.constituentDetails.drawer.bio"),
          name: "ConstituentDetails",
          icon: "mdi-account-outline"
        },
        {
          text: this.$t("page.constituentDetails.drawer.contactinfo"),
          name: "ConstituentContactInfo",
          icon: "mdi-web"
        }
      ];
      if (this.hasId) {
        r = r.concat([
          {
            text: this.$t("page.constituentDetails.drawer.addresses"),
            name: "ConstituentAddresses",
            icon: "mdi-map-marker-outline"
          },
          {
            text: this.$t("page.constituentDetails.drawer.relationships"),
            name: "ConstituentRelationships",
            icon: "mdi-family-tree"
          },
          {
            text: this.$t("page.constituentDetails.drawer.memberships"),
            name: "ConstituentMemberships",
            icon: "mdi-star-circle-outline"
          },
          {
            text: this.$t("page.constituentDetails.drawer.donations"),
            name: "ConstituentDonations",
            icon: "mdi-gift-outline"
          },
          {
            text: this.$t("page.constituentDetails.drawer.communications"),
            name: "ConstituentCommunications",
            icon: "mdi-email-outline"
          }

          // {
          //   text: this.$t("page.constituentDetails.drawer.actions"),
          //   name: "ConstituentActions",
          //   icon: "mdi-check-circle-outline"
          // },
        ]);
        if (this.isIndividual) {
          r.push({
            text: this.$t("page.constituentDetails.drawer.subscriptions"),
            name: "ConstituentSubscriptions",
            icon: "mdi-card-text-outline"
          });
          r.push({
            text: this.$t("page.constituentDetails.drawer.events"),
            name: "ConstituentActivities",
            icon: "mdi-ticket-confirmation-outline"
          });
        }
        if (this.hasId) {
          r.push(
            {
              text: this.$t("page.constituentDetails.drawer.notes"),
              name: "ConstituentNotes",
              icon: "mdi-pencil-outline"
            },
            {
              text: this.$t("page.constituentDetails.drawer.pledges"),
              name: "ConstituentPledges",
              icon: "mdi-handshake-outline"
            }
          );
        }
      }

      return r;
    }
  }
};
</script>
