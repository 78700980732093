<template>
  <v-card fluid>
    <v-progress-linear :active="isEdit && loading" indeterminate color="primary"></v-progress-linear>
    <v-card-text class="pb-0">
      <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + category.id">
        <v-row class="common_container">
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.categoryConfig.categoryName") }}</span>
            <v-text-field
                class="common_txtfield"
                v-model="category.membershipCategoryTypeName"
                outlined
                dense
                :rules="[v => !!v || $t('dialog.error.required')]"
                persistent-placeholder
            ></v-text-field>
          </v-col>
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.categoryConfig.program") }}</span>
            <v-autocomplete
              class="common_txtfield"
              v-model="category.membershipProgramId"
              :items="programs"
              item-text="name"
              item-value="id"
              outlined
              dense
              :rules="[v => !!v || $t('dialog.error.required')]"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.categoryConfig.amount") }}</span>
            <v-text-field
              class="common_txtfield"
              v-model.number="category.amount"
              @keypress.native="onlyAcceptNumber"
              outlined
              dense
              persistent-placeholder
              :rules="[v => !!v || $t('dialog.error.required')]"
            ></v-text-field>
          </v-col>
          <v-col cols="4 py-0">
            <span class="common_labels">{{ $t("page.categoryConfig.maximumMembers") }}</span>
            <v-text-field
              class="common_txtfield"
              v-model.number="category.maximumMembers"
              @keypress.native="onlyAcceptNumber"
              outlined
              dense
              persistent-placeholder
              :rules="[v => !!v || $t('dialog.error.required')]"
            ></v-text-field>
          </v-col>
          <v-col cols="3 pt-0" offset="2">
            <span class="common_labels">{{ $t("page.categoryConfig.isLifetime") }}</span>
            <v-checkbox 
              v-model="category.isLifetimeMembership"
              class="individual-bio-details_checkbox" 
              dense
              color="primary" 
            />
          </v-col>
          <v-col cols="3 pt-0">
            <span class="common_labels">{{ $t("page.categoryConfig.isActive") }}</span>
            <v-checkbox 
              v-model="category.isActive"
              class="individual-bio-details_checkbox" 
              dense
              color="primary" 
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-container class="px-0 pt-0">
        <v-btn class="float-right mr-2" small color="secondary" @click="save">
          {{ $t("app.actions.save") }}
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "../../plugins/axios";
import { orderList } from "../../utils/functions";

export default {
  data() {
    return {
      form: null,
      category: {},
      categoriesTypes: [],
      programs: [],
      benefits: [],
      isFromPickerOpen: false,
      isToPickerOpen: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "category/isEdit"
    }),
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    }
  },
  watch: {},
  methods: {
    loadMembershipCategory() {
      this.loading = true;
      const categoryId = this.$store.getters["category/selected"];
      axios
        .get(`MembershipCategory/${categoryId}`)
        .then(res => {
          if (res && res.data) {
            this.category = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    loadCategoriesTypes() {
      this.loading = true;
      axios
        .get(`MembershipCategoryType/SelectList`)
        .then(res => {
          if (res && res.data) {
            this.categoriesTypes = orderList(res.data, "name", true);
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    loadPrograms() {
      this.loading = true;
      axios
        .get(`MembershipProgram/SelectList`)
        .then(res => {
          if (res && res.data) {
            this.programs = orderList(res.data, "name", true);
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        let requestCall = null;

        if (!this.category.id) {
          requestCall = axios.post("/MembershipCategory", this.category);
        } else {
          requestCall = axios.put(`MembershipCategory/${this.category.id}`, this.category);
        }
        requestCall
          .then(res => {
            this.$store.commit("category/setCategoryKey");
            this.$store.commit("category/clearSelected");
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.$store.commit("category/setEditDialog", false);
          })
          .catch(exception => {
            const message = exception.response.data ? exception.response.data[0].brokenRuleReason : "";
            this.$store.commit("notification/showSnackbar", {
              message: `Fail: ${message}`
            });
          });
      }
    }
  },
  created() {
    this.loadCategoriesTypes();
    this.loadPrograms();
    if (this.isEdit) {
      this.loadMembershipCategory();
    } else {
      this.category.isActive = true;
    }
  }
};
</script>
