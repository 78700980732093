<template>
  <v-dialog v-model="openDialog" width="500px" :key="openDialog" persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ $t('component.campaignDetails.addAppeal') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="openDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-progress-linear
        :active="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-text class="pb-0 pt-4">
        <v-container class="common_container">
          <span class="common_labels">{{ $t('component.campaignDetails.selectedAppeals') }}</span>
          <v-autocomplete
            v-model="appealToAdd"
            :items="appealList"
            item-text="name"
            item-value="id"
            return-object
            outlined
            dense
          >
          </v-autocomplete>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-container class="pt-0">
          <v-btn class="float-right mr-2" small color="secondary" @click="save">
            {{ $t("app.actions.save") }}
          </v-btn>
        </v-container>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from "../../plugins/axios";

export default {
  props: ['addAppealDialog'],
  data() {
    return {
      openDialog: false,
      appealList: [],
      appealToAdd: {},
      loading: false
    }
  },
  computed: { 
    ...mapGetters({
      campaignId: "campaign/selected"
    }),
  },
  watch: { 
    openDialog(v) {
      this.$emit("input", v);
    },
    addAppealDialog(v) {
      this.openDialog = v;
    },
  },
  methods: {
    loadAppealSelectList() {
      this.loading = true;
      axios
        .post('appeal/search', { getActive: true })
        .then(res => {
          if(res && res.data) {
            this.appealList = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    save() {
      const appealId = this.appealToAdd.id;

      axios
        .post(`campaign/${this.campaignId}/appeal/${appealId}`)
        .then(res => {
          if(res && res.data) {
            this.$store.commit('campaign/setAppealKey');
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.openDialog = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    }
  },
  created() {
    this.loadAppealSelectList();
  }
};
</script>