export default {
    computed: {
        headers() {
            if (this.$route.meta.typeBatch ==='donations'){
                return [
                    {
                        text: this.$t("batch.form.constituent"),
                        align: 'start',
                        sortable: false,
                        value: 'constituentId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.date"),
                        align: 'start',
                        sortable: false,
                        value: 'date',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.type"),
                        align: 'start',
                        sortable: false,
                        value: 'donationTypeId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.subType"),
                        align: 'start',
                        sortable: false,
                        value: 'transactionSubTypeId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.campaign"),
                        align: 'start',
                        sortable: false,
                        value: 'campaignId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.fund"),
                        align: 'start',
                        sortable: false,
                        value: 'fundId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.appeal"),
                        align: 'start',
                        sortable: false,
                        value: 'appealId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.package"),
                        align: 'start',
                        sortable: false,
                        value: 'packageId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.amount"),
                        align: 'start',
                        sortable: false,
                        value: 'amount',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.acknowledge"),
                        align: 'start',
                        sortable: false,
                        value: 'acknowledgeStatusId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.receiptAmount"),
                        align: 'start',
                        sortable: false,
                        value: 'receiptAmount',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.receipt"),
                        align: 'start',
                        sortable: false,
                        value: 'receiptStatusId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.receiptNumber"),
                        align: 'start',
                        sortable: false,
                        value: 'receiptNumber',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    // {
                    //   text: 'Receipt Stack',
                    //   align: 'start',
                    //   sortable: false,
                    //   value: 'receiptStack',
                    //   class: "secondary--text  font-weight-bold subtitle-2"
                    // },
                    {
                        text: this.$t("batch.form.receiptDate"),
                        align: 'start',
                        sortable: false,
                        value: 'receiptDate',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.payMethod"),
                        align: 'start',
                        sortable: false,
                        value: 'transactionTypeId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.chequeNumber"),
                        align: 'start',
                        sortable: false,
                        value: 'chequeNumber',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.chequeDate"),
                        align: 'start',
                        sortable: false,
                        value: 'chequeDate',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.referenceNumber"),
                        align: 'start',
                        sortable: false,
                        value: 'referenceNumber',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.referenceDate"),
                        align: 'start',
                        sortable: false,
                        value: 'referenceDate',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    // {
                    //   text: this.$t("batch.form.notes"),
                    //   align: 'start',
                    //   sortable: false,
                    //   value: 'referenceComment',
                    //   class: "secondary--text  font-weight-bold subtitle-2"
                    // },
                    {
                        text: this.$t("batch.form.letterCode"),
                        align: 'start',
                        sortable: false,
                        value: 'letterCodeId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.anonymous"),
                        align: 'start',
                        sortable: false,
                        value: 'isAnonymous',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.budgetCode"),
                        align: 'start',
                        sortable: false,
                        value: 'budgetCodeId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.glPostStatus"),
                        align: 'start',
                        sortable: false,
                        value: 'glPostStatusId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.glPostDate"),
                        align: 'start',
                        sortable: false,
                        value: 'glPostDate',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.actions"),
                        align: 'center',
                        value: 'actions',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                ]
            } else if (this.$route.meta.typeBatch ==='membership') {
                return [
                    {
                        text: this.$t("batch.form.constituent"),
                        align: 'start',
                        sortable: false,
                        value: 'constituentId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.date"),
                        align: 'start',
                        sortable: false,
                        value: 'date',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    // {
                    //     text: this.$t("batch.form.type"),
                    //     align: 'start',
                    //     sortable: false,
                    //     value: 'donationTypeId',
                    //     class: "secondary--text  font-weight-bold subtitle-2"
                    // },
                    {
                        text: this.$t("batch.form.subType"),
                        align: 'start',
                        sortable: false,
                        value: 'transactionSubTypeId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.campaign"),
                        align: 'start',
                        sortable: false,
                        value: 'campaignId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.fund"),
                        align: 'start',
                        sortable: false,
                        value: 'fundId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.appeal"),
                        align: 'start',
                        sortable: false,
                        value: 'appealId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.package"),
                        align: 'start',
                        sortable: false,
                        value: 'packageId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.amount"),
                        align: 'start',
                        sortable: false,
                        value: 'amount',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.acknowledge"),
                        align: 'start',
                        sortable: false,
                        value: 'acknowledgeStatusId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.receipt"),
                        align: 'start',
                        sortable: false,
                        value: 'receiptStatusId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.receiptAmount"),
                        align: 'start',
                        sortable: false,
                        value: 'receiptAmount',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.payMethod"),
                        align: 'start',
                        sortable: false,
                        value: 'transactionTypeId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.chequeNumber"),
                        align: 'start',
                        sortable: false,
                        value: 'chequeNumber',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.chequeDate"),
                        align: 'start',
                        sortable: false,
                        value: 'chequeDate',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.referenceNumber"),
                        align: 'start',
                        sortable: false,
                        value: 'referenceNumber',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.referenceDate"),
                        align: 'start',
                        sortable: false,
                        value: 'referenceDate',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.letterCode"),
                        align: 'start',
                        sortable: false,
                        value: 'letterCodeId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    // {
                    //     text: this.$t("batch.form.anonymous"),
                    //     align: 'start',
                    //     sortable: false,
                    //     value: 'isAnonymous',
                    //     class: "secondary--text  font-weight-bold subtitle-2"
                    // },

                    {
                        text: this.$t("batch.form.budgetCode"),
                        align: 'start',
                        sortable: false,
                        value: 'budgetCodeId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.glPostStatus"),
                        align: 'start',
                        sortable: false,
                        value: 'glPostStatusId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.glPostDate"),
                        align: 'start',
                        sortable: false,
                        value: 'glPostDate',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.membership"),
                        align: 'start',
                        sortable: false,
                        value: 'membershipId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.membershipProgram"),
                        align: 'start',
                        sortable: false,
                        value: 'membershipProgramId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.membershipCategory"),
                        align: 'start',
                        sortable: false,
                        value: 'membershipCategoryId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.membershipTransactionType"),
                        align: 'start',
                        sortable: false,
                        value: 'membershipDetailTypeId',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.expiryDate"),
                        align: 'start',
                        sortable: false,
                        value: 'membershipExpireDate',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                    {
                        text: this.$t("batch.form.actions"),
                        align: 'center',
                        value: 'actions',
                        class: "secondary--text  font-weight-bold subtitle-2"
                    },
                ]
            } else {
                return []
            }
        },
    }
}