import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
// import { createLogger } from 'vuex'

Vue.use(Vuex);
const isProduction = ['production', 'staging'].includes(process.env.NODE_ENV)

const packageVersion = require('../../package.json').version
const packageVersionStorageKey = process.env.VUE_APP_APP_VERSION_STORAGE_KEY
const vuexStorageKey = process.env.VUE_APP_VUEX_STORAGE_KEY

/********************************************************************************
*
* SETUP FOR PERSISTENT STORAGE. REMOVES MODULE THAT DO NOT NEED TO BE PERSISTED
*
*********************************************************************************/
const persistenceExcludedModule = ['notification', 'auth']
const persistenceReducer = (e) => {

    const toPersist = { ...e }

    const keys = Object.keys(toPersist)

    for (let key of keys) {
        if (persistenceExcludedModule.includes(key)) {
            delete toPersist[key]
        }
    }
    return toPersist
}

/********************************************************************************
*
* LOAD MODULES DYNAMICALLY
*
*********************************************************************************/
const requireContext = require.context('./modules', false, /.*\.js$/)

const modules = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )
    .reduce((modules, [name, module]) => {
        if (module.namespaced === undefined) {
            module.namespaced = true
        }

        return { ...modules, [name]: module }
    }, {})

/********************************************************************************
*
* SETUP FOR PLUGINS ARRAY. CONDITIONNALY INSERTED PLUGINS ARE LISTED BELOW
*
*********************************************************************************/
const plugins = [createPersistedState({
    storage: window.localStorage, key: vuexStorageKey, reducer: persistenceReducer
})]

// if (!isProduction)
//     plugins.push(createLogger({ collapsed: true }))

/********************************************************************************
*
* CLEAN LOCAL STORAGE IF APPLICATION IS A NEW VERSION.
*
*********************************************************************************/
if (packageVersion != localStorage.getItem(packageVersionStorageKey)) {
    localStorage.setItem(packageVersionStorageKey, packageVersion)
    localStorage.removeItem(vuexStorageKey)
    if (!isProduction) {
        console.warn('VUEX Local storage has been cleared.')
    }
}

export default new Vuex.Store({
    plugins,
    modules,
    state: {
        isLoading: false,
        generalDateTimeFormat: "YYYY-MM-DD hh:mm:ss",
        generalDateFormat: "YYYY-MM-DD",
    },
    mutations: {
        setLoadingState(state, isLoading) {
            state.isLoading = isLoading;
        }
    },
    actions: {
        init({ commit, dispatch }) {
            commit('setLoadingState', false)
            return Promise.all([dispatch('auth/init')])
        },
        download(context, { blob, filename, applicationType }) {
            blob = new Blob([blob], { type: applicationType });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob)
            link.download = filename;
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            //URL.revokeObjectURL(link.href);
        }
    },
    getters: {
        isLoading: state => state.isLoading,
        generalDateTimeFormat : state => state.generalDateTimeFormat,
        generalDateFormat : state => state.generalDateFormat
    }
})