//const ReportLayout = () => import('../../views/Reports/ReportLayout')
import ReportLayout from '../../views/Reports/ReportLayout';
import ReportFinancialRec from '../../views/Reports/ReportFinancialRec';
import ReportDonation from '../../views/Reports/ReportDonation/ReportDonation.vue';
import ReportConstituent from '../../views/Reports/ReportConstituent/ReportConstituent.vue';
import ReportMembership from '../../views/Reports/ReportMembership/ReportMembership.vue';
import BuilderPage from '../../views/Reports/BuilderPage';
// const ReportBuilder = () => import('../../views/Reports/ReportBuilder')
// const ReportFinancialRec = () => import('../../views/Reports/ReportFinancialRec')

import * as Drawers from '../../utils/drawers';

// export default {
//
//     path: 'reports/Builder',
//     name: 'ReportBuilder',
//     component: ReportBuilder,
//     // children: [
//     //     {
//     //         path: 'builder',
//     //         name: 'ReportBuilder',
//     //         components: ReportBuilder,
//
//     //     }
//     // ],
//     // meta: { drawer: Drawers.ReportNav },
//
//
// }
export default {
    path: 'reports',
    name: '',
    component: ReportLayout,
    children: [
        {
            path: 'FinancialRec',
            name: 'financialRec',
            component: ReportFinancialRec,
        },
        {
            path: 'donation',
            name: 'ReportDonation',
            component: ReportDonation,
        },
        {
            path: 'constituent',
            name: 'ReportConstituent',
            component: ReportConstituent,
        },
        {
            path: 'membership',
            name: 'ReportMembership',
            component: ReportMembership,
        },
        {
            path: 'builder',
            name: 'builder',
            component: BuilderPage,
        }
    ],
};