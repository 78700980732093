import axios from '../../plugins/axios'

export const state = {
    activities: [],
    packages: [],
    roles: [],
    selected: {}
};
export const mutations = {
    clearSelected(state) {
        state.selected = {}
    },
    setActivities(state, activities) {
        if (activities == null) {
            activities = []
        }
        state.activities = activities.sort((a, b) => a.name > b.name)
    },
    setPackages(state, packages) {
        if (packages == null) {
            packages = []
        }
        state.packages = packages.sort((a, b) => a.name > b.name)
    },
    setRoles(state, roles) {
        if (roles == null) {
            roles = []
        }
        state.roles = roles.sort((a, b) => a.name > b.name)
    },
    setSelected(state, activity) {
        if (activity == null) {
            activity = {}
        }

        state.selected = activity
    }
}
export const actions = {
    loadActivities({ commit }) {
        return new Promise((resolve, reject) => {

            axios.get('activity').then((res) => {
                const activities = res.data
                commit('setActivities', activities)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    loadActivity({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios.get(`activity/${id}`)
                .then(res => {
                    const activity = res.data
                    commit('setSelected', activity)
                    resolve()
                }).catch((e) => {
                    commit('clearSelected')
                    reject()
                })
        })
    },
    loadPackages({ commit }) {
        return new Promise((resolve, reject) => {
            console.warn('TODO: Load packages (activity/loadPackages)')
            let packages = [{ id: 1, name: 'On-Site' }, { id: 2, name: 'Day pass' }, { id: 3, name: 'Complimentary' }]
            // axios.get('activity/packages').then(res => {
            //     let packages = res.data
            commit('setPackages', packages)
            resolve()
            // }).catch(e => reject(e))
        })
    },
    loadRoles({ commit }) {

        return new Promise((resolve, reject) => {

            console.warn('TODO: Load roles (activity/loadRoles)')
            let roles = [{ id: 1, name: 'Attendee' }, { id: 2, name: 'Speaker' }, { id: 3, name: 'Volunteer' }]
            // axios.get('activity/roles').then(res => {
            //     let roles = res.data
            commit('setRoles', roles)
            resolve()
            // }).catch(e => reject(e))
        })
    },
    saveActivity({ dispatch }, { id, name, description, fromDate, toDate }) {
        return new Promise((resolve, reject) => {
            const toSave = { id, name, description, fromDate, toDate }
            var operation = null;
            if (id == null)
                operation = axios.post('activity', toSave)
            else {
                operation = axios.put('activity', toSave)
            }

            operation.then(() => {
                dispatch('loadActivities')
                resolve()
            }).catch(() => {
                reject()
            })
        })
    }
}
export const getters = {
    activities: state => state.activities,
    packages: state => state.packages,
    roles: state => state.roles,
    selected: state => state.selected
}