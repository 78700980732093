<template>
  <v-card fluid>
    <v-progress-linear :active="isEdit && loading" indeterminate color="primary"></v-progress-linear>
    <v-card-text class="pb-0">
      <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + profession.id">
        <v-row class="common_container">
          <v-col cols="8 pb-0">
            <span class="common_labels">{{ $t("page.professionConfig.name") }}</span>
            <v-text-field
              class="common_txtfield"
              v-model="profession.name"
              outlined
              dense
              required
              :rules="[v => !!v || $t('dialog.error.required')]"
              persistent-placeholder
            ></v-text-field>
          </v-col>
          <v-col offset="1" cols="3 pb-0">
            <span class="common_labels">{{ $t("page.professionConfig.active") }}</span>
            <v-checkbox class="common_txtfield" dense color="secondary" v-model="profession.isActive" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-container class="px-0 pt-0">
        <v-btn class="float-right mr-2" small color="secondary" @click="save">
          {{ $t("app.actions.save") }}
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "../../plugins/axios";

export default {
  data() {
    return {
      form: null,
      profession: {},
      isFromPickerOpen: false,
      isToPickerOpen: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "profession/isEdit"
    }),
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    }
  },
  watch: {},
  methods: {
    loadMembershipProfessions() {
      this.loading = true;
      const professionId = this.$store.getters["profession/selected"];
      axios
        .get(`Profession/${professionId}`)
        .then(res => {
          if (res && res.data) {
            this.profession = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        let requestCall = null;

        if (!this.profession.id) {
          requestCall = axios.post("/Profession", this.profession);
        } else {
          requestCall = axios.put(`Profession/${this.profession.id}`, this.profession);
        }
        requestCall
          .then(res => {
            this.$store.commit("profession/setProfessionKey");
            this.$store.commit("profession/clearSelected");
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.$store.commit("profession/setEditDialog", false);
          })
          .catch(exception => {
            const message = exception.response.data ? exception.response.data[0].brokenRuleReason : "";
            this.$store.commit("notification/showSnackbar", {
              message: `Fail: ${message}`
            });
          });
      }
    }
  },
  created() {
    if (this.isEdit) {
      this.loadMembershipProfessions();
    } else {
      this.profession.isActive = true;
    }
  }
};
</script>
