export const state = {
    selected: null,
    isEdit: false,
    isDetail: false,
    recurringGiftKey: 0,
    installmentKey: 0,
    editDialog: false,
    editedIndex: -1,
    originalAmount: null,
    newAmount: null
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    clearEditedIndex(state) {
        state.editedIndex = -1;
    },
    setSelected(state, recurringGiftId) {
        state.selected = recurringGiftId;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setDetail(state, isDetail) {
        state.isDetail = isDetail;
    },
    setRecurringGiftKey(state) {
        state.recurringGiftKey = state.recurringGiftKey + 1;
    },
    setInstallmentKey(state) {
        state.installmentKey = state.installmentKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
    setOriginalAmount(state, originalAmount) {
        state.originalAmount = originalAmount;
    },
    setNewAmount(state, newAmount) {
        state.newAmount = newAmount;
    }
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    isEdit: state => state.isEdit,
    isDetail: state => state.isDetail,
    recurringGiftKey: state => state.recurringGiftKey,
    installmentKey: state => state.installmentKey,
    editDialog: state => state.editDialog,
    editDialogDetail: state => state.editDialogDetail,
    editedIndex: state => state.editedIndex,
    originalAmount: state => state.originalAmount,
    newAmount: state => state.newAmount
}