<template>
  <v-card fluid>
    <v-progress-linear :active="isEdit && loading" indeterminate color="primary"></v-progress-linear>
    <v-card-text class="pb-0">
      <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + campaign.id">
        <v-row class="common_container">
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.campaignConfig.name") }}</span>
            <v-text-field
                class="common_txtfield"
                v-model="campaign.name"
                outlined
                dense
                required
                :rules="[v => !!v || $t('dialog.error.required')]"
                persistent-placeholder
            ></v-text-field>
          </v-col>
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.campaignConfig.category") }}</span>
            <v-select
                clearable
                class="common_txtfield"
                v-model="campaign.campaignCategoryId"
                :items="categories"
                item-text="name"
                item-value="id"
                outlined
                dense
                persistent-placeholder
            ></v-select>
          </v-col>
          <v-col cols="2 pb-0">
            <span class="common_labels">{{ $t("page.campaignConfig.active") }}</span>
            <v-checkbox class="common_checkbox" dense color="primary" v-model="campaign.isActive"/>
          </v-col>
          <v-col cols="12 py-0">
            <span class="common_labels">{{ $t("page.campaignConfig.description") }}</span>
            <v-text-field class="common_txtfield" v-model="campaign.description" outlined dense
                          persistent-placeholder></v-text-field>
          </v-col>
          <v-col cols="4 py-0">
            <span class="common_labels">{{ $t("page.campaignConfig.startDate") }}</span>
            <calendar-field v-model="campaign.startDate" :valeur="campaign.startDate"
                            :dateFormat="dateFormat"></calendar-field>
          </v-col>
          <v-col cols="4 py-0">
            <span class="common_labels">{{ $t("page.campaignConfig.endDate") }}</span>
            <calendar-field v-model="campaign.endDate" :valeur="campaign.endDate" :dateFormat="dateFormat"
                            :rules="endDateRule(campaign.endDate)">
            </calendar-field>
          </v-col>
          <v-col cols="12 py-0">
            <span class="common_labels">{{ $t("page.campaignConfig.notes") }}</span>
            <v-textarea v-model="campaign.comment" outlined dense class="common_txtfield"
                        persistent-placeholder></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-container class="px-0 pt-0">
        <v-btn class="float-right mr-2" small color="secondary" @click="save">
          {{ $t("app.actions.save") }}
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "../../plugins/axios";
import CalendarField from "../Shared/ACalendarField.vue";
import {orderList} from "../../utils/functions";

export default {
  components: {CalendarField},
  data() {
    return {
      form: null,
      categories: [],
      campaign: {},
      isFromPickerOpen: false,
      isToPickerOpen: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "campaign/isEdit"
    }),
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    }
  },
  watch: {},
  methods: {
    loadCampaign() {
      this.loading = true;
      const campaignId = this.$store.getters["campaign/selected"];
      axios
          .get(`campaign/${campaignId}`)
          .then(res => {
            if (res && res.data) {
              this.campaign = res.data;
              this.loading = false;
            }
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail"
            });
          });
    },
    loadCategories() {
      axios
          .get("campaigncategory/selectlist")
          .then(res => {
            this.categories = orderList(res.data, "name", true);
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail to load the categories"
            });
          });
    },
    formatAmount() {
      if (this.campaign.overall != undefined && this.campaign.overall != null) this.campaign.overall = parseFloat(this.campaign.overall).toFixed(2);
    },
    save() {
      if (this.$refs.form.validate()) {
        let requestCall = null;

        if (!this.campaign.id) {
          requestCall = axios.post("/campaign", this.campaign);
        } else {
          requestCall = axios.put(`campaign/${this.campaign.id}`, this.campaign);
        }
        requestCall
            .then(res => {
              this.$store.commit("campaign/setCampaignKey");
              this.$store.commit("campaign/clearSelected");
              this.$store.commit("notification/showSnackbar", {
                message: "Success"
              });
              this.$store.commit("campaign/setEditDialog", false);
            })
            .catch(exception => {
              const message = exception.response.data ? exception.response.data[0].brokenRuleReason : "";
              this.$store.commit("notification/showSnackbar", {
                message: `Fail: ${message}`
              });
            });
      }
    },
    endDateRule(v) {
      return [
        v => {
          if (
              this.campaign.startDate != null &&
              this.campaign.endDate != null &&
              (this.$moment(this.campaign.endDate).isBefore(this.campaign.startDate) ||
                  this.$moment(this.campaign.endDate).isSame(this.campaign.startDate))
          ) {
            return this.$t("dialog.membershipdialog.endDateAfterError");
          } else return true;
        }
      ];
    }
  },
  created() {
    this.loadCategories();
    if (this.isEdit) {
      this.loadCampaign();
    } else {
      this.campaign.isActive = true;
    }
  }
};
</script>
