<template>
  <div>
    <v-card outlined class="mt-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <span class="text-uppercase pr-2 ma-auto">{{ $t("app.actions.filter") }}:</span>
            <v-btn elevation="0" small @click="onToggleShowActive" class="mr-2" :class="showActive ? 'selected' : ''">
              {{ $t("app.actions.showActive") }}
            </v-btn>
            <v-btn elevation="0" small @click="onToggleShowInactive" :class="showInactive ? 'selected' : ''">
              {{ $t("app.actions.showInactive") }}
            </v-btn>
            <v-spacer />
            <v-btn color="secondary" small @click="onAddBenefit">{{ $t("app.actions.add") }}</v-btn>
          </v-col>
        </v-row>
        <v-data-table :items="benefits" item-key="id" :headers="headers" :loading="loading" @dblclick:row="onClickDetail" :items-per-page="10">
          <template v-slot:item.isActive="{ item }">
            <v-icon x-small :class="item.isActive ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
          <template v-slot:item.action="{ item }">
            <slot name="row-action">
              <v-icon small class="" @click="onClickEdit(null, item)">mdi-pencil</v-icon>
               <v-icon small @click="onClickDelete(null, item)">mdi-delete</v-icon>
            </slot>
          </template>
        </v-data-table>
        <edit-benefit-dialog v-model="editDialog" @close="dialog = false"></edit-benefit-dialog>
      </v-card-text>
    </v-card>
    <confirm-dialog :dialog="deleteDialog" @btnClicked="hasConfirmedDelete($event)"></confirm-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EditBenefitDialog from "../../components/ConfigBenefit/EditBenefitDialog.vue";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog.vue";
import axios from "../../plugins/axios";

export default {
  components: { EditBenefitDialog, ConfirmDialog },
  data() {
    return {
      editDialog: false,
      deleteDialog: false,
      isEdit: false,
      benefits: [],
      benefitIdToDelete: null,
      loading: false,
      showInactive: false,
      showActive: true,
    };
  },
  computed: {
    ...mapGetters({
      benefitKey: "benefit/benefitKey",
    }),
    headers() {
      return [
        {
          text: this.$t("page.benefitConfig.name"),
          value: "name",
        },
        // {
        //   text: this.$t("page.benefitConfig.description"),
        //   value: "description",
        // },
        {
          text: this.$t("page.benefitConfig.amount"),
          value: "unitCost",
          align: "center"
        },
        {
          text: this.$t("page.benefitConfig.active"),
          value: "isActive",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("app.common.actions"),
          value: "action",
          width: "10%",
          sortable: false,
          align: "center",
        },
      ];
    },
  },
  watch: {
    benefitKey() {
      this.loadBenefits();
    },
  },
  methods: {
    loadBenefits() {
      this.loading = true;
      axios
        .post("Benefit/search", {
          getActive: this.showActive,
          getInactive: this.showInactive,
        })
        .then((res) => {
          this.benefits = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail",
          });
        });
    },
    setBenefit(benefit) {
      const benefitId = benefit && benefit.id;
      const benefitName = benefit && benefit.name;

      this.$store.commit("benefit/setSelected", benefitId);
      this.$store.commit("benefit/setEdit", true);
      this.$store.commit("benefit/setEditDialog", true);
      this.$store.commit("benefit/setNameSelected", benefitName);
    },
    onClickEdit(event, row) {
      this.setBenefit(row);
    },
    onClickDelete(event, row) {
      this.benefitIdToDelete = row && row.id;
      this.deleteDialog = true;
    },
    onClickDetail(event, row) {
      const benefit = row && row.item;
      this.setBenefit(benefit);
    },
    onAddBenefit() {
      this.$store.commit("benefit/setEdit", false);
      this.$store.commit("benefit/setEditDialog", true);
    },
    onToggleShowActive() {
      this.showActive = !this.showActive;
      this.loadBenefits();
    },
    onToggleShowInactive() {
      this.showInactive = !this.showInactive;
      this.loadBenefits();
    },
    hasConfirmedDelete(isConfirmed) {
      if (isConfirmed) {
        axios
          .delete(`Benefit/${this.benefitIdToDelete}`)
          .then(() => {
            this.$store.commit("benefit/setBenefitKey");
            this.$store.commit("notification/showSnackbar", {
              message: "Success",
            });
            this.deleteDialog = false;
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: `Fail`,
            });
          });
      } else {
        this.deleteDialog = false;
      }
    },
  },
  created() {
    this.loadBenefits();
  },
};
</script>