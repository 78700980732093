<template>
  <v-dialog v-model="editDialog" width="400px" :key="editDialog" persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-progress-linear :active="isEdit && loading" indeterminate color="primary"></v-progress-linear>
      <v-container fluid class="py-0">
        <v-card elevation="0">
          <v-card-text class="pb-0">
            <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + user.id">
              <v-row class="common_container">
                <v-col cols="12 pb-0">
                  <span class="common_labels">{{ $t("dialog.userEdit.fields.firstName") }}</span>
                  <v-text-field
                    class="common_txtfield"
                    v-model="user.firstName"
                    outlined
                    dense
                    required
                    :rules="[v => !!v || $t('dialog.error.required')]"
                    persistent-placeholder
                  ></v-text-field>
                </v-col>
                <v-col cols="12 pb-0">
                  <span class="common_labels">{{ $t("dialog.userEdit.fields.lastName") }}</span>
                  <v-text-field
                    class="common_txtfield"
                    v-model="user.lastName"
                    outlined
                    dense
                    required
                    :rules="[v => !!v || $t('dialog.error.required')]"
                    persistent-placeholder
                  ></v-text-field>
                </v-col>
                <v-col v-if="!isEdit" cols="12 pb-0">
                  <span class="common_labels">{{ $t("dialog.userEdit.fields.email") }}</span>
                  <v-text-field
                    class="common_txtfield"
                    v-model="user.email"
                    outlined
                    type="email"
                    dense
                    required
                    :rules="emailRules(user.email)"
                    persistent-placeholder
                  ></v-text-field>
                </v-col>
                 <v-col cols="12 pb-0">
                  <span class="common_labels">{{ $t("dialog.userEdit.fields.role") }}</span>
                  <v-select
                    class="common_txtfield"
                    v-model="user.roleId"
                    :items="roles"
                    item-text="name"
                    item-value="id"
                    :loading="loadingRoles"
                    outlined
                    dense
                    persistent-placeholder
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex">
            <v-container class="px-0 pt-0">
              <v-btn class="float-right mr-2" small color="secondary" @click="save">
                {{ $t("app.actions.save") }}
              </v-btn>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios";
import { mapGetters } from "vuex";
import { notEmpty, matches } from "../../rules/general";

export default {
  data: () => ({
    user: {},
    form: null,
    loading: false,
    loadingRoles: false,
    roles: []
  }),
  computed: {
    ...mapGetters({
      isEdit: "user/isEdit",
      editDialog: "user/editDialog",
      selectedName: "user/selectedName",
      selected: "user/selected"
    }),
    title() {
      return this.isEdit ? this.selectedName : this.$t("dialog.userEdit.titleAdd");
    }
  },
  watch: {
    editDialog(v) {
      if (!v) this.$store.commit("user/clearSelected");
      this.$emit("input", v);
    },
    selected(v) {
      if (v) {
        this.loadUser();
      }
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit("user/setEditDialog", false);
      this.$store.commit("user/setEdit", false);
      this.$store.commit("user/clearSelected", false);
      this.user = {};
    },
    emailRules() {
      return [notEmpty(this.$t("dialog.error.required")), matches(this.$t("dialog.createConstituent.email.errors.notEmail"), ".+?@.+?\\..+")];
    },
    loadUser() {
      this.loading = true;
      const userId = this.$store.getters["user/selected"];
      axios
        .get(`user/${userId}`)
        .then(res => {
          if (res && res.data) {
            this.user = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadRoles() {
      this.loadingRoles = true;
      axios
        .get("userrole")
        .then(res => {
          if (res && res.data) {
            this.roles = res.data;
            this.loadingRoles = false;
          }
        })
        .catch(() => {
          this.loadingRoles = false;
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        let requestCall = null;

        if (!this.user.id) {
          requestCall = axios.post("user", this.user);
        } else {
          this.user = {
            id: this.user.id,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            roleId: this.user.roleId
          }
          requestCall = axios.put(`user/${this.user.id}`, this.user);
        }
        requestCall
          .then(res => {
            this.$store.commit("user/setUserKey");
            this.$store.commit("user/clearSelected");
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
          })
          .catch(exception => {
            const message = exception.response.data ? exception.response.data[0].brokenRuleReason : "";
            this.$store.commit("notification/showSnackbar", {
              message: `Fail: ${message}`
            });
          });

        this.$store.commit("user/setEditDialog", false);
      }
    }
  },
  created() {
    this.loadRoles();
  }
};
</script>
