<template>
  <div>
    <v-card outlined class="mt-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <span class="text-uppercase pr-2 ma-auto">{{ $t("app.actions.filter") }}:</span>
            <v-btn elevation="0" small @click="onToggleShowActive" class="mr-2" :class="showActive ? 'selected' : ''">
              {{ $t("app.actions.showActive") }}
            </v-btn>
            <v-btn elevation="0" small @click="onToggleShowInactive" :class="showInactive ? 'selected' : ''">
              {{ $t("app.actions.showInactive") }}
            </v-btn>
            <v-spacer />
            <v-btn color="secondary" small @click="onAddCampaign">{{ $t("app.actions.add") }}</v-btn>
          </v-col>
        </v-row>
        <v-data-table :items="campaigns" item-key="id" :headers="headers" :loading="loading" @dblclick:row="onClickDetail" :items-per-page="10">
          <template v-slot:item.startDate="{ item }">{{ item.startDate | moment("YYYY-MM-DD") }}</template>
          <template v-slot:item.endDate="{ item }">{{ item.endDate | moment("YYYY-MM-DD") }}</template>
          <template v-slot:item.isActive="{ item }">
            <v-icon x-small :class="item.isActive ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
          <template v-slot:item.action="{ item }">
            <slot name="row-action">
              <v-icon small @click="onClickEdit(null, item)">mdi-pencil</v-icon>
              <!-- <v-icon small @click="onClickDelete(null, item)">mdi-delete</v-icon> -->
            </slot>
          </template>
        </v-data-table>
        <edit-campaign-dialog v-model="editDialog" @close="dialog = false"> </edit-campaign-dialog>
      </v-card-text>
    </v-card>
    <confirm-dialog :dialog="deleteDialog" @btnClicked="hasConfirmedDelete($event)"></confirm-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EditCampaignDialog from "../../components/ConfigCampaign/EditCampaignDialog.vue";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog.vue";
import axios from "../../plugins/axios";

export default {
  components: { EditCampaignDialog, ConfirmDialog },
  data() {
    return {
      editDialog: false,
      deleteDialog: false,
      isEdit: false,
      campaigns: [],
      campaignIdToDelete: null,
      loading: false,
      showInactive: false,
      showActive: true,
    };
  },
  computed: {
    ...mapGetters({
      campaignKey: "campaign/campaignKey",
    }),
    headers() {
      return [
        {
          text: this.$t("page.campaignConfig.name"),
          value: "name",
        },
        {
          text: this.$t("page.campaignConfig.startDate"),
          value: "startDate",
        },
        {
          text: this.$t("page.campaignConfig.endDate"),
          value: "endDate",
        },
        {
          text: this.$t("page.campaignConfig.category"),
          value: "campaignCategoryName",
        },
        {
          text: this.$t("page.campaignConfig.active"),
          value: "isActive",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("app.common.actions"),
          value: "action",
          width: "10%",
          sortable: false,
          align: "center",
        },
      ];
    },
  },
  watch: {
    campaignKey() {
      this.loadCampaigns();
    },
  },
  methods: {
    loadCampaigns() {
      this.loading = true;
      axios
        .post("campaign/search", {
          getActive: this.showActive,
          getInactive: this.showInactive,
        })
        .then((res) => {
          this.campaigns = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail",
          });
        });
    },
    setCampaign(campaign) {
      const campaignId = campaign && campaign.id;
      const campaignName = campaign && campaign.name;

      this.$store.commit("campaign/setSelected", campaignId);
      this.$store.commit("campaign/setEdit", true);
      this.$store.commit("campaign/setEditDialog", true);
      this.$store.commit("campaign/setNameSelected", campaignName);
    },
    onClickEdit(event, row) {
      this.setCampaign(row);
    },
    onClickDelete(event, row) {
      this.campaignIdToDelete = row && row.id;
      this.deleteDialog = true;
    },
    onClickDetail(event, row) {
      const campaign = row && row.item;
      this.setCampaign(campaign);
    },
    onAddCampaign() {
      this.$store.commit("campaign/setEdit", false);
      this.$store.commit("campaign/setEditDialog", true);
    },
    onToggleShowActive() {
      this.showActive = !this.showActive;
      this.loadCampaigns();
    },
    onToggleShowInactive() {
      this.showInactive = !this.showInactive;
      this.loadCampaigns();
    },
    hasConfirmedDelete(isConfirmed) {
      if (isConfirmed) {
        axios
          .delete(`campaign/${this.campaignIdToDelete}`)
          .then(() => {
            this.$store.commit("campaign/setCampaignKey");
            this.$store.commit("notification/showSnackbar", {
              message: "Success",
            });
            this.deleteDialog = false;
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail",
            });
          });
      } else {
        this.deleteDialog = false;
      }
    },
  },
  created() {
    this.loadCampaigns();
  },
};
</script>