<template>
  <v-card-title>
    <v-row class="align-center justify-space-between">
        <span class="secondary--text ml-3 bold title">
          {{ $t(`batch.templates.`+$route.meta.typeBatch) }}
        </span>

      <div class="d-flex align-center justify-end">
        <span class="secondary--text">
          {{ "Row" }}
        </span>

        <v-btn
            icon
            small
            dense
            color="secondary"
            class="ma-2 white--text"
            @click="$emit('addRow')"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
            dense
            color="primary"
            class="ma-2 white--text"
            @click="$emit('sendData')"
        >
          {{ $t('app.actions.commit') }}
          <v-icon
              right
              dark
          >
            mdi-cloud-upload
          </v-icon>
        </v-btn>
      </div>
    </v-row>
  </v-card-title>
</template>
<script>
export default {
  props: {
    title: String
  }
}
</script>