export const state = {
    selected: null,
    isEdit: false,
    constituentCodeKey: 0,
    editDialog: false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, constituentCodeId) {
        state.selected = constituentCodeId;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setConstituentCodeKey(state) {
        state.constituentCodeKey = state.constituentCodeKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    isEdit: state => state.isEdit,
    constituentCodeKey: state => state.constituentCodeKey,
    editDialog: state => state.editDialog
}