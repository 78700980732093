import axios from '../../plugins/axios'

export const state = {
    countries: [],
    states: []
};
export const mutations = {
    setCountries(state, countries) {
        if (countries == null) {
            countries = []
        }
        state.countries = countries
    },
    setStates(state, states) {
        if (states == null) {
            states = []
        }
        state.states = states
    },
}
export const actions = {
    loadCountries({ commit, state }) {
        return new Promise((resolve, reject) => {
            axios.get('/country').then(res => {
                let types = res.data
                commit('setCountries', types)
                resolve()
            }).catch(e => reject(e))
        })
    },
    loadStates({ commit, state }) {
        return new Promise((resolve, reject) => {
            axios.get('/state').then(res => {
                let types = res.data
                commit('setStates', types)
                resolve()
            }).catch(e => reject(e))
        })
    },
}
export const getters = {
    countries: state => state.countries,
    countriesWithStates: state => {
        let states = state.states
        let countries = state.countries
        if (states.length) {
            for (let country of countries) {
                country['states'] = states.filter(x => x.countryId == country.id)
            }
        }
        return countries
    },
    states: state => state.states

}