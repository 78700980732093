import axios from '../../plugins/axios'

export const state = {
    emails: [],
    emailTypes: []
};

export const mutations = {
    setEmails(state, emails) {
        if (emails == null) {
            emails = []
        }
        state.emails = emails
    },
    setEmailTypes(state, types) {
        if (types == null) {
            types = []
        }
        state.emailTypes = types
    }
}

export const actions = {
    loadEmails({ commit }, constituentId) {
        return new Promise((resolve, reject) => {
            axios.get(`/email/${constituentId}`).then(res => {
                let emails = res.data
                commit('setEmails', emails)
                resolve()
            }).catch(e => reject(e))
        })
    },
    loadEmailTypes({ commit, state }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (!state.isEmailTypesLoaded || forceLoad) {
                axios.get('/email/types').then(res => {
                    let types = res.data
                    commit('setEmailTypes', types)
                    resolve()
                }).catch(e => reject(e))
            }
            else {
                resolve();
            }
        })
    },
}
export const getters = {
    emails: state => state.emails,
    emailTypes: state => state.emailTypes
}