<template>
  <v-card fluid>
    <v-progress-linear :active="isEdit && loading" indeterminate color="primary"></v-progress-linear>
    <v-card-text class="pb-0">
      <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + benefit.id">
        <v-row class="common_container">
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.benefitConfig.name") }}</span>
            <v-text-field
              class="common_txtfield"
              v-model="benefit.name"
              outlined
              dense
              required
              :rules="[v => !!v || $t('dialog.error.required')]"
              persistent-placeholder
            ></v-text-field>
          </v-col>
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.benefitConfig.amount") }}</span>
            <v-text-field
              class="common_txtfield"
              v-model="benefit.unitCost"
              outlined
              type="number"
              dense
              required
              :rules="[v => !!v || $t('dialog.error.required')]"
              persistent-placeholder
            ></v-text-field>
          </v-col>
          <v-col cols="2 pb-0">
            <span class="common_labels">{{ $t("page.benefitConfig.active") }}</span>
            <v-checkbox class="common_txtfield" dense color="secondary" v-model="benefit.isActive" />
          </v-col>
          <!--          <v-col cols="12 pb-0">-->
          <!--            <span class="pack-details_labels">{{ $t('page.benefitConfig.description') }}</span>-->
          <!--            <v-text-field-->
          <!--              class="common-details_txtfield"-->
          <!--              v-model="benefit.description"-->
          <!--              outlined-->
          <!--              dense-->
          <!--              required-->
          <!--              :rules="[]"-->
          <!--              persistent-placeholder-->
          <!--            ></v-text-field>-->
          <!--          </v-col>-->
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-container class="px-0 pt-0">
        <v-btn class="float-right mr-2" small color="secondary" @click="save">
          {{ $t("app.actions.save") }}
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "../../plugins/axios";

export default {
  data() {
    return {
      form: null,
      categories: [],
      appeals: [],
      benefit: {},
      isFromPickerOpen: false,
      isToPickerOpen: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "benefit/isEdit"
    }),
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    }
  },
  watch: {},
  methods: {
    loadBenefit() {
      this.loading = true;
      const benefitId = this.$store.getters["benefit/selected"];
      axios
        .get(`Benefit/${benefitId}`)
        .then(res => {
          if (res && res.data) {
            this.benefit = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    formatAmount() {
      if (this.benefit.overall != undefined && this.benefit.overall != null) this.benefit.overall = parseFloat(this.benefit.overall).toFixed(2);
    },
    save() {
      if (this.$refs.form.validate()) {
        let requestCall = null;

        if (!this.benefit.id) {
          requestCall = axios.post("/Benefit", this.benefit);
        } else {
          requestCall = axios.put(`Benefit/${this.benefit.id}`, this.benefit);
        }
        requestCall
          .then(res => {
            this.$store.commit("benefit/setBenefitKey");
            this.$store.commit("benefit/clearSelected");
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.$store.commit("benefit/setEditDialog", false);
          })
          .catch(exception => {
            const message = exception.response.data ? exception.response.data[0].brokenRuleReason : "";
            this.$store.commit("notification/showSnackbar", {
              message: `Fail: ${message}`
            });
          });
      }
    },
    endDateRule(v) {
      return [
        v => {
          if (
            this.benefit.startDate != null &&
            this.benefit.endDate != null &&
            (this.$moment(this.benefit.endDate).isBefore(this.benefit.startDate) || this.$moment(this.benefit.endDate).isSame(this.benefit.startDate))
          ) {
            return this.$t("dialog.membershipdialog.endDateAfterError");
          } else return true;
        }
      ];
    }
  },
  created() {
    if (this.isEdit) {
      this.loadBenefit();
    } else {
      this.benefit.isActive = true;
    }
  }
};
</script>
