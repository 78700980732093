<template>
  <v-sheet class="d-flex justify-center align-center" style="background-color:rgb(247 248 249); justify-content: center;">
    <v-form ref="formBatch" class="mt-2"  v-model="valid">
    <v-card  elevation="7" style="width: 900px">
        <v-toolbar dark color="primary" >
          <v-toolbar-title >{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
     <v-card-text>
       <v-container>
         <v-row class="secondary--text bold ">
           <h3>
             {{ $t('reports.forms.pleaseSelect') }}
           </h3>
         </v-row>
         <v-row>
           <v-col class="col-5  d-flex">
             <v-menu
                 :close-on-content-click="true"
                 :nudge-right="40"
                 transition="scale-transition"
                 offset-y
                 min-width="auto"
             >
               <template v-slot:activator="{ on, attrs }">
                 <v-text-field
                     dense
                     :label="$t('reports.forms.from') "
                     outlined
                     :rules="[v => !!v || $t('dialog.error.required')]"
                     v-model="form.startDate"
                     prepend-icon="mdi-calendar"
                     readonly
                     v-bind="attrs"
                     v-on="on"
                 ></v-text-field>
               </template>
               <v-date-picker
                   v-model="form.startDate"
               ></v-date-picker>
             </v-menu>
           </v-col>
           <v-col class="col-5  d-flex justify-end">
             <v-menu
                 :close-on-content-click="true"
                 :nudge-right="40"
                 transition="scale-transition"
                 offset-y
                 min-width="auto"
             >
               <template v-slot:activator="{ on, attrs }">
                 <v-text-field
                     outlined
                     dense
                     :label="$t('reports.forms.to') "
                     :rules="[v => !!v || $t('dialog.error.required')]"
                     v-model="form.endDate"
                     prepend-icon="mdi-calendar"
                     readonly
                     v-bind="attrs"
                     v-on="on"
                 ></v-text-field>
               </template>
               <v-date-picker
                   v-model="form.endDate"
               ></v-date-picker>
             </v-menu>
           </v-col>
           <v-col class="col-2 d-flex justify-end">
             <v-btn :loading="loading" color="secondary" @click="generateReport">{{ $t('reports.forms.generate') }}</v-btn>
           </v-col>
         </v-row>
       </v-container>
     </v-card-text>

    </v-card>
    </v-form>
  </v-sheet>
</template>
<script>
import axios from "../../plugins/axios";
export default {
  data() {
    return {
      form: {
        startDate: '',
        endDate: ''
      },
      loading: false,
      valid:false
    };
  },
  computed: {
    title(){
      return this.$t('reports.financialRec')
    }
  },
  methods: {
    validate() {
      return this.$refs.formBatch.validate()
    },
    generateReport(){
      this.validate()
      if (this.valid) {
        this.loading=true
        this.exportData()

      }
    },
    exportData() {
      axios.post(`Transaction/export`, this.form, {responseType: 'blob'})
          .then(res => {
            const fileName = this.title
            this.loading=false
            this.downloadExcel(fileName, res.data);
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
          })
          .catch(err =>{
            console.log(err)
            this.$store.commit("notification/showSnackbar", {
              message: `Fail: ${err.message}`
            });
          }
          )
    },

    downloadExcel(fileName, data) {
      const blob = new Blob([data], { type: "application/xlsx" })
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + '.xlsx';
      link.click();
    },
  }
};
</script>