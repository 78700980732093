<template>
  <div>
    <v-container fluid>
      <v-toolbar elevation="0">
        <v-row>
          <v-col offset="10 pr-0 text-end">
            <v-btn color="secondary" small @click="onAddPackage">{{$t('component.appealDetails.addPackage')}}</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table 
        :items="packages" 
        item-key="id" 
        :headers="headers"
        :loading="loading" 
        :items-per-page="5"
      >
        <template v-slot:item.startDate="{item}">{{item.startDate | moment('YYYY-MM-DD')}}</template>
        <template v-slot:item.endDate="{item}">{{item.endDate | moment('YYYY-MM-DD')}}</template>
        <template v-slot:item.isActive="{item}">
          <v-simple-checkbox
            v-model="item.isActive"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.action="{item}">
          <slot name="row-action">
            <v-icon small @click="onDeletePackage(item)">mdi-delete</v-icon>
          </slot>
        </template>
      </v-data-table>
    </v-container>
    <add-package-dialog 
      v-model="addPackageDialog"
      :addPackageDialog="addPackageDialog"
      @close="addPackageDialog = false"
    >
    </add-package-dialog>
    <confirm-dialog :dialog="deletePackageDialog" @btnClicked="hasConfirmedDelete($event)"></confirm-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from "../../plugins/axios";
import AddPackageDialog from '../../components/ConfigAppeal/AddPackageDialog.vue'
import ConfirmDialog from '../Dialogs/ConfirmDialog.vue';

export default {
  components: { ConfirmDialog, AddPackageDialog },
  data: () => ({
    packages: [],
    addPackageDialog: false,
    deletePackageDialog: false,
    packageToDelete: null,
    loading: false
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("component.appealDetails.packageName"),
          value: "name"
        },
        {
          text: this.$t("page.appealConfig.description"),
          value: "description"
        },
        {
            text: this.$t("page.appealConfig.startDate"),
            value: "startDate"
        },
        {
            text: this.$t("page.appealConfig.endDate"),
            value: "endDate"
        },
        {
          text: this.$t("page.appealConfig.category"),
          value: "packageCategoryName"
        },
        {
          text: this.$t("page.appealConfig.active"),
          value: "isActive"
        },
        {
          text: this.$t("app.common.actions"),
          value: "action",
          width: "10%",
          sortable: false,
          align: "center"
        },
      ];
    },
    ...mapGetters({
      appealId: "appeal/selected",
      isEdit: "appeal/isEdit",
      packageKey: "appeal/packageKey"
    }),
  },
  watch: {
    packageKey() {
      this.loadPackages();
    }
  },
  methods: {
    loadPackages() {
      this.loading = true;
      axios
        .get(`package/appeal/${this.appealId}`)
        .then(res => {
          this.packages = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
          this.loading = false;
        });
    },
    onAddPackage() {
      this.addPackageDialog = true;
    },
    onDeletePackage(pack) {
      this.deletePackageDialog = true;
      this.packageToDelete = pack;
    },
    hasConfirmedDelete(isConfirmed) {
      if (isConfirmed) {
        const packageId = this.packageToDelete.id;
        this.packageToDelete.appealId = null;
        
        axios
          .delete(`package/${packageId}`, this.packageToAdd)
          .then(() => {
            this.loadPackages();
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.deletePackageDialog = false;
            this.packageToDelete = null;
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail"
            });
          });
      } else {
        this.deletePackageDialog = false;
        this.packageToDelete = null;
      }
    },
  },
  created() {
    if(this.isEdit)
      this.loadPackages();
  }
};
</script>