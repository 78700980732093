export const state = {
    selected: null,
    nameSelected: null,
    isEdit: false,
    appealKey: 0,
    packageKey: 0,
    editDialog: false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, appealId) {
        state.selected = appealId;
    },
    setNameSelected(state, appealName) {
        state.nameSelected = appealName;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setAppealKey(state) {
        state.appealKey = state.appealKey + 1;
    },
    setPackageKey(state) {
        state.packageKey = state.packageKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    nameSelected: state => state.nameSelected,
    isEdit: state => state.isEdit,
    appealKey: state => state.appealKey,
    packageKey: state => state.packageKey,
    editDialog: state => state.editDialog
}