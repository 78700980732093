export const state = {
    selected: null,
    selectedTransactionType: null,
    selectedTransaction: null,
    isEdit: false,
    isEditTransaction: false,
    membershipKey: 0,
    memberKey: 0,
    membershipObject: null,
    membershipTransactionKey: 0,
    membershipTransactionObject: null,
    editDialog: false,
    showTransactionsDialog:false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, membershipId) {
        state.selected = membershipId;
    },
    setSelectedTransaction(state, membershipTransactionId) {
        state.selectedTransaction = membershipTransactionId;
    },
    setSelectedTransactionType(state, transactionTypeId) {
        state.selectedTransactionType = transactionTypeId;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setEditTransaction(state, isEditTransaction) {
        state.isEditTransaction = isEditTransaction;
    },
    setMembershipKey(state) {
        state.membershipKey = state.membershipKey + 1;
    },
    setMemberKey(state) {
        state.memberKey = state.memberKey + 1;
    },
    setMembershipObject(state, membershipObject) {
        state.membershipObject = membershipObject;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
    showTransactionsDialog(state, showTransactionsDialog){
        state.showTransactionsDialog = showTransactionsDialog
    },
    updateMembershipTransactionKey(state){
        state.membershipTransactionKey = state.membershipTransactionKey + 1
    },
    setMembershipTransactionObject(state, membershipTransactionObject){
        state.membershipTransactionObject = membershipTransactionObject
    }
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    selectedTransactionType: state => state.selectedTransactionType,
    selectedTransaction: state => state.selectedTransaction,
    isEdit: state => state.isEdit,
    isEditTransaction: state => state.isEditTransaction,
    membershipKey: state => state.membershipKey,
    memberKey: state => state.memberKey,
    membershipObject: state => state.membershipObject,
    membershipTransactionKey: state => state.membershipTransactionKey,
    membershipTransactionObject: state => state.membershipTransactionObject,
    editDialog: state => state.editDialog,
    showTransactionsDialog: state => state.showTransactionsDialog
}