export const state = {
    selected: null,
    nameSelected: null,
    isEdit: false,
    packKey: 0,
    appealKey: 0,
    editDialog: false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, packId) {
        state.selected = packId;
    },
    setNameSelected(state, packName) {
        state.nameSelected = packName;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setPackKey(state) {
        state.packKey = state.packKey + 1;
    },
    setAppealKey(state) {
        state.appealKey = state.appealKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    nameSelected: state => state.nameSelected,
    isEdit: state => state.isEdit,
    packKey: state => state.packKey,
    appealKey: state => state.appealKey,
    editDialog: state => state.editDialog
}