<template>
  <v-card fluid>
    <v-progress-linear :active="isEdit && loading" indeterminate color="primary"></v-progress-linear>
    <v-card-text class="pb-0">
      <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + appeal.id">
        <v-row class="common_container">
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.appealConfig.name") }}</span>
            <v-text-field
                class="common_txtfield"
                v-model="appeal.name"
                outlined
                dense
                required
                :rules="[v => !!v || $t('dialog.error.required')]"
                persistent-placeholder
            ></v-text-field>
          </v-col>
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.appealConfig.category") }}</span>
            <v-select
                class="common_txtfield"
                v-model="appeal.appealCategoryId"
                :items="categories"
                clearable
                item-text="name"
                item-value="id"
                outlined
                dense
                persistent-placeholder
            ></v-select>
          </v-col>
          <v-col cols="2 pb-0">
            <span class="common_labels">{{ $t("page.appealConfig.active") }}</span>
            <v-checkbox class="common_checkbox" dense color="primary" v-model="appeal.isActive"/>
          </v-col>
          <v-col cols="12 py-0">
            <span class="common_labels">{{ $t("page.appealConfig.description") }}</span>
            <v-text-field class="common_txtfield" v-model="appeal.description" outlined dense
                          persistent-placeholder></v-text-field>
          </v-col>
          <v-col v-if="!isEdit" cols="4 py-0">
            <span class="common_labels">{{ $t("page.appealConfig.campaign") }}</span>
            <v-select
                class="common_txtfield"
                v-model="appeal.campaignId"
                :items="campaigns"
                item-text="name"
                item-value="id"
                outlined
                dense
                persistent-placeholder
            ></v-select>
          </v-col>
          <v-col cols="4 py-0">
            <span class="common_labels">{{ $t("page.appealConfig.defaultFund") }}</span>
            <v-select
                clearable
                class="common_txtfield"
                v-model="appeal.defaultFundId"
                :items="funds"
                item-text="name"
                item-value="id"
                outlined
                dense
                persistent-placeholder
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="common_container">
          <v-col cols="4 py-0">
            <span class="common_labels">{{ $t("page.appealConfig.startDate") }}</span>
            <calendar-field v-model="appeal.startDate" :valeur="appeal.startDate"
                            :dateFormat="dateFormat"></calendar-field>
          </v-col>
          <v-col cols="4 py-0">
            <span class="common_labels">{{ $t("page.appealConfig.endDate") }}</span>
            <calendar-field v-model="appeal.endDate" :valeur="appeal.endDate" :dateFormat="dateFormat"
                            :rules="endDateRule(appeal.endDate)">
            </calendar-field>
          </v-col>
          <v-col cols="12 py-0">
            <span class="common_labels">{{ $t("page.appealConfig.notes") }}</span>
            <v-textarea v-model="appeal.comment" outlined dense class="common_txtfield"
                        persistent-placeholder></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-container class="px-0 pt-0">
        <v-btn class="float-right mr-2" small color="secondary" @click="save">
          {{ $t("app.actions.save") }}
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "../../plugins/axios";
import CalendarField from "../Shared/ACalendarField.vue";
import {orderList} from "../../utils/functions";

export default {
  components: {CalendarField},
  data() {
    return {
      form: null,
      categories: [],
      appeal: {},
      campaigns: [],
      funds: [],
      isFromPickerOpen: false,
      isToPickerOpen: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "appeal/isEdit"
    }),
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    }
  },
  watch: {},
  methods: {
    loadAppeal() {
      this.loading = true;
      const appealId = this.$store.getters["appeal/selected"];
      axios
          .get(`appeal/${appealId}`)
          .then(res => {
            if (res && res.data) {
              this.appeal = res.data;
              this.loading = false;
            }
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail"
            });
            this.loading = false;
          });
    },
    loadCategories() {
      axios
          .get("appealcategory/selectlist")
          .then(res => {
            this.categories = orderList(res.data, "name", true);
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail to load the categories"
            });
          });
    },
    loadCampaigns() {
      axios
          .get("campaign/selectlist")
          .then(res => {
            this.campaigns = orderList(res.data, "name", true);
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail to load the campaigns"
            });
          });
    },
    loadFunds() {
      axios
          .get("fund/selectlist")
          .then(res => {
            this.funds = orderList(res.data, "name", true);
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail to load the funds"
            });
          });
    },
    save() {
      if (this.$refs.form.validate()) {
        let requestCall = null;

        if (!this.appeal.id) {
          requestCall = axios.post("/appeal", this.appeal);
        } else {
          requestCall = axios.put(`appeal/${this.appeal.id}`, this.appeal);
        }
        requestCall
            .then(res => {
              this.$store.commit("appeal/setAppealKey");
              this.$store.commit("appeal/clearSelected");
              this.$store.commit("notification/showSnackbar", {
                message: "Success"
              });
              this.$store.commit("appeal/setEditDialog", false);
            })
            .catch(exception => {
              const message = exception.response.data ? exception.response.data[0].brokenRuleReason : "";
              this.$store.commit("notification/showSnackbar", {
                message: `Fail: ${message}`
              });
            });
      }
    },
    endDateRule(v) {
      return [
        v => {
          if (
              this.appeal.startDate != null &&
              this.appeal.endDate != null &&
              (this.$moment(this.appeal.endDate).isBefore(this.appeal.startDate) || this.$moment(this.appeal.endDate).isSame(this.appeal.startDate))
          ) {
            return this.$t("dialog.membershipdialog.endDateAfterError");
          } else return true;
        }
      ];
    }
  },
  created() {
    this.loadCategories();
    this.loadFunds();
    if (this.isEdit) {
      this.loadAppeal();
    } else {
      this.appeal.isActive = true;
      this.loadCampaigns();
    }
  }
};
</script>
