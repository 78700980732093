import Vue from "vue";
//import axios from "axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./i18n";
import momentTimezone from "moment-timezone";
import store from "./store";
import VueTheMask from "vue-the-mask";
import "./plugins";
import * as Dialogs from "./utils/dialogs";
import * as Drawers from "./utils/drawers";
import Vuelidate from "vuelidate";
import "@/sass/app.scss";

const eventBus = new Vue();
Vue.config.productionTip = process.env.NODE_ENV == "development";
Vue.use(require("vue-moment"), { momentTimezone });

Vue.use(VueTheMask);
Vue.use(Vuelidate);

Vue.mixin({
  methods: {
    onlyAcceptNumber: function(evt) {
      evt = evt ? evt : window.event;

      let stringStart = evt.target.value.toString().slice(0, evt.target.selectionStart);
      let stringEnd = evt.target.value.toString().substr(evt.target.selectionEnd, evt.target.value.length);
      
      let expect = stringStart + evt.key.toString() + stringEnd; //evt.target.value.toString() + evt.key.toString();
      if (expect.length == 1 && expect == "-") {
        return true;
      } else if (!/^-{0,1}[0-9]+?(\.)?[0-9]{0,2}$/gm.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onlyAcceptPositiveNumber: function(evt) {
      evt = evt ? evt : window.event;

      let stringStart = evt.target.value.toString().slice(0, evt.target.selectionStart);
      let stringEnd = evt.target.value.toString().substr(evt.target.selectionEnd, evt.target.value.length);
      
      let expect = stringStart + evt.key.toString() + stringEnd;
      if (!/^-{0,1}[0-9]+?(\.)?[0-9]{0,2}$/gm.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  computed: {
    appInstance: {
      get() {
        return Vue.options;
      }
    },
    $eventBus: {
      get() {
        return eventBus;
      }
    }
  }
});

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");

//#region Dialogs
Vue.component(Dialogs.GlobalSearch, () => import("./components/Dialogs/GlobalSearch"));
Vue.component(Dialogs.ConstituentFilter, () => import("./components/Dialogs/ConstituentFilterDialog"));
Vue.component(Dialogs.DonationEdit, () => import("./components/Dialogs/DonationEditDialog"));
Vue.component(Dialogs.MembershipEdit, () => import("./components/Dialogs/MembershipEditDialog"));
Vue.component(Dialogs.RelationshipEdit, () => import("./components/Dialogs/RelationshipEditDialog"));
Vue.component(Dialogs.NoteEdit, () => import("./components/Dialogs/NoteEditDialog"));
Vue.component(Dialogs.NoteDelete, () => import("./components/Dialogs/NoteDeleteDialog"));
Vue.component(Dialogs.ImportConstituents, () => import("./components/Dialogs/ImportConstituentsDialog"));
Vue.component(Dialogs.ActionEdit, () => import("./components/Dialogs/ActionEditDialog"));
Vue.component(Dialogs.AddressEdit, () => import("./components/Dialogs/AddressEditDialog"));
Vue.component(Dialogs.ContactEdit, () => import("./components/Dialogs/ContactDialog/ContactEditDialog"));
Vue.component(Dialogs.ConstituentCreateContactEdit, () => import("./components/Dialogs/ContactDialog/ConstituentCreateContactDialog"));
Vue.component(Dialogs.ConstituentIndividualCreate, () => import("./components/Dialogs/ConstituentCreateDialog/ConstituentIndividualCreateDialog"));
Vue.component(Dialogs.ConstituentOrganisationCreate, () =>
  import("./components/Dialogs/ConstituentCreateDialog/ConstituentOrganisationCreateDialog")
);
Vue.component(Dialogs.ConstituentActivityEdit, () => import("./components/Dialogs/ConstituentActivityEditDialog"));
Vue.component(Dialogs.DeleteDialogConfirmation, () => import("./components/Dialogs/Communication/DeleteCommunicationConfirmation"));
Vue.component(Dialogs.CommunicationDialogEdit, () => import("./components/Dialogs/Communication/CommunicationDialogEdit"));
Vue.component(Dialogs.ShowNoteCommunicationDialog, () => import("./components/Dialogs/Communication/ShowNoteCommunicationDialog"));

//#endregion

//#region Drawers
Vue.component(Drawers.ConstituentNav, () => import("./views/Constituent/ConstituentDetailsDrawer"));
Vue.component(Drawers.ReportNav, () => import("./views/Reports/ReportsDrawer.vue"));
//#endregion
