import ConfigLayout from '../../views/Config/ConfigLayout'
import CampaignConfig from "../../views/Config/CampaignConfig";
import AppealConfig from "../../views/Config/AppealConfig";
import FundConfig from "../../views/Config/FundConfig";
import PackConfig from "../../views/Config/PackConfig";
import BenefitConfig from "../../views/Config/BenefitConfig";
import ProgramConfig from "../../views/Config/ProgramConfig";
import CategoryConfig from "../../views/Config/CategoryConfig";
import EventConfig from "../../views/Config/EventConfig";
import ProfessionConfig from "../../views/Config/ProfessionConfig";
import CodeConfig from "../../views/Config/CodeConfig.vue";
import ContactTypeConfig from "../../views/Config/ContactTypeConfig";

export default {
    path: 'Config',
    name: 'config',
    component: ConfigLayout,
    children: [
        {
            path: "Campaing",
            name: "configCampaing",
            component: CampaignConfig
        },
        {
            path: "Appeal",
            name: "configAppeal",
            component: AppealConfig
        },
        {
            path: "Fun",
            name: "configFun",
            component: FundConfig
        },
        {
            path: "Fun",
            name: "configFun",
            component: FundConfig
        },
        {
            path: "Pack",
            name: "configPack",
            component: PackConfig
        },
        {
            path: "Program",
            name: "configProgram",
            component: ProgramConfig
        },
        {
            path: "Benefit",
            name: "configBenefit",
            component: BenefitConfig
        },
        {
            path: "Category",
            name: "configCategory",
            component: CategoryConfig
        },
        {
            path: "Event",
            name: "configEvent",
            component: EventConfig
        },
        {
            path: "Code",
            name: "configCode",
            component: CodeConfig
        },
        {
            path: "Profession",
            name: "configProfession",
            component: ProfessionConfig
        },
        {
            path: "ContactType",
            name: "configContactType",
            component: ContactTypeConfig
        },
    ]
}