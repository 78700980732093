<template>
  <div>
    <v-navigation-drawer absolute temporary v-model="isOpenDrawer" height="92vh" width="25vw">
      <div class="d-flex justify-space-between report_filters-top_buttons">
        <v-btn class="mt-2" icon plain :to="{ name: 'builder' }">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div>
          <v-btn class="mt-3 mr-2" depressed color="secondary" small @click="launchSearch()">
            {{ $t("appbar.searchField.title") }}
          </v-btn>
        </div>
      </div>

      <div class="px-3 mt-12">
        <h3 v-if="false">{{ $t("page.reports.membership.fields") }}</h3>

        <div v-if="false" class="common-details_container">
          <v-row class="common_container">
            <!--#region Program  -->
            <v-col cols="12 pb-0">
              <span class="common_labels">{{ $t("page.reports.membership.program") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.program.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.program.operator == 1 || filters.program.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.program.values"
                :items="programs"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Category  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.category") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.category.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.category.operator == 1 || filters.category.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.category.values"
                :items="categories"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Transaction Type  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.transactionType") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.transactionType.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.transactionType.operator == 1 || filters.transactionType.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.transactionType.values"
                :items="transactionTypes"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Membership length  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.length") }}</span>
            </v-col>
            <v-col cols="12 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.membershipLength.operator"
                :items="numericOptions"
                item-text="name"
                item-value="id"
                outlined
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col
              v-if="filters.membershipLength.operator != 3 && filters.membershipLength.operator != 8 && filters.membershipLength.operator != 9"
              cols="12 py-0"
            >
              <v-text-field
                class="common_txtfield"
                v-model.number="filters.membershipLength.value"
                outlined
                @keypress.native="onlyAcceptNumber"
                dense
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col v-if="filters.membershipLength.operator == 3" cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.from") }}</span>
              <v-text-field
                class="common_txtfield"
                v-model.number="filters.membershipLength.lowerValue"
                outlined
                @keypress.native="onlyAcceptNumber"
                dense
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col v-if="filters.membershipLength.operator == 3" cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.membership.to") }}</span>
              <v-text-field
                class="common_txtfield"
                v-model.number="filters.membershipLength.upperValue"
                outlined
                @keypress.native="onlyAcceptNumber"
                dense
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <!--#endregion -->
          </v-row>
        </div>

        <h3>{{ $t("page.reports.drawer.constituentFields") }}</h3>

        <div class="common-details_container">
          <v-row class="common_container">
            <!--#region Country  -->
            <v-col cols="12 pb-0">
              <span class="common_labels">{{ $t("page.reports.donation.country") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.country.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.country.operator == 1 || filters.country.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.country.values"
                :items="countries"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Language  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.donation.language") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.preferredLanguage.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.preferredLanguage.operator == 1 || filters.preferredLanguage.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.preferredLanguage.values"
                :items="languages"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Const Code  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.donation.constituentId") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.constituentCode.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.constituentCode.operator == 1 || filters.constituentCode.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.constituentCode.values"
                :items="constituentCodes"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Const Type  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.constituent.constituentType") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.constituentType.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.constituentType.operator == 1 || filters.constituentType.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.constituentType.values"
                :items="constituentTypes"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <v-col
              cols="12 py-0"
              v-if="
                (filters.constituentType.operator == 1 && filters.constituentType.values.find(item => item == 1) != undefined) ||
                  (filters.constituentType.operator == 2 && filters.constituentType.values.find(item => item == 2) != undefined) ||
                  filters.constituentType.operator == undefined
              "
            >
              <span class="common_labels">{{ $t("page.reports.constituent.isDeceased") }}</span>
            </v-col>
            <v-col
              cols="6 py-0"
              v-if="
                (filters.constituentType.operator == 1 && filters.constituentType.values.find(item => item == 1) != undefined) ||
                  (filters.constituentType.operator == 2 && filters.constituentType.values.find(item => item == 2) != undefined) ||
                  filters.constituentType.operator == undefined
              "
            >
              <v-select
                class="common_txtfield"
                v-model="filters.isDeceased.value"
                :items="booleanOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.constituent.isDoNotSolicit") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.isDoNotSolicit.value"
                :items="booleanOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.constituent.isGivingAnonymously") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.isGivingAnonymously.value"
                :items="booleanOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <h3>{{ $t("page.reports.donation.fields") }}</h3>

        <div class="common-details_container">
          <v-row class="common_container">
            <!--#region Date  -->
            <v-col cols="12 pb-0">
              <span class="common_labels">{{ $t("page.reports.donation.date") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.donation.from") }}</span>
              <calendar-field v-model="filters.transactionDate.startDate" :valeur="filters.transactionDate.startDate" :dateFormat="dateFormat">
              </calendar-field>
            </v-col>
            <v-col cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.donation.to") }}</span>
              <calendar-field v-model="filters.transactionDate.endDate" :valeur="filters.transactionDate.endDate" :dateFormat="dateFormat">
              </calendar-field>
            </v-col>
            <!--#endregion -->
            <!--#region Type  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.donation.donationType") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.donationType.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.donationType.operator == 1 || filters.donationType.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.donationType.values"
                :items="donationTypes"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region SubType  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("dialog.donationedit.fields.subType.title") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.donationSubtype.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.donationSubtype.operator == 1 || filters.donationSubtype.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.donationSubtype.values"
                :items="donationSubtypes"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Amount  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.reports.donation.amountFinancial") }}</span>
            </v-col>
            <v-col cols="12 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.amount.operator"
                :items="numericOptions"
                item-text="name"
                item-value="id"
                outlined
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.amount.operator != 3 && filters.amount.operator != 8 && filters.amount.operator != 9" cols="12 py-0">
              <v-text-field
                class="common_txtfield"
                v-model.number="filters.amount.value"
                outlined
                @keypress.native="onlyAcceptNumber"
                dense
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col v-if="filters.amount.operator == 3" cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.donation.from") }}</span>
              <v-text-field
                class="common_txtfield"
                v-model.number="filters.amount.lowerValue"
                outlined
                @keypress.native="onlyAcceptNumber"
                dense
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col v-if="filters.amount.operator == 3" cols="6 py-0">
              <span class="common_labels">{{ $t("page.reports.donation.to") }}</span>
              <v-text-field
                class="common_txtfield"
                v-model.number="filters.amount.upperValue"
                outlined
                @keypress.native="onlyAcceptNumber"
                dense
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <!--#endregion -->
            <!--#region Anonymous  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("dialog.donationedit.fields.isanonymous.title") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.isAnonymous.value"
                :items="booleanOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Matching Gift  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("dialog.donationedit.fields.isMatchingGift.title") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.isMatchingGift"
                :items="booleanOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Acknowledge  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.constituentDonations.headers.acknowledge") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.acknowledgeStatus.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.acknowledgeStatus.operator == 1 || filters.acknowledgeStatus.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.acknowledgeStatus.values"
                :items="acknowledgeList"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Campaign  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("dialog.donationedit.fields.campaign.title") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.campaign.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.campaign.operator == 1 || filters.campaign.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.campaign.values"
                :items="campaignsList"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Fund  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("dialog.donationedit.fields.fund.title") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.fund.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.fund.operator == 1 || filters.fund.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.fund.values"
                :items="fundsList"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Appeal  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("dialog.donationedit.fields.appeal.title") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.appeal.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.appeal.operator == 1 || filters.appeal.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.appeal.values"
                :items="appealsList"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Package  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("page.constituentDonations.headers.package") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.package.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.package.operator == 1 || filters.package.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.package.values"
                :items="packagesList"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Letter  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("dialog.donationedit.fields.letter.title") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.letterCode.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.letterCode.operator == 1 || filters.letterCode.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.letterCode.values"
                :items="letterCodeList"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
            <!--#region Budget Code  -->
            <v-col cols="12 py-0">
              <span class="common_labels">{{ $t("dialog.donationedit.fields.budgetCode.title") }}</span>
            </v-col>
            <v-col cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.budgetCode.operator"
                :items="dropdownOptions"
                item-text="name"
                item-value="id"
                outlined
                clearable
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <v-col v-if="filters.budgetCode.operator == 1 || filters.budgetCode.operator == 2" cols="6 py-0">
              <v-select
                class="common_txtfield"
                v-model="filters.budgetCode.values"
                :items="budgetCodeList"
                item-text="name"
                item-value="id"
                outlined
                clearable
                multiple
                dense
                persistent-placeholder
              ></v-select>
            </v-col>
            <!--#endregion -->
          </v-row>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from "../../../plugins/axios";
import { orderList } from "../../../utils/functions";
import CalendarField from "../../../components/Shared/ACalendarField.vue";

export default {
  name: "ReportConstituentFilters",
  components: { CalendarField },
  props: ["drawer", "value"],
  data() {
    return {
      dropdownOptions: [],
      numericOptions: [],
      donationTypeOptionSelected: null,
      donationSubTypeOptionSelected: null,
      donationAcknowledgeOptionSelected: null,
      donationCampaignOptionSelected: null,
      donationFundOptionSelected: null,
      donationAppealOptionSelected: null,
      donationPackageOptionSelected: null,
      donationLetterOptionSelected: null,
      donationBudgetCodeOptionSelected: null,
      donationPostStatusOptionSelected: null,
      donationReceiptStatusOptionSelected: null,
      donationPayMethodOptionSelected: null,
      constituentCountryOptionSelected: null,
      constituentLanguageOptionSelected: null,
      constituentCodeOptionSelected: null,
      // dropdowns list
      donationTypes: [],
      donationSubtypes: [],
      fundsList: [],
      campaignsList: [],
      appealsList: [],
      packagesList: [],
      acknowledgeList: [],
      letterCodeList: [],
      budgetCodeList: [],
      payMethodList: [],
      receiptStatusList: [],
      postStatusList: [],
      countries: [],
      categories: [],
      programs: [],
      transactionTypes: [],
      languages: [],
      constituentCodes: [],
      constituentTypes: [],
      standings: []
    };
  },
  computed: {
    booleanOptions() {
      return [
        { id: 1, name: this.$t("app.common.yes") },
        { id: 2, name: this.$t("app.common.no") }
      ];
    },
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    },
    isOpenDrawer: {
      get() {
        return this.drawer;
      },
      set(v) {
        if (!v) {
          this.$emit("hideFilters");
        }
      }
    },
    filters: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  watch: {},
  methods: {
    launchSearch() {
      this.$emit("search");
      this.isOpenDrawer = false;
    },
    loadDonationTypes() {
      axios
        .get("donation/types")
        .then(res => {
          this.donationTypes = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadFundsList() {
      axios
        .get("/fund/selectlist")
        .then(res => {
          this.fundsList = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadCampaignsList() {
      axios
        .get("/campaign/selectlist")
        .then(res => {
          this.campaignsList = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadAppealsList() {
      axios
        .get("/appeal/selectlist")
        .then(res => {
          this.appealsList = res.data;
        })
        .catch();
    },
    loadPackagesList() {
      axios
        .get("/package/selectlist")
        .then(res => {
          this.packagesList = res.data;
        })
        .catch();
    },
    loadAcknowledgeList() {
      axios
        .get("/acknowledgestatus")
        .then(res => {
          this.acknowledgeList = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadLetterCodeList() {
      axios
        .get("/lettercode")
        .then(res => {
          this.letterCodeList = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadDonationSubtypes() {
      axios
        .get("/transactionsubtype")
        .then(res => {
          this.donationSubtypes = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadBudgetCodeList() {
      axios
        .get("/budgetcode")
        .then(res => {
          this.budgetCodeList = res.data;
        })
        .catch();
    },
    loadCountryList() {
      axios
        .get("/country")
        .then(res => {
          this.countries = res.data;
        })
        .catch();
    },
    loadLanguageList() {
      axios
        .get("/language")
        .then(res => {
          this.languages = res.data;
        })
        .catch();
    },
    loadConstituentCodeList() {
      axios
        .get("/constituentcode/selectlist")
        .then(res => {
          this.constituentCodes = res.data;
        })
        .catch();
    },
    loadDropdownOperators() {
      axios
        .get("/constituent/filters/dropdown-operators")
        .then(res => {
          this.dropdownOptions = res.data;
        })
        .catch();
    },
    loadNumericOperators() {
      axios
        .get("/constituent/filters/numeric-operators")
        .then(res => {
          this.numericOptions = res.data;
        })
        .catch();
    },
    loadConstituentTypes() {
      axios
        .get("/constituent/types")
        .then(res => {
          this.constituentTypes = res.data;
        })
        .catch();
    },
    loadPrograms() {
      axios
        .get("/membershipProgram/selectList")
        .then(res => {
          this.programs = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadCategories() {
      axios
        .get("/membershipCategory/selectList")
        .then(res => {
          this.categories = orderList(res.data, "name", true);
        })
        .catch();
    },
    loadTransactionTypes() {
      axios
        .get("/transactionType")
        .then(res => {
          this.transactionTypes = orderList(res.data, "name", true);
        })
        .catch();
    }
  },
  created() {
    this.loadDonationTypes();
    this.loadFundsList();
    this.loadCampaignsList();
    this.loadAppealsList();
    this.loadPackagesList();
    this.loadAcknowledgeList();
    this.loadLetterCodeList();
    this.loadDonationSubtypes();
    this.loadBudgetCodeList();
    this.loadCountryList();
    this.loadLanguageList();
    this.loadConstituentCodeList();
    this.loadDropdownOperators();
    this.loadNumericOperators();
    this.loadPrograms();
    this.loadCategories();
    this.loadTransactionTypes();
    this.loadConstituentTypes();
  }
};
</script>
