<template>
  <div class="constituents-addresses_container">
    <v-row>
      <v-col cols="12">
        <v-data-table
          class="hide-centered-filterarrow elevation-1 rounded-0"
          :headers="headers"
          :items="memberships"
          :options.sync="options"
          :server-items-length="totalMemberships"
          :loading="loading"
          height="75vh"
          dense
        >
          <template v-slot:item.membership_Joined_Date="{ item }">{{ getDate(item.membership_Joined_Date) }}</template>
          <template v-slot:item.membership_Start_Date="{ item }">{{ getDate(item.membership_Start_Date) }}</template>
          <template v-slot:item.membership_Expiry_Date="{ item }">{{ getDate(item.membership_Expiry_Date) }}</template>
          <template v-slot:item.constituent_IsGivingAnonymously="{ item }">
            <v-icon x-small :class="item.constituent_IsGivingAnonymously ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
          <template v-slot:item.constituent_IsDoNotSolicit="{ item }">
            <v-icon x-small :class="item.constituent_IsDoNotSolicit ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
          <template v-slot:item.constituent_IsDeceased="{ item }">
            <v-icon x-small :class="item.constituent_IsDeceased ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ReportMembershipResults",
  components: {},
  props: ["result", "loading"],
  data() {
    return {
      memberships: [],
      totalMemberships: 0,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        size: 10,
        sortBy: [],
        sortDesc: true
      },
      headers: [
        {
          text: this.$t("page.reports.constituent.id"),
          value: "constituent_Id"
        },
        {
          text: this.$t("page.reports.constituent.title"),
          value: "title"
        },
        {
          text: this.$t("page.reports.constituent.firstName"),
          value: "first_Name"
        },
        {
          text: this.$t("page.reports.constituent.middleName"),
          value: "middle_Name"
        },
        {
          text: this.$t("page.reports.constituent.lastName"),
          value: "last_Name"
        },
        {
          text: this.$t("page.reports.constituent.maidenName"),
          value: "maiden_Name"
        },
        {
          text: this.$t("page.reports.constituent.gender"),
          value: "gender"
        },
        {
          text: this.$t("page.reports.constituent.suffix"),
          value: "suffix"
        },
        {
          text: this.$t("page.reports.constituent.communicationLanguage"),
          value: "preferred_Language"
        },
        {
          text: this.$t("page.reports.constituent.profession"),
          value: "profession"
        },
        {
          text: this.$t("page.reports.constituent.constituentCode"),
          value: "constituent_Code"
        },
        {
          text: this.$t("page.reports.constituent.primaryPhoneNumber"),
          value: "primary_Phone_Number"
        },
        {
          text: this.$t("page.reports.constituent.primaryEmailAddresses"),
          value: "primary_Email_Address"
        },
        {
          text: this.$t("page.reports.membership.id"),
          value: "membership_Id"
        },
        {
          text: this.$t("page.reports.membership.standing"),
          value: "standing"
        },
        {
          text: this.$t("page.reports.membership.program"),
          value: "membership_Program"
        },
        {
          text: this.$t("page.reports.membership.category"),
          value: "membership_Category"
        },
        {
          text: this.$t("page.reports.membership.joinDate"),
          value: "membership_Joined_Date"
        },
        {
          text: this.$t("page.reports.membership.detailId"),
          value: "membership_Detail_Id"
        },
        {
          text: this.$t("page.reports.membership.startDate"),
          value: "membership_Start_Date"
        },
        {
          text: this.$t("page.reports.membership.transactionType"),
          value: "membership_Transaction_Type"
        },
        {
          text: this.$t("page.reports.membership.expiryDate"),
          value: "membership_Expiry_Date"
        },
        {
          text: this.$t("page.reports.membership.dues"),
          value: "dues"
        },
        // {
        //   text: this.$t("page.reports.membership.campaign"),
        //   value: "campaign"
        // },
        // {
        //   text: this.$t("page.reports.membership.fund"),
        //   value: "fund"
        // },
        // {
        //   text: this.$t("page.reports.membership.appeal"),
        //   value: "appeal"
        // },
        // {
        //   text: this.$t("page.reports.membership.package"),
        //   value: "package"
        // },
        {
          text: this.$t("page.reports.membership.country"),
          value: "country",
          width: "150px"
        },
        {
          text: this.$t("page.reports.membership.constituentId"),
          value: "constituent_Code"
        },
        {
          text: this.$t("page.reports.constituent.isDeceased"),
          value: "constituent_IsDeceased"
        },
        {
          text: this.$t("page.reports.constituent.isDoNotSolicit"),
          value: "constituent_IsDoNotSolicit"
        },
        {
          text: this.$t("page.reports.constituent.isGivingAnonymously"),
          value: "constituent_IsGivingAnonymously"
        }
      ]
    };
  },
  computed: {},
  watch: {
    options: {
      handler(v) {
        this.$emit("changePagination", v);
      },
      deep: true
    },
    result: {
      handler(v) {
        this.memberships = v.records || [];
        this.totalMemberships = v.totalCount || 0;
      },
      deep: true
    }
  },
  methods: {
    getDate(date) {
      return date
        ? this.$moment(date)
            .local()
            .format(this.$store.getters.generalDateFormat)
        : "N/A";
    }
  }
};
</script>
