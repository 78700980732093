<template>
  <v-app>
    <app-bar></app-bar>
    <app-drawer></app-drawer>
    <v-main class="background-color">
      <v-row>
        <v-col class="col-xl-10 offset-xl-1 pb-0">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import AppBar from "../components/Layout/AppBar.vue";
import AppDrawer from "../components/Layout/AppDrawer.vue";
export default {
  components: { AppBar, AppDrawer },
  //components: { AppBar },
  data() {
    return {};
  },
  computed: {},
  watch: {}
};
</script>
