export const state = {
    editDialog: false,
    linkDialog: false,
    constituentSelected: null,
    constituentName: '',
};

export const mutations = {
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
    setLinkDialog(state, linkDialog) {
        state.linkDialog = linkDialog;
    },
    setConstituentSelected(state, constituentSelected) {
        state.constituentSelected = constituentSelected;
    },
    setConstituentName(state, constituentName) {
        state.constituentName = constituentName;
    },
};

export const getters = {
    editDialog: state => state.editDialog,
    linkDialog: state => state.linkDialog,
    constituentSelected: state => state.constituentSelected,
    constituentName: state => state.constituentName
}