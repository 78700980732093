<template>
  <div class="pt-8">
    <div>
      <v-row class="text-h4 bold secondary--text pt-8  mx-12 bold">
        New reports
      </v-row>
      <v-row class="d-flex  pt-6 mx-10">
        <div class=""
             v-for="(item, index) in items"
             :key="index"
        >
          <v-tooltip v-if="!item.active" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-card v-bind="attrs"
                      v-on="on"
                      style="border-left: solid rgba(0, 0, 0, 0.5)  3px; width: 205px"
                      class="d-flex mx-2 justify-center "
              >
                <v-icon color="grey" size="40px">
                  {{ item.icon }}
                </v-icon>
                <v-card-title class="text-subtitle-1 bold grey--text">
                  {{ item.title }}
                </v-card-title>
              </v-card>
            </template>
            <span>This functionality is not available yet</span>
          </v-tooltip>
          <v-card v-else @click="toRoute(item.route)" class="d-flex mx-2 justify-center " style="cursor: pointer; border-left: solid red  3px; width: 205px">
            <v-icon color="primary" size="40px">
              {{ item.icon }}
            </v-icon>
            <v-card-title class="text-subtitle-1 bold primary--text">
              {{ item.title }}
            </v-card-title>
          </v-card>

        </div>
      </v-row>
    </div>

    <!--    <v-divider class="mt-8"></v-divider>-->
    <!--    <div class="mt-8">-->
    <!--      <v-row class="d-flex justify-space-around text-h4 bold secondary&#45;&#45;text">-->
    <!--        Saved Reports-->
    <!--      </v-row>-->
    <!--    </div>-->

  </div>
</template>
<script>

export default {
  data() {
    return {
      items: [
        {
          title: "Constituent",
          active: true,
          route:'ReportConstituent',
          icon:' mdi-account-outline'
        },
        {
          title: "Memberships",
          active: true,
          route:'ReportMembership',
          icon:'mdi-star-circle-outline'
        },
        {
          title: "Donations",
          active: true,
          route:'ReportDonation',
          icon:" mdi-gift-outline"
        },
        {
          title: "Pledges",
          active: false,
          route:'',
          icon:"mdi-handshake-outline"
        },
        {
          title: "Communications",
          active: false,
          route:'',
          icon:"mdi-email-outline"
        },
        {
          title: "Events",
          active: false,
          route:'',
          icon:"mdi-ticket-confirmation-outline"
        },
      ]
    };
  },
  methods: {
    toRoute(nameRoute){
      this.$router.push({name:nameRoute})
    }
  }
};
</script>
