<template>
  <v-card fluid>
    <v-progress-linear :active="isEdit && loading" indeterminate color="primary"></v-progress-linear>
    <v-card-text class="pb-0">
      <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + fund.id">
        <v-row class="common_container">
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.fundConfig.name") }}</span>
            <v-text-field
              class="common_txtfield"
              v-model="fund.name"
              outlined
              dense
              required
              :rules="[v => !!v || $t('dialog.error.required')]"
              persistent-placeholder
            ></v-text-field>
          </v-col>
          <v-col cols="2 pb-0">
            <span class="common_labels">{{ $t("page.fundConfig.active") }}</span>
            <v-checkbox class="common_checkbox" dense color="primary" v-model="fund.isActive" />
          </v-col>
          <v-col cols="12 py-0">
            <span class="common_labels">{{ $t("page.fundConfig.description") }}</span>
            <v-text-field class="common_txtfield" v-model="fund.description" outlined dense persistent-placeholder></v-text-field>
          </v-col>
          <v-col cols="4 py-0">
            <span class="common_labels">{{ $t("page.fundConfig.category") }}</span>
            <v-select
              class="common_txtfield"
              v-model="fund.fundCategoryId"
              :items="categories"
              item-text="name"
              item-value="id"
              outlined
              dense
              persistent-placeholder
            ></v-select>
          </v-col>
          <v-col cols="4 py-0">
            <span class="common_labels">{{ $t("page.fundConfig.type") }}</span>
            <v-select
              class="common_txtfield"
              v-model="fund.fundTypeId"
              :items="types"
              item-text="name"
              item-value="id"
              outlined
              dense
              persistent-placeholder
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="common_container">
          <v-col cols="4 py-0">
            <span class="common_labels">{{ $t("page.fundConfig.startDate") }}</span>
            <calendar-field v-model="fund.startDate" :valeur="fund.startDate" :dateFormat="dateFormat"> </calendar-field>
          </v-col>
          <v-col cols="4 py-0">
            <span class="common_labels">{{ $t("page.fundConfig.endDate") }}</span>
            <calendar-field v-model="fund.endDate" :valeur="fund.endDate" :dateFormat="dateFormat" :rules="endDateRule(fund.endDate)">
            </calendar-field>
          </v-col>
          <v-col cols="12 py-0">
            <span class="common_labels">{{ $t("page.fundConfig.notes") }}</span>
            <v-textarea v-model="fund.comment" outlined dense class="common_txtfield" persistent-placeholder> </v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-container class="px-0 pt-0">
        <v-btn class="float-right mr-2" small color="secondary" @click="save">
          {{ $t("app.actions.save") }}
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "../../plugins/axios";
import CalendarField from "../Shared/ACalendarField.vue";
import { orderList } from "../../utils/functions";

export default {
  components: { CalendarField },
  data() {
    return {
      form: null,
      fund: {},
      categories: [],
      types: [],
      isFromPickerOpen: false,
      isToPickerOpen: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "fund/isEdit"
    }),
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    }
  },
  watch: {},
  methods: {
    loadFund() {
      this.loading = true;
      const fundId = this.$store.getters["fund/selected"];
      axios
        .get(`fund/${fundId}`)
        .then(res => {
          if (res && res.data) {
            this.fund = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
          this.loading = false;
        });
    },
    loadCategories() {
      axios
        .get("fundcategory/selectlist")
        .then(res => {
          this.categories = orderList(res.data, "name", true);
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail to load the categories"
          });
        });
    },
    loadTypes() {
      axios
        .get("fundtype/selectlist")
        .then(res => {
          this.types = orderList(res.data, "name", true);
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail to load the funds"
          });
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        let requestCall = null;

        if (!this.fund.id) {
          requestCall = axios.post("/fund", this.fund);
        } else {
          requestCall = axios.put(`fund/${this.fund.id}`, this.fund);
        }
        requestCall
          .then(res => {
            this.$store.commit("fund/setFundKey");
            this.$store.commit("fund/clearSelected");
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.$store.commit("fund/setEditDialog", false);
          })
          .catch(exception => {
            const message = exception.response.data ? exception.response.data[0].brokenRuleReason : "";
            this.$store.commit("notification/showSnackbar", {
              message: `Fail: ${message}`
            });
          });
      }
    },
    endDateRule(v) {
      return [
        v => {
          if (
            this.fund.startDate != null &&
            this.fund.endDate != null &&
            (this.$moment(this.fund.endDate).isBefore(this.fund.startDate) || this.$moment(this.fund.endDate).isSame(this.fund.startDate))
          ) {
            return this.$t("dialog.membershipdialog.endDateAfterError");
          } else return true;
        }
      ];
    }
  },
  created() {
    this.loadCategories();
    this.loadTypes();
    if (this.isEdit) {
      this.loadFund();
    } else {
      this.fund.isActive = true;
    }
  }
};
</script>
