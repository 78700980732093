<template>
  <div>
    <v-container fluid>
      <v-toolbar elevation="0">
        <v-row>
          <v-col offset="10 pr-0 text-end">
            <v-btn color="secondary" small @click="onAddAppeal">{{$t('component.fundDetails.addAppeal')}}</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table 
        :items="appeals" 
        item-key="id" 
        :headers="headers"
        :loading="loading" 
        :items-per-page="5"
      >
       <template v-slot:item.action="{item}">
          <slot name="row-action">
            <v-icon small @click="onDeleteAppeal(item)">mdi-delete</v-icon>
          </slot>
        </template>
      </v-data-table>
    </v-container>
    <add-appeal-fund-dialog 
      v-model="addAppealDialog"
      :addAppealDialog="addAppealDialog"
      @close="addAppealDialog = false"
    >
    </add-appeal-fund-dialog>
    <confirm-dialog :dialog="deleteAppealDialog" @btnClicked="hasConfirmedDelete($event)"></confirm-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from "../../plugins/axios";
import AddAppealFundDialog from './AddAppealFundDialog.vue'
import ConfirmDialog from '../Dialogs/ConfirmDialog.vue';

export default {
  components: { ConfirmDialog, AddAppealFundDialog },
  data: () => ({
    appeals: [],
    addAppealDialog: false,
    deleteAppealDialog: false,
    appealToDelete: null,
    loading: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("component.fundDetails.appealName"),
          value: "name"
        },
        {
          text: this.$t("component.fundDetails.appealDescription"),
          value: "description"
        },
        {
          text: this.$t("app.common.actions"),
          value: "action",
          width: "10%",
          sortable: false,
          align: "center"
        },
      ];
    },
    ...mapGetters({
      fundId: "fund/selected",
      isEdit: "fund/isEdit",
      appealKey: "fund/appealKey"
    }),
  },
  watch: {
    appealKey() {
      this.loadAppeals();
    }
  },
  methods: {
    loadAppeals() {
      this.loading = true;
      axios
        .get(`appeal/fund/${this.fundId}`)
        .then(res => {
          this.appeals = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
          this.loading = false;
        });
    },
    onAddAppeal() {
      this.addAppealDialog = true;
    },
    onDeleteAppeal(appeal) {
      this.deleteAppealDialog = true;
      this.appealToDelete = appeal;
    },
    hasConfirmedDelete(isConfirmed) {
      if (isConfirmed) {
        const appealId = this.appealToDelete.id;
        this.appealToDelete.fundId = null;
        
        axios
          .delete(`appeal/${appealId}`, this.appealToAdd)
          .then(() => {
            this.loadAppeals();
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.deleteAppealDialog = false;
            this.appealToDelete = null;
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail"
            });
          });
      } else {
        this.deleteAppealDialog = false;
        this.appealToDelete = null;
      }
    },
  },
  created() {
    if(this.isEdit)
      this.loadAppeals();
  }
};
</script>