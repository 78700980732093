export const state = {
    selected: null,
    nameSelected: null,
    isEdit: false,
    professionKey: 0,
    editDialog: false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, professionId) {
        state.selected = professionId;
    },
    setNameSelected(state, professionName) {
        state.nameSelected = professionName;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setProfessionKey(state) {
        state.professionKey = state.professionKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    nameSelected: state => state.nameSelected,
    isEdit: state => state.isEdit,
    professionKey: state => state.professionKey,
    editDialog: state => state.editDialog
}