<template>
  <v-dialog v-model="editDialog" width="1100px" :key="editDialog" content-class="common-config-edit_dialog" persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid class="py-0">
        <v-tabs v-model="tab" background-color color="primary">
          <v-tab>{{$t('component.fundDetails.details')}}</v-tab>
          <v-tab v-if="isEdit">{{$t('page.fundConfig.campaign')}}</v-tab>
          <v-tab v-if="isEdit">{{$t('page.fundConfig.appeal')}}</v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <detail-fund-info></detail-fund-info>
            </v-tab-item>
            <v-tab-item v-if="isEdit">
              <campaign-fund-table></campaign-fund-table>
            </v-tab-item>
            <v-tab-item v-if="isEdit">
              <appeal-fund-table></appeal-fund-table>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import AppealFundTable from './AppealFundTable.vue';
import CampaignFundTable from './CampaignFundTable.vue';
import DetailFundInfo from './DetailFundInfo.vue';

export default {
  components: { DetailFundInfo, AppealFundTable, CampaignFundTable },
  data: () => ({
    tab: 0,
  }),
  computed: { 
    ...mapGetters({
      isEdit: "fund/isEdit",
      editDialog: "fund/editDialog",
      nameSelected: "fund/nameSelected"
    }),
    title() {
      return this.isEdit ? this.nameSelected : this.$t("component.fundDetails.add");
    }
  },
  watch: {
    editDialog(v) {
      if (!v) this.$store.commit("fund/clearSelected");
      this.$emit("input", v);
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("fund/setEditDialog", false);
      this.tab = 0;
    }
  },
};
</script>