<template>
  <v-sheet elevation="0">
    <router-view></router-view>
  </v-sheet>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>