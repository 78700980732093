<template>
  <v-dialog v-model="openDialog" width="1000px" :key="openDialog" persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ $t('component.appealDetails.addPackage') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pb-0 pt-4">
        <v-container>
          <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + appealId">
            <v-row class="common_container">
              <v-col cols="4 pb-0">
                <span class="common_labels">{{ $t('component.appealDetails.packageName') }}</span>
                <v-text-field
                  class="common_txtfield"
                  v-model="newPackage.name"
                  outlined
                  dense
                  required
                  :rules="[v => !!v || $t('dialog.error.required')]"
                  persistent-placeholder
                ></v-text-field>
              </v-col>
              <v-col cols="4 pb-0">
                <span class="common_labels">{{ $t('component.appealDetails.packageCategory') }}</span>
                <v-select
                  class="common_txtfield"
                  v-model="newPackage.packageCategoryId"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  persistent-placeholder
                ></v-select>
              </v-col>
              <v-col cols="2 pb-0">
                <span class="common_labels">{{ $t('page.appealConfig.active') }}</span>
                <v-checkbox class="common_checkbox" dense color="primary" v-model="newPackage.isActive" />
              </v-col>
              <v-col cols="12 py-0">
                <span class="common_labels">{{ $t('page.appealConfig.description') }}</span>
                <v-text-field
                  class="common_txtfield"
                  v-model="newPackage.description"
                  outlined
                  dense
                  persistent-placeholder
                ></v-text-field>
              </v-col>
              <v-col cols="4 py-0">
                <span class="common_labels">{{ $t('page.appealConfig.startDate') }}</span>
                <calendar-field 
                  v-model="newPackage.startDate"
                  :dateFormat="dateFormat"
                >
                </calendar-field>
              </v-col>
              <v-col cols="4 py-0">
                <span class="common_labels">{{ $t('page.appealConfig.endDate') }}</span>
                <calendar-field 
                  v-model="newPackage.endDate" 
                  :dateFormat="dateFormat"
                  :rules="endDateRule(newPackage.endDate)"
                >
                </calendar-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-container class="pt-0">
          <v-btn class="float-right mr-2" small color="secondary" @click="save">
            {{ $t("app.actions.save") }}
          </v-btn>
        </v-container>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from "../../plugins/axios";
import CalendarField from "../Shared/ACalendarField.vue";

export default {
  components: { CalendarField },
  props: ['addPackageDialog'],
  data() {
    return {
      openDialog: false,
      newPackage: {},
      categories: [],
      form: null
    }
  },
  computed: { 
    ...mapGetters({
      appealId: "appeal/selected"
    }),
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    },
  },
  watch: { 
    openDialog(v) {
      this.$emit("input", v);
    },
    addPackageDialog(v) {
      this.openDialog = v;
    },
  },
  methods: {
    loadCategories() {
      axios
        .get('packagecategory/selectlist')
        .then(res => {
          if(res && res.data) {
            this.categories = res.data;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.newPackage.appealId = this.appealId;

        axios
          .post('package', this.newPackage)
          .then(res => {
            if(res && res.data) {
              this.$store.commit('appeal/setPackageKey');
              this.$store.commit("notification/showSnackbar", {
                message: "Success"
              });
              this.closeDialog();
            }
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail"
            });
          });
      }
    },
    endDateRule(v) {
      return [
        v => {
          if (
            this.newPackage.startDate != null &&
            this.newPackage.endDate != null &&
            ( this.$moment(this.newPackage.endDate).isBefore(this.newPackage.startDate) || 
              this.$moment(this.newPackage.endDate).isSame(this.newPackage.startDate))
          ) {
            return this.$t("dialog.membershipdialog.endDateAfterError");
          } else return true;
        }
      ];
    },
    closeDialog() {
      this.openDialog = false;
      this.newPackage = {};
    }
  },
  created() {
    this.loadCategories();
  }
};
</script>