<template>
  <div class="constituents-addresses_container">
    <v-row>
      <v-col cols="12">
        <v-data-table
          class="hide-centered-filterarrow elevation-1 rounded-0"
          :headers="headers"
          :items="donations"
          :options.sync="options"
          :server-items-length="totalDonations"
          :loading="loading"
          height="75vh"
          dense
        >
          <template v-slot:item.transaction_Date="{ item }">{{ getDate(item.transaction_Date) }}</template>
          <template v-slot:item.gL_Post_Date="{ item }">{{ getDate(item.gL_Post_Date) }}</template>
          <template v-slot:item.receipt_Date="{ item }">{{ getDate(item.receipt_Date) }}</template>
          <template v-slot:item.cheque_Date="{ item }">{{ getDate(item.cheque_Date) }}</template>
          <template v-slot:item.creation_Date="{ item }">{{ getDate(item.creation_Date) }}</template>
          <template v-slot:item.is_Anonymous="{ item }">
            <v-icon x-small :class="item.is_Anonymous ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
          <template v-slot:item.is_Matching_Gift="{ item }">
            <v-icon x-small :class="item.is_Matching_Gift ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
          <template v-slot:item.constituent_IsGivingAnonymously="{ item }">
            <v-icon x-small :class="item.constituent_IsGivingAnonymously ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
          <template v-slot:item.constituent_IsDoNotSolicit="{ item }">
            <v-icon x-small :class="item.constituent_IsDoNotSolicit ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
          <template v-slot:item.constituent_IsDeceased="{ item }">
            <v-icon x-small :class="item.constituent_IsDeceased ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ReportDonationResults",
  components: {},
  props: ["result", "loading"],
  data() {
    return {
      donations: [],
      totalDonations: 0,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        size: 10,
        sortBy: [],
        sortDesc: true
      },
      headers: [
        {
          text: this.$t("page.reports.donation.constituent"),
          value: "constituent_Id"
        },
        {
          text: this.$t("page.reports.donation.title"),
          value: "title"
        },
        {
          text: this.$t("page.reports.donation.firstName"),
          value: "first_Name",
          width: 140
        },
        {
          text: this.$t("page.reports.donation.middleName"),
          value: "middle_Name"
        },
        {
          text: this.$t("page.reports.donation.lastName"),
          value: "last_Name"
        },
        {
          text: this.$t("page.reports.donation.maidenName"),
          value: "maiden_Name"
        },
        {
          text: this.$t("page.reports.donation.gender"),
          value: "gender"
        },
        {
          text: this.$t("page.reports.donation.suffix"),
          value: "suffix"
        },
        {
          text: this.$t("page.reports.donation.communicationLanguage"),
          value: "preferred_Language"
        },
        {
          text: this.$t("page.reports.donation.profession"),
          value: "profession"
        },
        {
          text: this.$t("page.reports.donation.constituentId"),
          value: "constituent_Code"
        },
        {
          text: this.$t("page.reports.constituent.isDeceased"),
          value: "constituent_IsDeceased"
        },
        {
          text: this.$t("page.reports.constituent.isDoNotSolicit"),
          value: "constituent_IsDoNotSolicit"
        },
        {
          text: this.$t("page.reports.constituent.isGivingAnonymously"),
          value: "constituent_IsGivingAnonymously"
        },
        {
          text: this.$t("page.reports.donation.primaryPhone"),
          value: "primary_Phone_Number"
        },
        {
          text: this.$t("page.reports.donation.primaryEmail"),
          value: "primary_Email_Address"
        },
        {
          text: this.$t("page.reports.donation.donationId"),
          value: "donation_Id"
        },
        {
          text: this.$t("page.reports.donation.date"),
          value: "transaction_Date"
        },
        {
          text: this.$t("page.reports.donation.creationDate"),
          value: "creation_Date"
        },
        {
          text: this.$t("page.reports.donation.donationType"),
          value: "donation_Type"
        },
        {
          text: this.$t("page.reports.donation.subType"),
          value: "donation_Subtype"
        },
        {
          text: this.$t("page.reports.donation.amountFinancial"),
          value: "amount"
        },
        {
          text: this.$t("page.reports.donation.isanonymous"),
          value: "is_Anonymous",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("page.reports.donation.isMatchingGift"),
          value: "is_Matching_Gift",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("page.reports.donation.acknowledge"),
          value: "acknowledge_Status"
        },
        {
          text: this.$t("page.reports.donation.campaign"),
          value: "campaign"
        },
        {
          text: this.$t("page.reports.donation.fund"),
          value: "fund"
        },
        {
          text: this.$t("page.reports.donation.appeal"),
          value: "appeal"
        },
        {
          text: this.$t("page.reports.donation.package"),
          value: "package"
        },
        {
          text: this.$t("page.reports.donation.letterCode"),
          value: "letter_Code"
        },
        {
          text: this.$t("page.reports.donation.budgetCode"),
          value: "budget_Code"
        },
        {
          text: this.$t("page.reports.donation.glPostStatus"),
          value: "gL_Post_Status"
        },
        {
          text: this.$t("page.reports.donation.glPostDate"),
          value: "gL_Post_Date"
        },
        {
          text: this.$t("page.reports.donation.receiptStatus"),
          value: "receipt_Status"
        },
        {
          text: this.$t("page.reports.donation.receiptAmount"),
          value: "receipt_Amount"
        },
        {
          text: this.$t("page.reports.donation.receiptDate"),
          value: "receipt_Date"
        },
        {
          text: this.$t("page.reports.donation.payMethod"),
          value: "pay_Method"
        },
        {
          text: this.$t("page.reports.donation.chequeNumber"),
          value: "cheque_Number"
        },
        {
          text: this.$t("page.reports.donation.chequeDate"),
          value: "cheque_Date"
        },
        {
          text: this.$t("page.reports.donation.cardType"),
          value: "card_Type"
        },
        {
          text: this.$t("page.reports.donation.cardNumber"),
          value: "card_Number"
        },
        {
          text: this.$t("page.reports.donation.cardHolder"),
          value: "card_Holder"
        },
        {
          text: this.$t("page.reports.donation.authentificationCode"),
          value: "authorization_Code"
        },
        {
          text: this.$t("page.reports.donation.directDebit"),
          value: "direct_Debit"
        },
        {
          text: this.$t("page.reports.donation.bank"),
          value: "bank"
        },
        {
          text: this.$t("page.reports.donation.currencyExchangeRate"),
          value: "currencyExchangeRate"
        },
        {
          text: this.$t("page.reports.donation.currencyAmount"),
          value: "currencyAmount"
        },
        {
          text: this.$t("page.reports.donation.currencyReceiptAmount"),
          value: "currencyReceiptAmount"
        },
        {
          text: this.$t("page.reports.donation.currencyCountry"),
          value: "currencyCountry"
        }
      ]
    };
  },
  computed: {},
  watch: {
    options: {
      handler(v) {
        this.$emit("changePagination", v);
      },
      deep: true
    },
    result: {
      handler(v) {
        this.donations = v.records || [];
        this.totalDonations = v.totalCount || 0;
      },
      deep: true
    }
  },
  methods: {
    getDate(date) {
      return date
        ? this.$moment(date)
            .local()
            .format(this.$store.getters.generalDateFormat)
        : "N/A";
    }
  }
};
</script>
