<template>
  <v-main class="background">
    <v-container fluid class="h-100">
      <img src="../../assets/img/WFH_Logo.png" class="login-logo" />
      <v-row justify="center" align="center" class="h-100" no-gutters>
        <v-col md="6" sm="10">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>
