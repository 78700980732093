<template>
  <v-dialog v-model="showNote" width="1100px"  persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container  class="py-2">
        <v-card flat>
          <v-card-text>
            <v-textarea
                outlined
                v-model="note"

            ></v-textarea>
          </v-card-text>
          <v-card-actions class="d-flex">
            <v-container class="px-0 pt-0">
              <v-btn class="float-right mr-2" small color="secondary" @click="save">
                {{ $t("app.actions.save") }}
              </v-btn>
            </v-container>
          </v-card-actions>
        </v-card>

      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import TableConstituentsFindInfo from "../../BatchTemplates/TableConstituentsFindInfo";
export default {
  components: {  },
  data: () => ({
    note: ""
  }),
  computed: {
    ...mapGetters({
      showNote: "batch/showNote",
      noteValue: "batch/noteValue",
    }),
    title() {
      return this.$t("batch.templates.addNote")
    },
  },
  mounted() {
    this.note = this.noteValue
  },
  watch: {
  },
  methods: {
    closeDialog() {
      this.$store.commit("batch/showNote", false);
    },
    save(){
      this.$emit('updateNote', this.note)
      this.$store.commit("batch/showNote", false);

    }
  }
};
</script>