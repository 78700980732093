export const state = {
    selected: null,
    nameSelected: null,
    isEdit: false,
    codeKey: 0,
    editDialog: false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, codeId) {
        state.selected = codeId;
    },
    setNameSelected(state, codeName) {
        state.nameSelected = codeName;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setCodeKey(state) {
        state.codeKey = state.codeKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    nameSelected: state => state.nameSelected,
    isEdit: state => state.isEdit,
    codeKey: state => state.codeKey,
    editDialog: state => state.editDialog
}