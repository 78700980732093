import axios from "../../plugins/axios";

export const state = {
    actionCategories: [],
    actionStatuses: [],
    actionTypes: [],
    constituentActions: [],
    userActions: [],
};
export const mutations = {
    setActionCategories(state, categories) {
        if (categories == null) {
            categories = []
        }
        state.actionCategories = categories
    },
    setActionStatuses(state, statuses) {
        if (statuses == null) {
            statuses = []
        }
        state.actionStatuses = statuses
    },
    setActionTypes(state, types) {
        if (types == null) {
            types = [];
        }
        state.actionTypes = types;
    },
    setConstituentActions(state, actions) {
        if (actions == null) {
            actions = []
        }
        state.constituentActions = actions;
    }
}
export const actions = {
    loadActionCategories({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/action/categories').then(res => {
                let types = res.data
                commit('setActionCategories', types)
                resolve();
            }).catch(e => reject(e))
        })
    },
    loadActionStatuses({ commit }) {
        return new Promise((resolve, reject) => {
            //if (!state.isNameTitlesLoaded || forceLoad) {
            axios.get('/action/statuses').then(res => {
                let types = res.data
                commit('setActionStatuses', types)
                resolve();
            }).catch(e => reject(e))
            // }
            // else {
            //     resolve();
            // }
        })
    },
    loadActionTypes({ commit }) {
        return new Promise((resolve, reject) => {
            //if (!state.isNameTitlesLoaded || forceLoad) {
            axios.get('/action/types').then(res => {
                let types = res.data
                commit('setActionTypes', types)
                resolve();
            }).catch(e => reject(e))
            // }
            // else {
            //     resolve();
            // }
        })
    },
    loadConstituentActions({ commit }, { constituentId }) {
        return new Promise((resolve, reject) => {
            axios.get('action/constituent/' + constituentId).then(res => {
                let actions = res.data;
                commit('setConstituentActions', actions)
                resolve();
            }).catch(e => reject(e))
        })
    },
    loadUserActions({ commit }, { userId }) {
        return new Promise((resolve, reject) => {

        })
    }
}
export const getters = {
    actionCategories: state => state.actionCategories,
    actionStatuses: state => state.actionStatuses,
    actionTypes: state => state.actionTypes,
    constituentActions: state => state.constituentActions,
    userActions: state => state.userActions,
}