<template>
  <v-dialog v-model="editDialog" width="1100px" :key="editDialog" content-class="common-config-edit_dialog" persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid class="py-0">
        <v-tabs v-model="tab" background-color color="primary">
          <v-tab>{{$t('component.benefitDetails.details')}}</v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <detail-benefit-info></detail-benefit-info>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import DetailBenefitInfo from './DetailBenefitInfo.vue';

export default {
  components: { DetailBenefitInfo },
  data: () => ({
    tab: 0,
  }),
  computed: { 
    ...mapGetters({
      isEdit: "benefit/isEdit",
      editDialog: "benefit/editDialog",
      nameSelected: "benefit/nameSelected"
    }),
    title() {
      return this.isEdit ? this.nameSelected : this.$t("component.benefitDetails.add");
    }
  },
  watch: {
    editDialog(v) {
      if (!v) this.$store.commit("benefit/clearSelected");
      this.$emit("input", v);
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("benefit/setEditDialog", false);
      this.tab = 0;
    }
  },
};
</script>