export const state = {
    selected: null,
    nameSelected: null,
    isEdit: false,
    contactTypeKey: 0,
    editDialog: false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, contactTypeId) {
        state.selected = contactTypeId;
    },
    setNameSelected(state, contactTypeName) {
        state.nameSelected = contactTypeName;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setContactTypeKey(state) {
        state.contactTypeKey = state.contactTypeKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    nameSelected: state => state.nameSelected,
    isEdit: state => state.isEdit,
    contactTypeKey: state => state.contactTypeKey,
    editDialog: state => state.editDialog
}