<template>
  <div class="col-m-12 offset-m-1 col">
    <v-sheet class="constituents-layout_container mt-3" elevation="0">
      <v-container class="constituents-layout_container-inr">
        <v-row class="constituents-layout_details-row">
          <div class="constituents-layout_drawer-col drawer">
            <config-menu />
          </div>
          <div class="constituents-layout_infos-col">
            <router-view></router-view>
          </div>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import axios from "../../plugins/axios";

import ConfigMenu from "./ConfigMenu";

export default {
  components: {
    ConfigMenu
  },
  data() {
    return {
      headerInformation: {},
      constituent: {},
      isMember: null
    };
  },
  watch: {
    constituentContactInfoRefreshKey() {
      this.getHeaderInformation();
    },
    membershipRefreshKey() {
      this.getConstituent();
    }
  },
  computed: {
    constituentContactInfoRefreshKey() {
      return this.$store.getters["constituent/constituentContactInfoRefreshKey"];
    },
    membershipRefreshKey() {
      return this.$store.getters["constituent/getMembershipRefreshKey"];
    },
    constituentId() {
      return this.$store.getters["constituent/constituentId"];
    },
    id() {
      if (this.constituent == null) {
        return null;
      }

      return this.constituent.id;
    },
    isActive() {
      if (this.isNew) return true;
      return this.constituent.isActive;
    },
    isNew() {
      return this.constituent == null || !("id" in this.constituent) || this.constituent.id == null;
    }
    // name() {
    //   let constituent = this.constituent;
    //   if (constituent == null) return "";
    //   if (constituent.constituentType == "Individual") {
    //     return `${constituent.firstName || ""} ${constituent.lastName || ""}`;
    //   } else {
    //     return constituent.name || "";
    //   }
    // },
  },
  methods: {
    getHeaderInformation() {
      axios.get(`constituent/getheaderinformation/${this.constituentId}`).then(res => {
        this.headerInformation = res.data;
      });
    },
    getConstituent() {
      axios
        .get("constituent/" + this.constituentId)
        .then(res => {
          this.constituent = res.data;
        })
        .catch();
    },
    toggleActive() {
      let action = "activate";
      if (this.isActive) action = "deactivate";

      axios
        .put(`/constituent/${this.constituent.id}/${action}`)
        .then(() => {
          if (!this.isActive) {
            this.constituent.isActive = true;
            this.$store.commit("notification/showSnackbar", {
              message: this.$t("constituent.messages.isNowActive")
            });
          } else {
            this.constituent.isActive = false;
            this.$store.commit("notification/showSnackbar", {
              message: this.$t("constituent.messages.isNowInactive")
            });
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: this.$t("app.messages.anErrorOccured")
          });
        });
    },
    copyId() {
      let location = window.location.href;
      navigator.clipboard.writeText(location).then(x => {
        this.$store.commit("notification/showSnackbar", {
          message: this.$t("page.constituentLayout.actions.copyId"),
          timeout: 1000
        });
      });
    }
  },
  created() {
    this.getConstituent();
    this.getHeaderInformation();
  }
  /**
   * Update the constituent if it is different from what is in the store
   */
  // beforeRouteUpdate(to, from, next) {
  //   if (to.params.constituentId != this.constituent.id) {
  //     // Promise.all([
  //     //   this.$store.commit("contactInformation/clearContactInformation"),
  //     //   this.$store.dispatch("constituent/loadConstituent", to.params.constituentId)
  //     // ]);//.then(_ => next());
  //     // this.$store.dispatch("address/loadAddresses", {});
  //     // //next();
  //     Promise.all([
  //       this.$store.commit("constituent/clearSelected"),
  //       this.$store.commit("contactInformation/clearContactInformation"),
  //       this.$store.commit("address/clearAddresses"),
  //       this.$store.dispatch("constituent/loadConstituent", to.params.constituentId),
  //     ])
  //       .then((x) => {
  //         this.$store.dispatch("address/loadAddresses", {});
  //         this.$store.dispatch("contactInformation/loadContactInformations", to.params.constituentId);
  //         next();
  //       })
  //       .catch((x) => {
  //         if (process.env.NODE_ENV != "production") console.log("navigation error: ", JSON.stringify(x, null, 2));
  //         next();
  //       });
  //   } else next();
  //},
};
</script>
