import axios from '../../plugins/axios'

export const state = {
    genders: []
};
export const mutations = {
    setGenders(state, genders) {
        if (genders == null)
            genders = []
        state.genders = genders
    }
}
export const actions = {
    loadGenders({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('gender').then(res => {
                let data = res.data
                commit('setGenders', data)
                resolve()
            }).catch(e => reject(e))
        })
    }
}
export const getters = {
    genders: state => state.genders
}