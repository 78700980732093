<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-text-field
          v-model="date"
          :append-icon="icon"
          v-on:click:append="menu = true"
          dense
          outlined
          :clearable="!readonly"
          :disabled="disabled"
          persistent-placeholder
          v-on="on"
          v-bind="attrs"
          no-validate
          :hide-details="hideDetails"
          autocomplete="new-password"
          readonly
          :rules="rules"
        ></v-text-field>
      </slot>
    </template>
    <v-date-picker v-if="!readonly" v-model="date" :no-title="noTitle" :scrollable="scrollable" @change="save">
      <template v-if="!selectOnClick">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
      </template>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    dateFormat: { type: String },
    label: { type: String, required: false, default: "" },
    noIcon: Boolean,
    noTitle: Boolean,
    hideDetails: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    open: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
    selectOnClick: { type: Boolean, default: true },
    scrollable: Boolean,
    valeur: { type: [Date, String], required: false },
  },

  data() {
    return {
      activePicker: "YEAR",
      internalValue: typeof this.valeur == "string" ? this.valeur : false || null,
      menu: this.open || false,
    };
  },

  computed: {
    date: {
      get() {
        var date = this.internalValue || this.valeur;
        if (date == null) {
          return null;
        }

        if (this.dateFormat != null) {
          date = this.getValidDate(date);
        }

        return date;
      },
      set(v) {
        this.internalValue = v;
      },
    },
    icon() {
      return !this.noIcon ? "mdi-calendar" : null;
    },
  },

  watch: {
    internalValue: {
      immediate: true,
      handler() {
        var date = this.internalValue;
        if (this.dateFormat != null) date = this.getValidDate(date);
        this.$emit("input", date);
        this.$emit("change", date);
      },
    },
  },

  methods: {
    save(date) {
      if (this.selectOnClick) this.$refs.menu.save(date);
    },
    getValidDate(date) {
      const validDate = this.$moment(date);
      return validDate.isValid() ? validDate.format(this.dateFormat) : null;
    }
  },
};
</script>
