<template>
  <v-dialog v-model="editDialog" width="1100px" :key="editDialog" content-class="common-config-edit_dialog" persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid class="py-0">
        <v-tabs v-model="tab" background-color color="primary">
          <v-tab>{{$t('component.categoryDetails.details')}}</v-tab>
          <v-tab v-if="isEdit">{{$t('page.categoryConfig.benefits')}}</v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <detail-category-info></detail-category-info>
            </v-tab-item>
            <v-tab-item v-if="isEdit">
              <appeal-benefit-table></appeal-benefit-table>
            </v-tab-item>
          </v-tabs-items>

        </v-tabs>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import DetailCategoryInfo from './DetailCategoryInfo.vue';
import AppealBenefitTable from './BenefitMembershipCategoryTable.vue';


export default {
  components: { DetailCategoryInfo, AppealBenefitTable},
  data: () => ({
    tab: 0,
  }),
  computed: { 
    ...mapGetters({
      isEdit: "category/isEdit",
      editDialog: "category/editDialog",
      nameSelected: "category/nameSelected"
    }),
    title() {
      return this.isEdit ? this.nameSelected : this.$t("component.categoryDetails.add");
    }
  },
  watch: {
    editDialog(v) {
      if (!v) this.$store.commit("category/clearSelected");
      this.$emit("input", v);
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("category/setEditDialog", false);
      this.tab = 0;
    }
  },
};
</script>