export const state = {
    selected: null,
    isEdit: false,
    isDetail: false,
    donationKey: 0,
    editDialog: false,
    matchingGiftkey: 0
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, donationId) {
        state.selected = donationId;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setDetail(state, isDetail) {
        state.isDetail = isDetail;
    },
    setDonationKey(state) {
        state.donationKey = state.donationKey + 1;
    },
    setMatchingGiftkey(state) {
        state.matchingGiftkey = state.matchingGiftkey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    isEdit: state => state.isEdit,
    isDetail: state => state.isDetail,
    donationKey: state => state.donationKey,
    matchingGiftkey: state => state.matchingGiftkey,
    editDialog: state => state.editDialog
}