<template>
  <v-card fluid>
    <v-progress-linear :active="isEdit && loading" indeterminate color="primary"></v-progress-linear>
    <v-card-text class="pb-0">
      <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + event.id">
        <v-row class="common_container">
          <v-col cols="3 pb-0">
            <span class="common_labels">{{ $t("page.eventConfig.name") }}</span>
            <v-text-field
              class="common_txtfield"
              v-model="event.name"
              outlined
              dense
              required
              :rules="[v => !!v || $t('dialog.error.required')]"
              persistent-placeholder
            ></v-text-field>
          </v-col>
          <v-col cols="3 pb-0">
            <span class="common_labels">{{ $t("page.eventConfig.dateFrom") }}</span>
            <calendar-field v-model="event.fromDate" :valeur="event.fromDate" :dateFormat="dateFormat"> </calendar-field>
          </v-col>
          <v-col cols="3 pb-0">
            <span class="common_labels">{{ $t("page.eventConfig.dateTo") }}</span>
            <calendar-field v-model="event.toDate" :valeur="event.toDate" :dateFormat="dateFormat"> </calendar-field>
          </v-col>
          <v-col offset="1" cols="2 pb-0">
            <span class="common_labels">{{ $t("page.eventConfig.active") }}</span>
            <v-checkbox class="common_txtfield" dense color="secondary" v-model="event.isActive" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-container class="px-0 pt-0">
        <v-btn class="float-right mr-2" small color="secondary" @click="save">
          {{ $t("app.actions.save") }}
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import CalendarField from "../Shared/ACalendarField.vue";
import axios from "../../plugins/axios";

export default {
  components: { CalendarField },
  data() {
    return {
      form: null,
      event: {},
      isFromPickerOpen: false,
      isToPickerOpen: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "event/isEdit"
    }),
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    }
  },
  watch: {},
  methods: {
    loadMembershipEvents() {
      this.loading = true;
      const eventId = this.$store.getters["event/selected"];
      axios
        .get(`activity/${eventId}`)
        .then(res => {
          if (res && res.data) {
            this.event = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        let requestCall = null;

        if (!this.event.id) {
          requestCall = axios.post("activity", this.event);
        } else {
          const payload = {
            id: this.event.id,
            name: this.event.name,
            fromDate: this.event.fromDate,
            toDate: this.event.toDate,
            isActive: this.event.isActive,
          }
          requestCall = axios.put(`activity/${this.event.id}`, payload);
        }
        requestCall
          .then(res => {
            this.$store.commit("event/setEventKey");
            this.$store.commit("event/clearSelected");
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.$store.commit("event/setEditDialog", false);
          })
          .catch(exception => {
            const message = exception.response.data ? exception.response.data[0].brokenRuleReason : "";
            this.$store.commit("notification/showSnackbar", {
              message: `Fail: ${message}`
            });
          });
      }
    }
  },
  created() {
    if (this.isEdit) {
      this.loadMembershipEvents();
    } else {
      this.event.isActive = true;
    }
  }
};
</script>
