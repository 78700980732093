<template>
  <v-app @contextmenu="hideContext($event)">
    <router-view></router-view>
    <snackbar-handler></snackbar-handler>
    <dialog-handler></dialog-handler>
    <v-overlay :value="isLoading">
      <breeding-rhombus-spinner :animation-duration="2000" :size="65" color="#E31837" />
    </v-overlay>
  </v-app>
</template>

<script>
import { BreedingRhombusSpinner } from "epic-spinners";
import SnackbarHandler from "./components/Layout/SnackbarHandler";
import DialogHandler from "./components/Layout/DialogHandler.vue";
export default {
  components: { SnackbarHandler, DialogHandler, BreedingRhombusSpinner },
  data() {
    return {};
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    }
  },
  watch: {},
  methods: {
    hideContext(e) {
      if (process.env.NODE_ENV == "production") e.preventDefault();
    }
  }
};
</script>
