<template>
  <v-dialog v-model="editDialog" width="1100px" :key="editDialog" persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid class="py-0">
        <v-tabs v-model="tab" background-color color="primary">
          <v-tab>{{$t('component.campaignDetails.details')}}</v-tab>
          <v-tab v-if="isEdit">{{$t('page.campaignConfig.appeals')}}</v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <detail-campaign-info></detail-campaign-info>
            </v-tab-item>
            <v-tab-item v-if="isEdit">
              <appeal-campaign-table></appeal-campaign-table>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import AppealCampaignTable from './AppealCampaignTable.vue';
import DetailCampaignInfo from './DetailCampaignInfo.vue';

export default {
  components: { DetailCampaignInfo, AppealCampaignTable },
  data: () => ({
    tab: 0,
  }),
  computed: { 
    ...mapGetters({
      isEdit: "campaign/isEdit",
      editDialog: "campaign/editDialog",
      nameSelected: "campaign/nameSelected"
    }),
    title() {
      return this.isEdit ? this.nameSelected : this.$t("component.campaignDetails.add");
    }
  },
  watch: {
    editDialog(v) {
      if (!v) this.$store.commit("campaign/clearSelected");
      this.$emit("input", v);
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("campaign/setEditDialog", false);
      this.tab = 0;
    }
  },
};
</script>