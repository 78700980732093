import axios from '../../plugins/axios'

export const state = {
    notes: [],
    noteCategories: [],
    selected: null,
    noteKey: 0
};

export const mutations = {
    setNotes(state, notes) {
        if (notes == null) {
            notes = []
        }
        state.notes = notes.sort((a, b) => a.createdAt > b.createdAt)
    },
    setNoteCategories(state, noteCategories) {
        if (noteCategories == null) {
            noteCategories = []
        }
        state.noteCategories = noteCategories;
    },
    setNoteKey(state) {
        state.noteKey = state.noteKey + 1;
    },
    setSelected(state, note) {
        state.selected = note;
    }
}

export const actions = {
    loadNotes({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios.get('/note/constituent/' + id).then(res => {
                let notes = res.data;
                commit('setNotes', notes);
                resolve();
            }).catch(e => reject(e));
        })
    },
    loadNote({ commit }, { noteId }) {
        return new Promise((resolve, reject) => {
            axios.get('note/' + noteId).then(res => {
                let note = res.data;
                commit('setSelected', note);
                resolve();
            }).catch(e => reject(e))
        })
    },
    loadNoteCategories({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/note/categories').then(res => {
                let noteCategories = res.data;
                commit('setNoteCategories', noteCategories);
                resolve();
            }).catch(e => reject(e));
        })
    },
    saveNote({ commit }, { id, title, content, createdAt, noteCategoryId, constituentId }) {
        return new Promise((resolve, reject) => {
            let note = { title: title, content: content, createdAt: createdAt, noteCategoryId: noteCategoryId, constituentId: constituentId };
            let req = null;

            if (!id) {
                req = axios.post('/note', note);
            } else {
                note["id"] = id;
                req = axios.put(`note/${id}`, note);
            }

            req.then(res => {
                let note = res.data;
                commit('setSelected', note);
                resolve();
            }).catch(e => { reject(e) });
        })
    }
}

export const getters = {
    notes: state => state.notes,
    noteCategories: state => state.noteCategories,
    selected: state => state.selected,
    noteKey: state => state.noteKey
}