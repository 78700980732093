<template>
  <v-card flat>
    <v-card-text>
      <v-text-field
        class="col-auto"
        v-model="search"
        append-outer-icon="mdi-account-search"
        :label="$t('batch.templates.pickConstituent')"
        v-on:keyup.enter="loadConstituents"
        @click:append-outer="loadConstituents"
        type="text"
      ></v-text-field>
    </v-card-text>
    <v-card-text>
      <v-data-table
        dense
        :loading="loading"
        single-select
        show-select
        checkbox-color="primary"
        item-key="id"
        v-model="selected"
        :headers="headers"
        :items="listConstituents"
      ></v-data-table>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-container class="px-0 pt-0">
        <v-btn :disabled="selected.length == 0" class="float-right mr-2" small color="secondary" @click="save">
          {{ $t("app.actions.next") }}
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "../../plugins/axios";

export default {
  components: {},
  data() {
    return {
      search: null,
      loading: false,
      selected: [],
      listConstituents: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          class: "secondary--text  font-weight-bold subtitle-2",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "secondary--text  font-weight-bold subtitle-2",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
          class: "secondary--text  font-weight-bold subtitle-2",
        },
        {
          text: "Country",
          align: "start",
          sortable: false,
          value: "country",
          class: "secondary--text  font-weight-bold subtitle-2",
        },
      ];
    },
  },
  watch: {},
  methods: {
    loadConstituents() {
      this.loading = true;
      axios
        .post("Constituent/Search", {
          country: null,
          name: this.search,
        })
        .then((res) => {
          if (res && res.data) {
            this.listConstituents = res.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      if (this.selected[0]) {
        this.$emit("pickConstituent", this.selected[0]);
      }
    },
  },
  created() {},
};
</script>
