import axios from "../../plugins/axios";
import i18n from "../../i18n";
//import fakeData from '../../data/constituents'
export const state = {
  //start
  constituentId: -1,
  constituentType: "",
  constituentContactInfoRefreshKey: 0,
  //end

  //constituents: [...fakeData],
  // constituents: [],
  constituentsSearch: [],
  constituentTypes: [i18n.t("app.common.constituentTypes.individual"), i18n.t("app.common.constituentTypes.organization")],
  currentPage: 1,
  itemsPerPage: 50,
  organizationTypes: [],
  donationTypes: [],
  saveDonationRes: null,
  donationDeactivateRes: null,
  selected: null, //c'est le constituent
  selectedDonations: null,
  donationRefreshKey: 0,
  membershipRefreshKey: 0,
  sortBy: "name",
  sortDesc: false,
  sortTimeout: false,
  totalItems: 0,
  constituentSaveRes: null
};

export const mutations = {
  setConstituentid(state, constituentId) {
    state.constituentId = constituentId;
  },
  setConstituentType(state, constituentType) {
    state.constituentType = constituentType;
  },
  setConstituentContactInfoRefreshKey(state) {
    state.constituentContactInfoRefreshKey = state.constituentContactInfoRefreshKey + 1;
  },
  setDonationRefreshKey(state) {
    state.donationRefreshKey = state.donationRefreshKey + 1;
  },
  setMembershipRefreshKey(state) {
    state.membershipRefreshKey = state.membershipRefreshKey + 1;
  },

  // setConstituents(state, constituents) {
  //   if (constituents == null) {
  //     constituents = [];
  //   }
  //   state.constituents = constituents;
  // },
  setConstituentsSearch(state, constituents) {
    if (constituents == null) {
      constituents = [];
    }
    state.constituentsSearch = constituents;
  },
  setCurrentPage(state, direction) {
    if (direction == "up") state.currentPage = state.currentPage + 1;
    else state.currentPage = state.currentPage - 1;
  },
  clearConstituents(state) {
    state.constituents = [];
  },
  setItemsPerPage(state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage;
  },
  setOrganizationTypes(state, types) {
    if (types == null) {
      types = [];
    }
    state.organizationTypes = types;
  },
  setDonationTypes(state, types) {
    if (types == null) {
      types = [];
    }
    state.donationTypes = types;
  },
  saveDonation_result(state, res) {
    state.saveDonationRes = res;
  },
  deactivateDonation_result(state, res) {
    state.donationDeactivateRes = res;
  },
  setSelected(state, constituent) {
    state.selected = constituent;
  },
  setDonations(state, donations) {
    state.selected.donations = donations;
  },
  setConstituentDonations(state, donations) {
    state.selectedDonations = donations;
  },
  setSortBy(state, sortBy) {
    state.sortBy = sortBy;
  },
  setSortDesc(state, sortDesc) {
    state.sortDesc = sortDesc;
  },
  setTotalItems(state, totalItems) {
    state.totalItems = totalItems;
  },
  clearSelected(state) {
    state.selected = null;
  },
  setConstituentSaveRes(state, payload) {
    state.constituentSaveRes = payload;
  }
};

export const actions = {
  //start
  setConstituentId({ commit }, constituentId) {
    commit("setConstituentid", constituentId);
  },

  setConstituentType({ commit }, constituentId) {
    axios
      .get(`/constituent/getconstituenttype/${constituentId}`)
      .then(res => {
        let type = res.data;
        commit("setConstituentType", type);
      })
      .catch();
  },

  //end

  setConstituentSelected({ commit }) {
    new Promise((resolve, reject) => {
      commit("setSelected", {});
      resolve();
    });
  },
  setDonations({ commit }, payload) {
    new Promise((resolve, reject) => {
      commit("setConstituentDonations", payload);
      resolve();
    });
  },

  // loadConstituents({
  //   commit
  // }) {
  //   new Promise((resolve, reject) => {
  //     axios
  //       .get("/constituent")
  //       .then(res => {
  //         let constituents = res.data;
  //         commit("setConstituents", constituents);
  //         resolve();
  //       })
  //       .catch(e => reject(e));
  //   });
  // },
  // loadConstituentsByPage({
  //   commit,
  //   state
  // }) {
  //   if (state.sortTimeout) clearTimeout();

  //   state.sortTimeout = setTimeout(() => {
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get("constituent", {
  //           params: {
  //             itemsPerPage: state.itemsPerPage,
  //             currentPage: state.currentPage,
  //             sortBy: state.sortBy,
  //             sortDesc: state.sortDesc
  //           }
  //         })
  //         .then(res => {
  //           let constituents = res.data.data;
  //           let totalItems = res.data.pagination.totalItems;
  //           commit("setConstituents", constituents);
  //           commit("setTotalItems", totalItems);
  //           resolve();
  //         })
  //         .catch(e => reject(e));
  //     });
  //   }, 1000);
  // },

  // loadConstituent({
  //   commit
  // }, id) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get("constituent/" + id)
  //       .then(res => {
  //         let constituent = res.data;
  //         commit("setSelected", constituent);
  //         resolve();
  //       })
  //       .catch(e => reject(e));
  //   });
  // },
  loadOrganizationTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("organization/types")
        .then(res => {
          let types = res.data;
          commit("setOrganizationTypes", types);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  // loadConstituentDonations({ commit }, constituentId) {
  //   new Promise((resolve, reject) => {
  //     axios
  //       .get("/donation/constituent/" + constituentId)
  //       .then(res => {
  //         let donations = res.data;
  //         commit("setConstituentDonations", donations);
  //         resolve();
  //       })
  //       .catch(e => reject(e));
  //   });
  // },
  loadDonationTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("donation/types")
        .then(res => {
          let types = res.data;
          commit("setDonationTypes", types);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  saveDonation({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      let req;
      if (payload.id) {
        //Marche pas... aucun API existe pour edit................................................................
        req = axios.put(`/donation/${payload.id}`, payload);
      } else {
        req = axios.post(`/donation`, payload);
      }
      req
        .then(res => {
          commit("saveDonation_result", res.data);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  saveConstituent({ dispatch, getters }) {
    let constituent = getters["selected"];
    if (constituent.constituentType == "Individual") {
      return dispatch("saveIndividual", constituent);
    } else {
      return dispatch("saveOrganization", {
        ...constituent
      });
    }
  },
  saveIndividual({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let constituent = payload;
      let req = null;
      // If no id is given or id == 0 then we create the individual
      if (constituent.id == null || !constituent.id || constituent.id == 0) {
        req = axios.post("/individual", constituent);
      } else {
        req = axios.put(`/individual/${constituent.id}`, constituent);
      }

      req
        .then(res => {
          let individual = res.data;
          commit("setConstituentSaveRes", res);
          commit("setSelected", individual);
          resolve();
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  saveOrganization({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let constituent = payload;
      let req = null;
      if (constituent.id) {
        req = axios.put(`/organization/${constituent.id}`, constituent);
      } else {
        req = axios.post("organization", constituent);
      }

      req
        .then(res => {
          let organization = res.data;
          commit("setSelected", organization);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  // search({ commit }, { firstName, name }) {
  //   return new Promise((resolve, reject) => {
  //     var obj = {
  //       firstName: firstName,
  //       name: name
  //     };
  //     return axios
  //       .post("constituent/search", obj)
  //       .then(res => {
  //         var data = res.data;
  //         commit("setConstituentsSearch", data);
  //         return resolve();
  //       })
  //       .catch(e => reject(e));
  //   });
  // },
  deactivateDonation({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`donation/${id}/decline`)
        .then(res => {
          commit("deactivateDonation_result", res);
          return resolve();
        })
        .catch(e => reject(e));
    });
  },
  setConstituentActiveStatus({ commit, getters }, { constituentId, newStatus }) {
    return new Promise((resolve, reject) => {
      if (constituentId) {
        axios
          .put(`/constituent/${constituentId}/${newStatus ? "activate" : "deactivate"}`)
          .then(res => {
            let constituent = getters["selected"];
            constituent.isActive = newStatus;
            commit("setSelected", constituent);
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      } else reject();
    });
  }
};

export const getters = {
  //start
  constituentId: state => state.constituentId,
  constituentType: state => state.constituentType,
  constituentContactInfoRefreshKey: state => state.constituentContactInfoRefreshKey,
  //end

  constituents: state => state.constituents,
  currentPage: state => state.currentPage,
  id: (state, getters) => getters["selected"].id,
  itemsPerPage: state => state.itemsPerPage,
  organizationTypes: state => state.organizationTypes,
  selected: state => state.selected,
  sortBy: state => state.sortBy,
  sortDesc: state => state.sortDesc,
  totalItems: state => state.totalItems,
  types: state => state.constituentTypes,
  getConstituentSaveRes: state => state.getConstituentSaveRes,
  constituentsSearch: state => {
    const constituents = state.constituentsSearch;

    return constituents.map(x => ({
      ...x,
      id: x.constituentId
    }));
  },
  getConstituentDonations: state => state.selectedDonations,
  getDonationTypes: state => state.donationTypes,
  getSaveDonationRes: state => state.saveDonationRes,
  getDonationRefreshKey: state => state.donationRefreshKey,
  getDonationDeactivateRes: state => state.donationDeactivateRes,
  getMembershipRefreshKey: state => state.membershipRefreshKey
};
