

export const notEmpty = (text) => (v) => {
    return !!v || text;
};

export const matches = (text, regex) => (v) => {
    if (!v)
        return true;

    return new RegExp(regex).test(v) || text;

};