import axios from "axios";
import store from "../store";
import router from "@/router";

const instance = axios.create();

instance.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

// Request interceptor
instance.interceptors.request.use(request => {
  const token = store.getters["auth/token"];
  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`;
  }

  return request;
});

// instance.interceptors.request.use((config) => {
//     store.commit('setLoadingState', true)
//     return config;
// }, (error) => {
//     store.commit('setLoadingState', false)
//     return Promise.reject(error);
// });

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (["401", 401].includes(error.response.status)) {
      if (router.currentRoute.path !== '/login/' && router.currentRoute.path !== '/login') {
        store.dispatch('auth/logout')
      }
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    store.commit("setLoadingState", false);
    return response;
  },
  error => {
    store.commit("setLoadingState", false);
    return Promise.reject(error);
  }
);

export default instance;
