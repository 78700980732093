import store from '../../store'

export function auth({ to, next, router }) {
    var requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    if (!requiresAuth) {
        return next()
    }
    else {
        if (!store.getters['auth/isAuthenticated'] || store.getters['auth/isTokenExpired']) {
            store.dispatch('auth/logout')
        }
        return next();
    }
}

export function isAdmin({ to, next, router }) {
    var requiresAuth = to.matched.some(x => x.meta.requiresAuth);
    if (!requiresAuth) {
        return next();
    }
    else {
        const store1 = store.getters['auth/role'];
        if (store.getters['auth/role'] === 'Admin') {
            return next();
        } else {
            next({ name: 'Home' })
        }
    }
}