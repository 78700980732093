<template>
  <v-card fluid>
    <v-progress-linear :active="isEdit && loading" indeterminate color="primary"></v-progress-linear>
    <v-card-text class="pb-0">
      <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + code.id">
        <v-row class="common_container">
          <v-col cols="8 pb-0">
            <span class="common_labels">{{ $t("page.codeConfig.name") }}</span>
            <v-text-field
              class="common_txtfield"
              v-model="code.name"
              outlined
              dense
              required
              :rules="[v => !!v || $t('dialog.error.required')]"
              persistent-placeholder
            ></v-text-field>
          </v-col>
          <v-col offset="1" cols="3 pb-0">
            <span class="common_labels">{{ $t("page.codeConfig.active") }}</span>
            <v-checkbox class="common_txtfield" dense color="secondary" v-model="code.isActive" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-container class="px-0 pt-0">
        <v-btn class="float-right mr-2" small color="secondary" @click="save">
          {{ $t("app.actions.save") }}
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "../../plugins/axios";

export default {
  data() {
    return {
      form: null,
      code: {},
      isFromPickerOpen: false,
      isToPickerOpen: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "code/isEdit"
    }),
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    }
  },
  watch: {},
  methods: {
    loadConstituentCodes() {
      this.loading = true;
      const codeId = this.$store.getters["code/selected"];
      axios
        .get(`constituentcode/${codeId}`)
        .then(res => {
          if (res && res.data) {
            this.code = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        let requestCall = null;

        if (!this.code.id) {
          requestCall = axios.post("/constituentcode", this.code);
        } else {
          requestCall = axios.put(`constituentcode/${this.code.id}`, this.code);
        }
        requestCall
          .then(res => {
            this.$store.commit("code/setCodeKey");
            this.$store.commit("code/clearSelected");
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.$store.commit("code/setEditDialog", false);
          })
          .catch(exception => {
            const message = exception.response.data ? exception.response.data[0].brokenRuleReason : "";
            this.$store.commit("notification/showSnackbar", {
              message: `Fail: ${message}`
            });
          });
      }
    }
  },
  created() {
    if (this.isEdit) {
      this.loadConstituentCodes();
    } else {
      this.code.isActive = true;
    }
  }
};
</script>
