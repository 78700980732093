
<template>
  <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout" multi-line tile>
    <v-row v-if="hasTitle">
      <v-col cols="12">
        <h3>{{snackbarTitle}}</h3>
      </v-col>
      <v-col cols="12">{{ snackbarText }}</v-col>
    </v-row>
    <span v-else>{{ snackbarText }}</span>

    <template v-slot:action="{ attrs }">
      <v-btn :color="snackbarCloseButtonColor" text v-bind="attrs" @click="showSnackbar = false">{{ $t("app.actions.close") }}</v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
    name: "app-snackbar",
    //   data() {return ({
    //       snackbar
    //   })},
    computed: {
        hasTitle() {
            return this.snackbarTitle != null && typeof this.snackbarTitle == "string" && this.snackbarTitle.trim() != "";
        },
        isSnackbarClosable() {
            return this.snackbar.closable;
        },
        showSnackbar: {
            get() {
                return this.snackbar.message != null;
            },
            set(val) {
                this.$store.commit("notification/clearSnackbar");
            },
        },
        snackbar() {
            return this.$store.getters["notification/snackbar"];
        },
        snackbarText() {
            return this.snackbar.message;
        },
        snackbarTimeout() {
            return this.snackbar.timeout;
        },
        snackbarTitle() {
            return this.snackbar.title;
        },
        snackbarCloseButtonColor() {
            return this.snackbar.color;
        },
    },
};
</script>
