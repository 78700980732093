import axios from '../../plugins/axios'

export const state = {
    communicationLanguages: [],
    countries: [],
    countryStates: [],
    emailTypes: [],
    genders: [],
    nameTitles: [],
    nameSuffixes: [],
    phoneTypes: [],
    professionTypes: [],
    isCommunicationLanguagesLoaded: false,
    isCountriesLoaded: false,
    isCountryStatesLoaded: false,
    isEmailTypesLoaded: false,
    isGendersLoaded: false,
    isNameTitlesLoaded: false,
    isNameSuffixesLoaded: false,
    isPhoneTypesLoaded: false,
    isProfessionTypesLoaded: false,
};
export const mutations = {
    setCommunicationLanguages(state, types) {
        if (types == null) {
            types = []
        }

        state.communicationLanguages = types
        if (types.length)
            state.isCommunicationLanguagesLoaded = true
    },
    setCountries(state, types) {
        if (types == null) {
            types = []
        }

        state.countries = types
        if (types.length)
            state.isCountriesLoaded = true
    },
    setCountryStates(state, types) {
        if (types == null) {
            types = []
        }

        state.countryStates = types
        if (types.length)
            state.isCountryStatesLoaded = true
    },
    setEmailTypes(state, types) {
        if (types == null) {
            types = []
        }

        state.emailTypes = types
        if (types.length)
            state.isEmailTypesLoaded = true
    },
    setGenders(state, types) {
        if (types == null) {
            types = []
        }

        state.genders = types
        if (types.length)
            state.isGendersLoaded = true
    },
    setNameTitles(state, types) {
        if (types == null) {
            types = []
        }
        state.nameTitles = types
        if (types.length)
            state.isNameTitlesLoaded = true
    },
    setNameSuffixes(state, types) {
        if (types == null) {
            types = []
        }
        state.nameSuffixes = types
        if (types.length)
            state.isNameSuffixesLoaded = true
    },
    setPhoneTypes(state, types) {
        if (types == null) {
            types = []
        }

        state.phoneTypes = types
        if (types.length)
            state.isPhoneTypesLoaded = true
    },

    setProfessionTypes(state, types) {
        if (types == null) {
            types = []
        }

        state.professionTypes = types
        if (types.length)
            state.isProfessionTypesLoaded = true
    }
}
export const actions = {
    loadCommunicationLanguages({ commit, state }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (!state.isCommunicationLanguagesLoaded || forceLoad) {
                axios.get('/language').then(res => {
                    let types = res.data
                    commit('setCommunicationLanguages', types)
                    resolve()
                }).catch(e => reject(e))
            }
            else {
                resolve();
            }
        })
    },
    loadCountries({ commit, state }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (!state.isCountriesLoaded || forceLoad) {
                axios.get('/country').then(res => {
                    let types = res.data
                    commit('setCountries', types)
                    resolve()
                }).catch(e => reject(e))
            }
            else {
                resolve();
            }
        })
    },
    loadCountryStates({ commit, state }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            // if (!state.isCountryStatesLoaded || forceLoad) {
            //     axios.get('/language').then(res => {
            //         let types = res.data
            //         commit('setCountryStates', types)
            //     }).catch(e => reject(e))
            // }
            // else {
            resolve();
            // }
        })
    },
    loadEmailTypes({ commit, state }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (!state.isEmailTypesLoaded || forceLoad) {
                axios.get('/email/types').then(res => {
                    let types = res.data
                    commit('setEmailTypes', types)
                    resolve()
                }).catch(e => reject(e))
            }
            else {
                resolve();
            }
        })
    },
    loadGenders({ commit, state }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (!state.isGendersLoaded || forceLoad) {
                axios.get('/gender').then(res => {
                    let types = res.data
                    commit('setGenders', types)
                }).catch(e => reject(e))
            }
            else {
                resolve();
            }
        })
    },
    loadNameTitles({ commit, state }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (!state.isNameTitlesLoaded || forceLoad) {
                axios.get('/NameTitle').then(res => {
                    let types = res.data
                    commit('setNameTitles', types)
                    resolve();
                }).catch(e => reject(e))
            }
            else {
                resolve();
            }
        })
    },
    loadNameSuffixes({ commit, state }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (!state.isNameSuffixesLoaded || forceLoad) {
                axios.get('/NameSuffix').then(res => {
                    let types = res.data
                    commit('setNameSuffixes', types)
                    resolve()
                }).catch(e => reject(e))
            }
            else {
                resolve();
            }
        })
    },
    loadPhoneTypes({ commit, state }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (!state.isPhoneTypesLoaded || forceLoad) {
                axios.get('/PhoneNumber/types').then(res => {
                    let types = res.data
                    commit('setPhoneTypes', types)
                    resolve()
                }).catch(e => reject(e))
            }
            else {
                resolve();
            }
        })
    },
    loadProfessionTypes({ commit, state }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (!state.isProfessionTypesLoaded || forceLoad) {
                axios.get('/profession').then(res => {
                    let types = res.data
                    commit('setProfessionTypes', types)
                    resolve()
                }).catch(e => reject(e))
            }
            else {
                resolve();
            }
        })
    }
}
export const getters = {
    communicationLanguages: state => state.communicationLanguages,
    countries: state => state.countries,
    countryStates: state => state.countryStates,
    emailTypes: state => state.emailTypes,
    genders: state => state.genders,
    nameTitles: state => state.nameTitles,
    nameSuffixes: state => state.nameSuffixes,
    phoneTypes: state => state.phoneTypes,
    professionTypes: state => state.professionTypes
}