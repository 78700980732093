<template>
  <v-card fluid>
    <v-progress-linear :active="isEdit && loading" indeterminate color="primary"></v-progress-linear>
    <v-card-text class="pb-0">
      <v-form ref="form" v-model="form" class="common-details_container" :key="'container' + program.id">
        <v-row class="common_container">
          <v-col cols="4 pb-0">
            <span class="common_labels">{{ $t("page.programConfig.name") }}</span>
            <v-text-field
              class="common_txtfield"
              v-model="program.name"
              outlined
              dense
              required
              :rules="[v => !!v || $t('dialog.error.required')]"
              persistent-placeholder
            ></v-text-field>
          </v-col>
          <v-col cols="2 pb-0">
            <span class="common_labels">{{ $t("page.programConfig.active") }}</span>
            <v-checkbox class="common_txtfield" dense color="secondary" v-model="program.isActive" />
          </v-col>
          <!--          <v-col cols="12 pb-0">-->
          <!--            <span class="pack-details_labels">{{ $t('page.programConfig.description') }}</span>-->
          <!--            <v-text-field-->
          <!--              class="common-details_txtfield"-->
          <!--              v-model="program.description"-->
          <!--              outlined-->
          <!--              dense-->
          <!--              required-->
          <!--              :rules="[]"-->
          <!--              persistent-placeholder-->
          <!--            ></v-text-field>-->
          <!--          </v-col>-->
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-container class="px-0 pt-0">
        <v-btn class="float-right mr-2" small color="secondary" @click="save">
          {{ $t("app.actions.save") }}
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "../../plugins/axios";

export default {
  data() {
    return {
      form: null,
      program: {},
      isFromPickerOpen: false,
      isToPickerOpen: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "program/isEdit"
    }),
    dateFormat() {
      return this.$store.getters.generalDateFormat;
    }
  },
  watch: {},
  methods: {
    loadMembershipPrograms() {
      this.loading = true;
      const programId = this.$store.getters["program/selected"];
      axios
        .get(`MembershipProgram/${programId}`)
        .then(res => {
          if (res && res.data) {
            this.program = res.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
        });
    },
    formatAmount() {
      if (this.program.overall != undefined && this.program.overall != null) this.program.overall = parseFloat(this.program.overall).toFixed(2);
    },
    save() {
      if (this.$refs.form.validate()) {
        let requestCall = null;

        if (!this.program.id) {
          requestCall = axios.post("/MembershipProgram", this.program);
        } else {
          requestCall = axios.put(`MembershipProgram/${this.program.id}`, this.program);
        }
        requestCall
          .then(res => {
            this.$store.commit("program/setProgramKey");
            this.$store.commit("program/clearSelected");
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.$store.commit("program/setEditDialog", false);
          })
          .catch(exception => {
            const message = exception.response.data ? exception.response.data[0].brokenRuleReason : "";
            this.$store.commit("notification/showSnackbar", {
              message: `Fail: ${message}`
            });
          });
      }
    }
  },
  created() {
    if (this.isEdit) {
      this.loadMembershipPrograms();
    } else {
      this.program.isActive = true;
    }
  }
};
</script>
