export const state = {
    selected: null,
    nameSelected: null,
    isEdit: false,
    programKey: 0,
    editDialog: false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, programId) {
        state.selected = programId;
    },
    setNameSelected(state, programName) {
        state.nameSelected = programName;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setProgramKey(state) {
        state.programKey = state.programKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    nameSelected: state => state.nameSelected,
    isEdit: state => state.isEdit,
    programKey: state => state.programKey,
    editDialog: state => state.editDialog
}