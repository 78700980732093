
export const state = {
    communicationsRefreshKey: 0,
    attachmentsRefreshKey: 0,
    attachmentSelected: null,
};

export const mutations = {
    communicationsRefreshKey(state) {
        state.communicationsRefreshKey = state.communicationsRefreshKey + 1;
    },    
    clearAttachmentSelected(state) {
        state.attachmentSelected = null;
    },    
    setAttachmentSelected(state, item) {
        state.attachmentSelected = item;
    },
    attachmentsRefreshKey(state) {
        state.attachmentsRefreshKey  = state.attachmentsRefreshKey + 1;
    }, 
}
export const actions = {
}

export const getters = {
    communicationsRefreshKey: state => state.communicationsRefreshKey,
    attachmentsRefreshKey: state => state.attachmentsRefreshKey,
    attachmentSelected: state => state.attachmentSelected,
}