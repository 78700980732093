<template>
  <v-app-bar color="primary" tile app elevation="0" clipped-left fluid class="appbar">
    <div class="appbar_left-logo">
      <v-img class="mx-2 whiten-icon" src="../../assets/img/WFH_Logo_big.png" max-height="50" max-width="95" contain></v-img>
    </div>
    <v-toolbar-items class="appbar_items">
      <v-btn large text exact :to="{ name: 'Home' }" active-class="appbar_btn-active">
        <span class="appbar_btn-content">{{ $t("appbar.links.home") }}</span>
      </v-btn>
      <v-btn large text exact :to="{ name: 'ConstituentList' }">
        <span class="appbar_btn-content">{{ $t("appbar.links.constituents") }}</span>
      </v-btn>
      <!-- <v-btn large text inactive disabled><span class="appbar_btn-content_disabled">Memberships</span></v-btn>
      <v-btn large text inactive disabled><span class="appbar_btn-content_disabled">Donations</span></v-btn> -->
<!--      <v-btn large text inactive :to="{ name: 'ReportBuilder' }"><span class="appbar_btn-content">{{ $t("appbar.links.reports") }}</span></v-btn>-->
      <v-menu
          open-on-hover
          close-on-click
          transition="slide-y-transition"
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text inactive
              v-bind="attrs"
              v-on="on"
          >
            <span  class="appbar_btn-content">{{  $t("appbar.links.reports") }}</span>
          </v-btn>
        </template>

        <v-list color="white" >
          <v-list-item v-for="report in reportMenus" :key="report.key" link :to="{ name: report.link }" >
            <v-list-item-title class="">
              {{ report.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>



      <v-btn v-if="isAdmin" large text exact :to="{ name: 'UserList' }">
        <span class="appbar_btn-content">{{ $t("appbar.links.users") }}</span>
      </v-btn>

      <v-menu
          open-on-hover
          :close-on-content-click="false"
          transition="slide-y-transition"
          bottom
          :nudge-width="150"
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              large text inactive
              v-bind="attrs"
              v-on="on"
          >
            <span class="appbar_btn-content">{{ $t("appbar.links.batch") }}</span>
          </v-btn>
        </template>
        <v-list expand>
          <v-list-group
              v-for="(menu) in batchMenus"
              :key="menu.key"
              no-action
          >
            <template v-slot:activator>
              <v-list-item-title >
                <v-list-item-content :name="menu.name" v-text="menu.name"></v-list-item-content>
              </v-list-item-title>
            </template>

            <v-list-item
                link
                :to="{ name: submenu.link }"
                style="padding: 0px"
                v-for="(submenu) in menu.children"
                :key="submenu.key"
            >
              <v-list-item-content class="px-6">
                <v-list-item-title  v-text="submenu.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu>


      <!-- <v-btn text exact>{{ $t("appbar.links.organizations") }}</v-btn> -->
    </v-toolbar-items>
    <!-- <v-spacer></v-spacer> -->
    <div class="appbar_right-menu">
      <!-- <global-search-dialog @input="onSelectConstituent" :btnProps="{ dark: true, icon: true }"></global-search-dialog> -->
      <!-- <v-btn icon @click="showGlobalSearch">
      <v-icon>mdi-binoculars</v-icon>
    </v-btn>-->
      <v-btn icon>
        <v-icon color="white">mdi-bell-outline</v-icon>
        <!-- <svg width="36" height="36">{{ notificationbell }}</svg> -->
      </v-btn>
      <v-btn v-if="isAdmin" icon :to="{ name: 'configCampaing' }">
        <v-icon color="white">mdi-cog-outline</v-icon>
      </v-btn>

      <v-menu left offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar color="primarydark" size="36">
              <span class="appbar_userInitial ">{{ userInitials }}</span>
            </v-avatar>
            <!-- <v-icon>mdi-account-circle</v-icon> -->
          </v-btn>
        </template>
        <!-- <appbar-profile></appbar-profile> -->
        <profile-popup-card :userName="userName" :userInitials="userInitials"></profile-popup-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>
<script>
import ProfilePopupCard from "./ProfilePopupCard.vue";
//import GlobalSearchDialog from "../Dialogs/GlobalSearchDialog.vue";
import { mensearch } from "../../assets/icons/menu/icon-search.svg";
import { notificationbell } from "../../assets/icons/menu/icon-notifications.svg";
import { setting } from "../../assets/icons/menu/icon-settings.svg";
import axios from "../../plugins/axios";
export default {
  components: { ProfilePopupCard },
  //components: { GlobalSearchDialog, ProfilePopupCard },
  data() {
    return {
      icons: {
        mensearch,
        notificationbell,
        setting
      },
      batchMenus:[
        {
          key:1,
          name: this.$t("appbar.links.new_batch"),
          children: [
            {
              key:4,
              name: this.$t("batch.templates.donations"),
              link: 'donations'
            },{
            key:3,
            name: this.$t("batch.templates.membership"),
              link: 'membership'
          }]
        },
        {
          key:2,
          name: this.$t("appbar.links.update_batch"),
          children: [{
            key:5,
            name: this.$t("batch.templates.financialRec"),
            link: 'importFinancialRec'
          }]
        }
      ],
      reportMenus:[
        {
          key:1,
          link:'financialRec',
          name: this.$t("batch.templates.financialRec"),
          children: []
        },
        {
          key:2,
          link:'builder',
          name: this.$t("batch.templates.builder"),
          children: []
        }
      ],
      isAdmin: false,
      userName: null,
      userInitials: null,
      reportTitle: this.$t("appbar.links.reports")
    };
  },
  computed: {
    userInitial() {
      return this.$store.getters["profile/initial"];
    },
    userId() {
      return this.$store.getters["auth/userId"]
    }
  },
  methods: {
    clickElement(idNodeElement){
      const element = document.getElementsByName(idNodeElement)[0]
      element.click()
    },
    onSelectConstituent(e) {
      this.$router.push({
        name: "ConstituentDetails",
        params: { constituentId: e.constituentId }
      });
    },
    changeReportTitle(){
      this.reportTitle = this.$t("batch.templates.financialRec")
    },
    refreshReportTitle(){
      this.reportTitle = this.$t("appbar.links.reports")
    },
    showGlobalSearch() {
      this.$store.dispatch("notification/showGlobalSearch");
    }
  },
  created() {
    const role = this.$store.getters['auth/role'];
    if (role === "Admin") {
      this.isAdmin = true;
    }
    const userId = this.$store.getters["auth/userId"];
    axios
      .get("user/" + userId)
      .then(res => {
        const user = res.data;
        this.userName = `${user.firstName} ${user.lastName}`;
        this.userInitials = user.firstName[0] + user.lastName[0]
      })
      .catch();
  }
};
</script>
