<template>
  <v-sheet class="d-flex justify-center align-center" style="background-color:rgb(247 248 249); justify-content: center;">
    <v-form ref="formBatch" v-model="valid">
      <v-card class="mt-2" elevation="7" style="width: 900px">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="secondary--text bold ">
              <h3>
                {{ $t('reports.forms.pleaseSelect') }}
              </h3>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input
                    dense
                    v-model="file"
                    :rules="[v => !!v || $t('dialog.error.required')]"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    label="File"
                ></v-file-input>
              </v-col>
              <v-col class="col-2 d-flex justify-end">
                <v-btn :loading="loading" dense color="secondary" @click="importData">{{ $t('reports.forms.upload') }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

      </v-card>
    </v-form>
  </v-sheet>
</template>
<script>
import axios from "../../plugins/axios";

export default {
  data() {
    return {
      file: null,
      valid: false,
      loading:false
    };
  },
  computed: {
    title() {
      return this.$t('reports.financialRec')
    }
  },
  methods: {
    validate() {
      return this.$refs.formBatch.validate()
    },
    importData() {
      this.validate()
      if (this.valid) {
        this.loading=true
        const data = new FormData()
        data.append('data', this.file)
        axios.post(`transaction/import`, data)
            .then(res => {
              this.loading = false
              this.$store.commit("notification/showSnackbar", {
                message: "Success"
              });
              this.file = null
            })
            .catch(err => {
              console.log(err.response)
              this.loading = false
              this.$store.commit("notification/showSnackbar", {
                message: `Failure: ${err.response.data.message}`
              });
            })
      }

    },
  }
};
</script>