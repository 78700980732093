<template>
  <v-dialog v-model="showFind" width="1100px"  persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container  class="py-0">
        <table-constituents-find-info @pickConstituent="changeConstituent"/>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import TableConstituentsFindInfo from "../../BatchTemplates/TableConstituentsFindInfo";
export default {
  components: { TableConstituentsFindInfo },
  props: {
    showFind: Boolean
  },
  data: () => ({}),
  computed: {
    title() {
      return this.$t("batch.donations.findConstituent")
    },
  },
  watch: {
  },
  methods: {
    closeDialog() {
      this.$emit('showFind')
    },
    changeConstituent(item){
      this.$emit('updateConstituent', item)
    }

  }
};
</script>