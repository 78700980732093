export const state = {
    selected: null,
    nameSelected: null,
    isEdit: false,
    eventKey: 0,
    editDialog: false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, eventId) {
        state.selected = eventId;
    },
    setNameSelected(state, eventName) {
        state.nameSelected = eventName;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setEventKey(state) {
        state.eventKey = state.eventKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    nameSelected: state => state.nameSelected,
    isEdit: state => state.isEdit,
    eventKey: state => state.eventKey,
    editDialog: state => state.editDialog
}