export const state = {
    selected: null,
    nameSelected: null,
    isEdit: false,
    categoryKey: 0,
    benefitKey:0,
    categoryBenefitSelected: null,
    editDialog: false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    clearCategoryBenefitSelected(state) {
        state.categoryBenefitSelected = null;
    },
    setSelected(state, categoryId) {
        state.selected = categoryId;
    },
    setCategoryBenefitSelected(state, item) {
        state.categoryBenefitSelected = item;
    },
    setNameSelected(state, categoryName) {
        state.nameSelected = categoryName;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setCategoryKey(state) {
        state.categoryKey = state.categoryKey + 1;
    },
    setBenefitKey(state) {
        state.benefitKey = state.benefitKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    categoryBenefitSelected: state => state.categoryBenefitSelected,
    nameSelected: state => state.nameSelected,
    isEdit: state => state.isEdit,
    categoryKey: state => state.categoryKey,
    benefitKey: state => state.benefitKey,
    editDialog: state => state.editDialog
}