import axios from '../../plugins/axios'
import router from '../../router/index'

export const state = {
    jwt: null,
    role: null,
    userId: null
};

export const mutations = {
    clearUser(state) {
        localStorage.clear();
        state = { jwt: null, role: null };
    },
    setJwt(state, jwt) {
        state.jwt = jwt;
        localStorage.setItem('token', jwt);
    },
    setRole(state, role) {
        state.role = role;
    },
    setUserId(state, userId) {
        state.userId = userId;
    }
};

export const actions = {
    init({ dispatch, commit, getters }) {
        return new Promise((resolve, reject) => {
            if (getters['token'] == null) {
                let token = localStorage.getItem('token');
                if (token) {
                    commit('setJwt', token);
                    const tokenBody = getters['tokenBody'];
                    if (tokenBody) {
                        const jwt = JSON.parse(window.atob(tokenBody));
                        const role = jwt['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
                        const userId = jwt.nameid;
                        commit('setRole', role);
                        commit('setUserId', userId);
                    }
                }
            }
            resolve();
        })
    },
    authenticate({ commit, getters }, { email, password }) {
        return new Promise((resolve, reject) => {
            const params = new URLSearchParams()
            params.append('email', email)
            params.append('password', password)

            const config = {
                headers: {
                    //     'Content-Type': 'application/x-www-form-urlencoded'
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }
            axios.post('/auth/login', params, config)
                .then((res) => {
                    let token = res.data.token;
                    commit('setJwt', token);
                    const tokenBody = getters['tokenBody'];
                    if (tokenBody) {
                        const jwt = JSON.parse(window.atob(tokenBody));
                        const role = jwt['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
                        const userId = jwt.nameid;
                        commit('setRole', role);
                        commit('setUserId', userId);
                    }
                    resolve();
                })
                .catch(e => {
                    reject();
                });
        });
    },
    logout({ commit }) {
        commit('clearUser');
        router.push({ name: 'Login' });
    },
};

export const getters = {
    token: state => state.jwt != null ? state.jwt : null,
    tokenHeader: state => {
        return splittedToken(state.jwt, 0);
    },
    tokenBody: state => {
        return splittedToken(state.jwt, 1);
    },
    isAuthenticated: state => state.jwt != null,
    isTokenExpired(state, getters) {
        var tokenBody = getters['tokenBody'];
        if (tokenBody == null)
            return true
        var jwt = window.atob(tokenBody);
        return jwt.exp * 1000 <= new Date(Date.now());
    },
    role: state => state.role != null ? state.role : null,
    userId: state => state.userId != null ? state.userId : null,
}

const splittedToken = (token, index) => {
    if (token == null || token.trim() == '')
        return null
    const splitted = token.split('.')

    if (splitted.length < 3)
        return null
    else if (index != null)
        return splitted[index]
    else
        return splitted
}