<template>
  <div>
    <v-card outlined class="mt-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <span class="text-uppercase pr-2 ma-auto">{{ $t("app.actions.filter") }}:</span>
            <v-btn elevation="0" small @click="onToggleShowActive" class="mr-2" :class="showActive ? 'selected' : ''">
              {{ $t("app.actions.showActive") }}
            </v-btn>
            <v-btn elevation="0" small @click="onToggleShowInactive" :class="showInactive ? 'selected' : ''">
              {{ $t("app.actions.showInactive") }}
            </v-btn>
            <v-spacer />
            <v-btn color="secondary" small @click="onAddEvent">{{ $t("app.actions.add") }}</v-btn>
          </v-col>
        </v-row>
        <v-data-table :items="events" item-key="id" :headers="headers" :loading="loading" @dblclick:row="onClickDetail" :items-per-page="10">
          <template v-slot:item.fromDate="{ item }">{{ item.fromDate | moment("YYYY-MM-DD") }}</template>
          <template v-slot:item.toDate="{ item }">{{ item.toDate | moment("YYYY-MM-DD") }}</template>
          <template v-slot:item.isActive="{ item }">
            <v-icon x-small :class="item.isActive ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
          <template v-slot:item.action="{ item }">
            <slot name="row-action">
              <v-icon small class="" @click="onClickEdit(null, item)">mdi-pencil</v-icon>
              <v-icon small @click="onClickDelete(null, item)">mdi-delete</v-icon>
            </slot>
          </template>
        </v-data-table>
        <edit-event-dialog v-model="editDialog" @close="dialog = false"></edit-event-dialog>
      </v-card-text>
    </v-card>
    <confirm-dialog :dialog="deleteDialog" @btnClicked="hasConfirmedDelete($event)"></confirm-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EditEventDialog from "../../components/ConfigEvent/EditEventDialog.vue";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog.vue";
import axios from "../../plugins/axios";

export default {
  components: { EditEventDialog, ConfirmDialog },
  data() {
    return {
      editDialog: false,
      deleteDialog: false,
      isEdit: false,
      events: [],
      eventIdToDelete: null,
      loading: false,
      showInactive: false,
      showActive: true,
    };
  },
  computed: {
    ...mapGetters({
      eventKey: "event/eventKey",
    }),
    headers() {
      return [
        {
          text: this.$t("page.eventConfig.name"),
          value: "name",
        },
        {
          text: this.$t("page.eventConfig.dateFrom"),
          value: "fromDate",
        },
        {
          text: this.$t("page.eventConfig.dateTo"),
          value: "toDate",
        },
        {
          text: this.$t("page.eventConfig.active"),
          value: "isActive",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("app.common.actions"),
          value: "action",
          width: "10%",
          sortable: false,
          align: "center",
        },
      ];
    },
  },
  watch: {
    eventKey() {
      this.loadEvents();
    },
  },
  methods: {
    loadEvents() {
      this.loading = true;
      axios
        .post("activity/search", {
          getActive: this.showActive,
          getInactive: this.showInactive,
        })
        .then((res) => {
          this.events = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail",
          });
        });
    },
    setEvent(event) {
      const eventtId = event && event.id;
      const eventName = event && event.name;

      this.$store.commit("event/setSelected", eventtId);
      this.$store.commit("event/setEdit", true);
      this.$store.commit("event/setEditDialog", true);
      this.$store.commit("event/setNameSelected", eventName);
    },
    onClickEdit(event, row) {
      this.setEvent(row);
    },
    onClickDelete(event, row) {
      this. eventIdToDelete = row && row.id;
      this.deleteDialog = true;
    },
    onClickDetail(evenFromTable, row) {
      const event = row && row.item;
      this.setEvent(event);
    },
    onAddEvent() {
      this.$store.commit("event/setEdit", false);
      this.$store.commit("event/setEditDialog", true);
    },
    onToggleShowActive() {
      this.showActive = !this.showActive;
      this.loadEvents();
    },
    onToggleShowInactive() {
      this.showInactive = !this.showInactive;
      this.loadEvents();
    },
    hasConfirmedDelete(isConfirmed) {
      if (isConfirmed) {
        axios
          .delete(`activity/${this. eventIdToDelete}`)
          .then(() => {
            this.$store.commit("event/setEventKey");
            this.$store.commit("notification/showSnackbar", {
              message: "Success",
            });
            this.deleteDialog = false;
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail",
            });
          });
      } else {
        this.deleteDialog = false;
      }
    },
  },
  created() {
    this.loadEvents();
  },
};
</script>