<template>
  <v-dialog v-model="openDialog" width="500px" :key="openDialog" persistent>
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ $t('component.categoryDetails.addBenefit') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="openDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-progress-linear
          :active="loading"
          indeterminate
          color="primary"
      ></v-progress-linear>
      <v-card-text class="pb-0 pt-4">
        <v-container>
          <span class="common_labels">{{ $t('component.categoryDetails.selectedBenefit') }}</span>
          <v-autocomplete
              class="common_txtfield"
              v-model="benefitToAdd.benefitId"
              :items="benefitList"
              item-text="name"
              item-value="id"
              outlined
              dense
          >
          </v-autocomplete>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-container class="pt-0">
          <v-btn class="float-right mr-2" small color="secondary" @click="save">
            {{ $t("app.actions.save") }}
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex';
import axios from "../../plugins/axios";
import {orderList} from "../../utils/functions"

export default {
  props: {
    addBenefitDialog: Boolean
  },
  data() {
    return {
      openDialog: false,
      benefitList: [],
      benefitToAdd: {},
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      categoryId: "category/selected",
      categoryBenefitSelected: "category/categoryBenefitSelected"
    }),
  },
  watch: {
    openDialog(v) {
      this.$emit("input", v);
    },
    addBenefitDialog(v) {
      this.openDialog = v;
      this.benefitToAdd.benefitId= (this.categoryBenefitSelected && this.categoryBenefitSelected.id)?this.categoryBenefitSelected.benefitId:null
    }
  },
  methods: {
    loadBenefitSelectList() {
      this.loading = true;
      axios
          .get('Benefit/SelectList')
          .then(res => {
            if (res && res.data) {
              orderList()
              this.benefitList =  orderList(res.data, 'name', true) ;
              this.loading = false;
            }
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail"
            });
          });
    },
    createNewBenefitMembershiCategory() {
      axios
          .post(`MembershipCategoryBenefit`, this.benefitToAdd)
          .then(res => {
            if (res && res.data) {
              this.$store.commit('category/setBenefitKey');
              this.$store.commit('category/clearCategoryBenefitSelected');
              this.$store.commit("notification/showSnackbar", {
                message: "Success"
              });
              this.openDialog = false;
            }
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail"
            });
          });
    },
    updateBenefitMembershiCategory() {
      axios
          .put(`MembershipCategoryBenefit/${this.benefitToAdd.id}`, this.benefitToAdd)
          .then(res => {
            if (res && res.data) {
              this.$store.commit('category/setBenefitKey');
              this.$store.commit('category/clearCategoryBenefitSelected');
              this.$store.commit("notification/showSnackbar", {
                message: "Success"
              });
              this.openDialog = false;
            }
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail"
            });
          });
    },

    save() {
      this.benefitToAdd.membershipCategoryId = this.categoryId;
      if (this.categoryBenefitSelected && this.categoryBenefitSelected.id ) {
        this.benefitToAdd.id = this.categoryBenefitSelected.id;
        this.updateBenefitMembershiCategory()
      } else {

        this.createNewBenefitMembershiCategory()
      }
    }
  },
  created() {
    this.loadBenefitSelectList();
  }
};
</script>