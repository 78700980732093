import BatchLayout from "../../views/Batch/BatchLayout"
import DonationsTemplate from "../../components/BatchTemplates/BatchTemplate"
import ImportFinancialRec from "../../components/BatchTemplates/ImportFinancialRec"

export default {
    path: 'batch',
    name: '',
    component: BatchLayout,
    children: [
        {
            path: 'Donations',
            name: 'donations',
            component: DonationsTemplate,
            meta: {typeBatch: 'donations'}
        },
        // {
        //     path: 'StaffContributions',
        //     name: 'staffContributions',
        //     component: StaffContributionsTemplate,
        // },
        {
            path: 'MembershipContributions',
            name: 'membership',
            component: DonationsTemplate,
            meta: {typeBatch: 'membership'}
        },
        {
            path: 'ImportFinancialRec.vue',
            name: 'importFinancialRec',
            component: ImportFinancialRec,
        }
    ]
}