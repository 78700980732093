<template>
  <v-navigation-drawer permanent app clipped v-if="showDrawer != null">
    <component :is="showDrawer"></component>
    <!-- <router-view name="sidebar"></router-view> -->
  </v-navigation-drawer>
</template>
<script>
export default {
  computed: {
    // showDrawer() {
    //   var matchedRoute = this.$route.matched;
    //   var drawerMeta = matchedRoute
    //     .map((x) => x.meta.sidebar)
    //     .filter((x) => x != null);
    //   var show = drawerMeta.some((x) => x);
    //   return show;
    // },
    showDrawer() {
      var matchedRoute = this.$route.matched;
      var drawerMeta = matchedRoute
        .map((x) => x.meta.drawer)
        .filter(
          (x) => x != null && typeof x == "string" && x.trim().length > 0
        );
      if (drawerMeta.length > 0) {
        let drawer = drawerMeta.pop();
        return drawer;
      } else return null;
    },
  },
};
</script>