<template>
  <v-card>
    <v-list class="pb-0">
      <v-list-item class="pointer" :to="{ name: 'MyProfile' }">
        <v-list-item-avatar>
          <v-avatar color="accent">
            <span class="white--text text-h6">{{ userInitials }}</span>
          </v-avatar>
          <!-- <img
            src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg"
            alt="John"
          /> -->
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ userName }}</v-list-item-title>

          <!-- <v-list-item-subtitle>{{ profile }}</v-list-item-subtitle> -->
        </v-list-item-content>

        <!-- <v-list-item-action>
          <v-btn :class="fav ? 'red--text' : ''" icon @click="fav = !fav">
            <v-icon>mdi-edit</v-icon>
          </v-btn>
        </v-list-item-action> -->
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-list>
      <v-list-item>
        <v-btn
          text
          block
          @click="
            menu = false;
            logout();
          "
          >{{ $t("appbar.notificationCenter") }}
        </v-btn>
      </v-list-item>
      <v-list-item
        ><v-btn
          text
          block
          @click="
            menu = false;
            logout();
          "
          >{{ $t("appbar.calendar") }}
        </v-btn></v-list-item
      >
      <v-divider></v-divider>
      <v-list-item>
        <v-btn
          text
          block
          @click="
            menu = false;
            logout();
          "
          >{{ $t("appbar.logout") }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import debugMixin from "../../mixins/debug";
export default {
  components: {},
  mixins: [debugMixin],
  props: ['userName', 'userInitials'],
  data() {
    return {
      menu: false
    };
  },
  computed: {
    fullname() {
      return this.$store.getters["profile/fullname"];
    },
    userInitial() {
      return this.$store.getters["profile/initial"];
    }
  },
  watch: {},
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>
