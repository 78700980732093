<template>
  <v-sheet style="background-color:rgb(247 248 249);">
    <router-view></router-view>
  </v-sheet>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>