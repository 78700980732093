<template>
  <v-sheet elevation="0">
    <v-card :loading="loading" class="ml-5 mt-2">
      <header-batch @addRow="addRow" @minusRow="deleteRow" @sendData="sendConmit()" />
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="formBatch" v-model="valid">
          <v-data-table dense :single-select="false" :headers="headers" :items="rows" disable-pagination hide-default-footer>
            <template v-slot:item="{ item }">
              <row-donation-batch
                v-if="$route.meta.typeBatch == 'donations'"
                @deleteRow="deleteRow"
                @updateItem="updateItem"
                :item="item"
                :donationTypeIds="donationTypeIds"
                :subtypes="subtypes"
                :campaigns="campaigns"
                :funds="funds"
                :acknowledges="acknowledges"
                :receiptstatus="receiptstatus"
                :transactionTypes="transactionTypes"
                :letterCodes="letterCodes"
                :budgetCodes="budgetCodes"
                :glPostStatues="glPostStatues"
              />
              <row-membership-batch
                v-if="$route.meta.typeBatch == 'membership'"
                @deleteRow="deleteRow"
                @updateItem="updateItem"
                :item="item"
                :donationTypeIds="donationTypeIds"
                :subtypes="subtypes"
                :campaigns="campaigns"
                :funds="funds"
                :acknowledges="acknowledges"
                :receiptstatus="receiptstatus"
                :transactionTypes="transactionTypes"
                :letterCodes="letterCodes"
                :budgetCodes="budgetCodes"
                :glPostStatues="glPostStatues"
              />
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
    </v-card>
    <note-batch-dialog @updateNote="updateNote" v-model="showFind" @close="dialog = false" />
  </v-sheet>
</template>
<script>
import axios from "../../plugins/axios";
import NoteBatchDialog from "../Dialogs/Batch/NoteBatchDialog";
import { mapGetters } from "vuex";
import BatchUtils from "../../mixins/batchUtils";
import HeaderBatch from "./HeaderBatch";
import RowDonationBatch from "./RowDonationBatch";
import RowMembershipBatch from "./RowMembershipBatch";

export default {
  components: {
    HeaderBatch,
    RowDonationBatch,
    RowMembershipBatch,
    NoteBatchDialog
  },
  mixins: [BatchUtils],
  data() {
    return {
      rows: [],
      cont: 0,
      form: {
        constituentId: null,
        membershipId: null,
        date: null,
        donationTypeId: null,
        transactionSubTypeId: null,
        campaignId: null,
        fundId: null,
        appealId: null,
        packageId: null,
        amount: null,
        acknowledgeStatusId: null,
        receiptAmount: null,
        receiptStatusId: null,
        receiptDate: null,
        transactionTypeId: null,
        chequeNumber: null,
        chequeDate: null,
        referenceNumber: null,
        referenceDate: null,
        referenceComment: null,
        letterCodeId: null,
        budgetCodeId: null,
        isAnonymous: false,
        glPostStatusId: null,
        membershipProgramId: null,
        membershipCategoryId: null,
        membershipDetailTypeId: null,
        membershipExpireDate: null
      },
      donationTypeIds: [],
      subtypes: [],
      campaigns: [],
      funds: [],
      appeals: [],
      allAppeals: [],
      packages: [],
      acknowledges: [],
      receiptstatus: [],
      transactionTypes: [],
      letterCodes: [],
      budgetCodes: [],
      glPostStatues: [],
      dateStatus: false,
      loading: false,
      valid: true
    };
  },
  computed: {
    ...mapGetters({
      showFind: "batch/showFind",
      showNote: "batch/showNote",
      noteValue: "batch/noteValue",
      selectedConstituent: "batch/selectedConstituent"
    })
  },
  created() {
    this.loading = true;
    this.loaddonationTypeIds();
    this.loadTransactionSubTypes();
    this.loadCampaigns();
    this.loadFunds();
    this.loadAcknowledgeStatus();
    this.loadReceiptStatus();
    this.loadTransactionTypes();
    this.loadBudgetCodes();
    this.loadGLPostStatus();
    this.loadLetterCodes();
    this.loading = false;
  },
  watch: {
    "$route.name"() {
      this.rows = [];
    }
  },
  methods: {
    addRow() {
      this.cont = this.cont + 1;
      this.form.id = this.cont;
      this.rows.push(Object.assign({}, this.form));
    },
    deleteRow(elementID) {
      const index = this.rows.findIndex(item => item.id === elementID);
      this.rows.splice(index, 1);
    },
    validate() {
      return this.$refs.formBatch.validate();
    },
    ShowSelectedConstituent() {
      this.$store.commit("batch/showFind", true);
    },
    setShowNote() {
      this.$store.commit("batch/showNote", true);
    },
    updateNote(value) {
      this.form.referenceComment = value;
    },
    updateItem(item) {
      const index = this.rows.findIndex(element => element.id === item.id);
      this.rows[index] = item;
    },
    loaddonationTypeIds() {
      axios
        .get("Donation/types")
        .then(res => {
          if (res && res.data) {
            this.donationTypeIds = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadTransactionSubTypes() {
      axios
        .get("TransactionSubType")
        .then(res => {
          if (res && res.data) {
            this.subtypes = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadCampaigns() {
      axios
        .get("Campaign/SelectList")
        .then(res => {
          if (res && res.data) {
            this.campaigns = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadFunds() {
      axios
        .get("Fund/SelectList")
        .then(res => {
          if (res && res.data) {
            this.funds = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadAppeals() {
      axios
        .get("Appeal/campaign/" + this.form.campaignId)
        .then(res => {
          if (res && res.data) {
            this.appeals = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadPackages() {
      axios
        .get("Package/appeal/" + this.form.appealObject.id)
        .then(res => {
          if (res && res.data) {
            this.packages = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadAcknowledgeStatus() {
      axios
        .get("AcknowledgeStatus")
        .then(res => {
          if (res && res.data) {
            this.acknowledges = res.data;
            this.form.acknowledgeStatusId = this.acknowledges.find(item => item.isDefault == true).id;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadReceiptStatus() {
      axios
        .get("ReceiptStatus")
        .then(res => {
          if (res && res.data) {
            this.receiptstatus = res.data;
            this.form.receiptStatusId = this.receiptstatus.find(item => item.isDefault == true).id;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadTransactionTypes() {
      axios
        .get("TransactionType")
        .then(res => {
          if (res && res.data) {
            this.transactionTypes = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadBudgetCodes() {
      axios
        .get("BudgetCode")
        .then(res => {
          if (res && res.data) {
            this.budgetCodes = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadGLPostStatus() {
      axios
        .get("GLPostStatus")
        .then(res => {
          if (res && res.data) {
            this.glPostStatues = res.data;
            this.form.glPostStatusId = this.glPostStatues.find(item => item.isDefault == true).id;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadLetterCodes() {
      axios
        .get("LetterCode")
        .then(res => {
          if (res && res.data) {
            this.letterCodes = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    findElementByKey(key, id, field = null) {
      return this[key].filter(item => item[field ? field : "id"] === id)[0];
    },
    deleteElement(index) {
      this.rows.splice(index, 1);
    },
    sendDonations(data) {
      this.loading = true;
      axios
        .post("Donation/CommitImport", data)
        .then(res => {
          this.$store.commit("notification/showSnackbar", {
            message: "Success"
          });
          this.rows = [];
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
          this.loading = false;
        });
    },
    sendMemberships(data) {
      this.loading = true;
      axios
        .post("Membership/CommitImport", data)
        .then(res => {
          this.rows = [];
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    clearForm() {
      this.$refs.formBatch.resetValidation();
      this.form = {
        constituentId: null,
        membershipId: null,
        date: null,
        donationTypeId: null,
        transactionSubTypeId: null,
        campaignId: null,
        fundId: null,
        appealId: null,
        appealObject: null,
        packageId: null,
        packageObject: null,
        amount: null,
        acknowledgeStatusId: null,
        receiptAmount: null,
        receiptStatusId: null,
        receipt_number: null,
        receipt_stack: null,
        transactionTypeId: null,
        chequeNumber: null,
        chequeDate: null,
        referenceNumber: null,
        referenceDate: null,
        referenceComment: null,
        letterCodeId: null,
        budgetCodeId: null,
        isAnonymous: false,
        glPostStatusId: null,
        glPostDate: null
      };
    },
    sendConmit() {
      this.validate();
      if (this.valid) {
        this.rows.forEach(item => {
          delete item.id;
        });
        const data = this.rows;
        if (this.$route.meta.typeBatch === "donations") {
          this.sendDonations(data);
        } else if (this.$route.meta.typeBatch === "membership") {
          this.sendMemberships(data);
        }
      } else {
        // this.$store.commit("notification/showSnackbar", {
        //   message: "Error",
        // });
      }
    }
  }
};
</script>
