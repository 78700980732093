import axios from "../../plugins/axios";

export const state = {
  contactInformations: [],
  contactInformationCategories: [],
  contactInformationSubTypes: [],
  contactInformationTypes: [],
  phoneNumbers: null,
  emailAddresses: null,
  links: null,
  selectedContactInformation: null,
  contactInformationKey: 0,
  saveContactInformationRes: [],
  contactInformationInactiveRes: [],
  contactInformationRefreshKey: 0
};

export const mutations = {
  setContactInformationRefreshKey(state) {
    state.contactInformationRefreshKey = state.contactInformationRefreshKey + 1;
  },
  clearContactInformation(state) {
    state.contactInformations = [];
    state.phoneNumbers = null;
    state.emailAddresses = null;
    state.links = null;
  },
  setContactInformations(state, contactInformations) {
    if (contactInformations == null) contactInformations = [];
    state.contactInformations = contactInformations.sort((a, b) => a.sequence > b.sequence);
    let phoneNumberIds = [1, 2, 3, 4];
    state.phoneNumbers = contactInformations.filter(x => phoneNumberIds.includes(x.contactInformationSubTypeId));
    let linkIds = [6, 7, 8, 9];
    state.links = contactInformations.filter(x => linkIds.includes(x.contactInformationSubTypeId));
    let emailIds = [5];
    state.emailAddresses = contactInformations.filter(x => emailIds.includes(x.contactInformationSubTypeId));
  },
  setContactInformationCategories(state, contactInformationCategories) {
    if (contactInformationCategories == null) contactInformationCategories = [];

    state.contactInformationCategories = contactInformationCategories;
  },
  setContactInformationSubTypes(state, contactInformationSubTypes) {
    if (contactInformationSubTypes == null) contactInformationSubTypes = [];

    state.contactInformationSubTypes = contactInformationSubTypes;
  },
  setContactInformationTypes(state, contactInformationTypes) {
    if (contactInformationTypes == null) contactInformationTypes = [];

    state.contactInformationTypes = contactInformationTypes;
  },
  setContactInformationKey(state) {
    state.contactInformationKey = state.contactInformationKey + 1;
  },
  setSelected(state, payload) {
    state.selectedContactInformation = { ...payload };
  },
  updateEmailAddresses(state, emailAddresses) {
    state.emailAddresses = [];
    state.emailAddresses = emailAddresses;
  },
  updateLinks(state, links) {
    state.links = [];
    state.links = links;
  },
  updatePhoneNumbers(state, phoneNumbers) {
    state.phoneNumbers = [];
    state.phoneNumbers = phoneNumbers;
  },
  saveContactInformation_result(state, res) {
    state.saveContactInformationRes = res;
  },
  setContactInformationInactive_success(state, res) {
    state.contactInformationInactiveRes = res;
  }
};

export const actions = {
  clearContactInformation({ commit }) {
    commit("clearContactInformation");
  },
  setContactInformations({ commit }, payload) {
    commit("setContactInformations", payload);
  },
  setContactInformationSelected({ commit }, payload) {
    commit("setSelected", payload);
  },
  setContactInformationInactive({ commit, getters }, contactInformationId) {
    return new Promise((resolve, reject) => {
      console.log(contactInformationId);
      if (contactInformationId) {
        axios
          .put(`contactInformation/${contactInformationId}/set-inactive`)
          .then(res => {
            commit("setContactInformationInactive_success", res);
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      } else reject("no id");
    });
  },
  loadContactInformationSelected({ commit }, { contactInformationId }) {
    return new Promise((resolve, reject) => {
      axios.get(`contactInformation/${contactInformationId}`).then(res => {
        commit("setSelected", res.data);
      });
    });
  },
  loadContactInformations({ commit, rootGetters }, id) {
    if (id == null) {
      if (rootGetters["constituent/id"]) {
        id = rootGetters["constituent/id"];
      }
    }
    if (id) {
      return new Promise((resolve, reject) => {
        //ICITTE faire marcher ca pour load les infos comme du monde.... lol.....
        axios
          .get("/contactInformation/constituent/" + id)
          .then(res => {
            let contactInformations = res.data;
            commit("setContactInformations", contactInformations);
            resolve();
          })
          .catch(e => reject(e));
      });
    } else {
      return new Promise(resolve => {
        commit("setContactInformations", null);
        resolve();
      });
    }
  },
  loadContactInformationCategories({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/contactInformation/categories")
        .then(res => {
          let contactInformationCategories = res.data;
          commit("setContactInformationCategories", contactInformationCategories);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  loadContactInformationSubTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/contactInformation/subTypes")
        .then(res => {
          let contactInformationSubTypes = res.data;
          commit("setContactInformationSubTypes", contactInformationSubTypes);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  loadContactInformationTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/contactInformation/types")
        .then(res => {
          let contactInformationTypes = res.data;
          commit("setContactInformationTypes", contactInformationTypes);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  saveContactInformations({ commit, dispatch, state }, constituentId) {
    return new Promise((resolve, reject) => {
      let phoneNumbers = state.phoneNumbers.filter(x => x.isEdited);
      phoneNumbers = phoneNumbers.map(obj => ({ ...obj, constituentId: constituentId }));
      let emailAddresses = state.emailAddresses.filter(x => x.isEdited);
      emailAddresses = emailAddresses.map(obj => ({ ...obj, constituentId: constituentId }));
      let links = state.links.filter(x => x.isEdited);
      links = links.map(obj => ({ ...obj, constituentId: constituentId }));
      let contactInformations = { phoneNumbers, emailAddresses, links };

      axios
        .post("/contactInformation/saveAll", { contactInformations: contactInformations, constituentId: constituentId })
        .then(res => {
          commit("setContactInformationKey");
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  saveContactInformation({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      let req;
      if (payload.id) {
        req = axios.put(`/contactInformation/${payload.id}`, payload);
      } else {
        req = axios.post(`/contactInformation`, payload);
      }
      req
        .then(res => {
          commit("saveContactInformation_result", res.data);
          resolve();
        })
        .catch(e => reject(e));
    });
  }
};

export const getters = {
  contactInformationRefreshKey: state => state.contactInformationRefreshKey,
  contactInformations: state => state.contactInformations,
  contactInformationCategories: state => state.contactInformationCategories,
  contactInformationSubTypes: state => state.contactInformationSubTypes,
  contactInformationTypes: state => state.contactInformationTypes,
  phoneNumbers: state => state.phoneNumbers,
  phoneNumberSubtypes: state =>
    state.contactInformationSubTypes.filter(x => x.contactInformationTypeName == "Phone" || x.contactInformationTypeName == "Fax"),
  emailSubtypes: state => state.contactInformationSubTypes.filter(x => x.contactInformationTypeName == "Email"),
  emailAddresses: state => state.emailAddresses,
  selectedContactInformation: state => state.selectedContactInformation,
  links: state => state.links,
  linkSubtypes: state => state.contactInformationSubTypes.filter(x => x.contactInformationTypeName == "Url"),
  getSaveContactInformationRes: state => state.saveContactInformationRes
};
