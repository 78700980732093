<template>
  <tr>
    <td>
      <FindConstituentDialog @updateConstituent="updateConstituent" :showFind="showFind" @showFind="showFind = !showFind"></FindConstituentDialog>
      <div @click="showFind = true" @keypress="showFind = true" style="width: 180px;">
        <v-text-field
          prepend-icon="mdi-account-search"
          v-model="constituentName"
          :rules="[v => !!v || $t('dialog.error.required')]"
          :label="$t('batch.templates.pickConstituent')"
        ></v-text-field>
      </div>
    </td>

    <td>
      <div style="width: 180px;">
        <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              :rules="[v => !!v || $t('dialog.error.required')]"
              v-model="form.date"
              clearable
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="form.date"></v-date-picker>
        </v-menu>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          :rules="[v => !!v || $t('dialog.error.required')]"
          v-model="form.transactionSubTypeId"
          :loading="loading"
          :items="subtypes"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete dense item-value="id" item-text="name" v-model="form.campaignId" :loading="loading" :items="campaigns"></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete dense v-model="form.fundId" :loading="loading" :items="funds" item-value="id" item-text="name"></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          :disabled="form.campaignId === null"
          v-model="form.appealId"
          :loading="loading"
          :items="appeals"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          :disabled="form.appealId === null"
          v-model="form.packageId"
          :loading="loading"
          :items="packages"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-text-field 
          :rules="[v => !!v || $t('dialog.error.required')]"  
          v-model="form.amount" 
          dense
          @keypress.native="onlyAcceptNumber"
          @blur="formatAmount"
          @change="formatAmount"
        ></v-text-field>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          v-model="form.acknowledgeStatusId"
          :rules="[v => !!v || $t('dialog.error.required')]"
          :loading="loading"
          :items="acknowledges"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          :rules="[v => !!v || $t('dialog.error.required')]"
          v-model="form.receiptStatusId"
          :loading="loading"
          :items="receiptstatus"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-text-field 
          dense 
          :rules="[v => !!v || $t('dialog.error.required')]" 
          v-model="form.receiptAmount"
          @keypress.native="onlyAcceptNumber"
          @blur="formatReceiptAmount"
          @change="formatReceiptAmount"
        ></v-text-field>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          :rules="[v => !!v || $t('dialog.error.required')]"
          v-model="form.transactionTypeId"
          :loading="loading"
          :items="transactionTypes"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-text-field v-model="form.chequeNumber" dense></v-text-field>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense clearable v-model="form.chequeDate" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="form.chequeDate"></v-date-picker>
        </v-menu>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-text-field v-model="form.referenceNumber" dense></v-text-field>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense clearable v-model="form.referenceDate" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="form.referenceDate"></v-date-picker>
        </v-menu>
      </div>
    </td>
    <!--      <td style="width: 180px;">-->
    <!--        <div>-->
    <!--          <v-icon @click="setShowNote()" :color="form.referenceComment ? 'secondary' : 'grey-2'">-->
    <!--            mdi-notebook-check-->
    <!--          </v-icon>-->
    <!--        </div>-->
    <!--        -->
    <!--      </td>-->
    <td>
      <div style="width: 180px;">
        <v-autocomplete dense v-model="form.letterCodeId" :loading="loading" :items="letterCodes" item-value="id" item-text="name"></v-autocomplete>
      </div>
    </td>
    <!--      <td class="d-flex justify-center " style="border: none">-->
    <!--        <div>-->
    <!--          <v-checkbox-->
    <!--              dense-->
    <!--              v-model="form.isAnonymous"-->
    <!--          ></v-checkbox>-->
    <!--        </div>-->
    <!--      </td>-->
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          :rules="[v => !!v || $t('dialog.error.required')]"
          v-model="form.budgetCodeId"
          :loading="loading"
          :items="budgetCodes"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          :rules="[v => !!v || $t('dialog.error.required')]"
          v-model="form.glPostStatusId"
          :loading="loading"
          :items="glPostStatues"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense v-model="form.glPostDate" prepend-icon="mdi-calendar" readonly clearable v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="form.glPostDate"></v-date-picker>
        </v-menu>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          v-model="form.membershipId"
          :disabled="form.constituentId === null"
          :rules="[v => !!v || $t('dialog.error.required')]"
          :loading="loading"
          :items="memberships"
          item-value="id"
          item-text="name"
          @change="loadMembershipPrograms"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          v-model="form.membershipProgramId"
          :disabled="form.membershipId === null"
          :rules="[v => !!v || $t('dialog.error.required')]"
          :loading="loading"
          :items="membershipPrograms"
          item-value="id"
          item-text="name"
          @change="loadMembershipCategoryType"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          v-model="form.membershipCategoryId"
          :disabled="form.membershipProgramId === null"
          :rules="[v => !!v || $t('dialog.error.required')]"
          :loading="loading"
          :items="membershipCategories"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-autocomplete
          dense
          v-model="form.membershipDetailTypeId"
          :rules="[v => !!v || $t('dialog.error.required')]"
          :loading="loading"
          :items="membershipTransactionTypes"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </div>
    </td>
    <td>
      <div style="width: 180px;">
        <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="form.membershipExpireDate"
              :rules="[v => !!v || $t('dialog.error.required')]"
              prepend-icon="mdi-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="form.membershipExpireDate"></v-date-picker>
        </v-menu>
      </div>
    </td>
    <td class="d-flex justify-center" style="border: none">
      <v-btn icon color="primary" class="ma-2 white--text" @click="$emit('deleteRow', form.id)">
        <v-icon> mdi-delete</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script>
import axios from "../../plugins/axios";
import FindConstituentDialog from "../Dialogs/Batch/FindConstituentDialog";

export default {
  props: {
    item: Object,
    donationTypeIds: Array,
    subtypes: Array,
    campaigns: Array,
    funds: Array,
    receiptstatus: Array,
    transactionTypes: Array,
    letterCodes: Array,
    glPostStatues: Array,
    acknowledges: Array,
    budgetCodes: Array
  },
  components: {
    FindConstituentDialog
  },
  data() {
    return {
      form: {
        constituentId: null,
        membershipId: null,
        date: null,
        transactionSubTypeId: null,
        campaignId: null,
        fundId: null,
        appealId: null,
        packageId: null,
        amount: null,
        acknowledgeStatusId: null,
        receiptAmount: null,
        receiptStatusId: null,
        receiptDate: null,
        transactionTypeId: null,
        chequeNumber: null,
        chequeDate: null,
        referenceNumber: null,
        referenceDate: null,
        referenceComment: null,
        letterCodeId: null,
        budgetCodeId: null,
        // isAnonymous: false,
        glPostStatusId: null,
        membershipProgramId: null,
        membershipCategoryId: null,
        membershipDetailTypeId: null,
        membershipExpireDate: null
      },
      constituentSelected: null,
      memberShipSelected: null,
      constituentName: "",
      showFind: false,
      showFindMembership: false,
      appeals: [],
      packages: [],
      membershipCategories: [],
      membershipPrograms: [],
      memberships: [],
      membershipTransactionTypes: [],
      loading: false,
      valid: true,
      isSelected: false
    };
  },
  computed: {},
  created() {
    this.loading = true;
    this.form = this.item;
    //this.loadMemberships();
    //this.loadMembershipPrograms();
    //this.loadMembershipCategoryType();
    this.loadMembershipTransactionTypes();
    this.loading = false;
  },
  watch: {
    "form.campaignId"() {
      if (this.form.campaignId !== null) {
        this.loadAppeals();
      }
    },
    "form.appealId"() {
      if (this.form.appealId !== null) {
        this.loadPackages();
      }
    },
    "form.constituentId"() {
      if (this.form.constituentId !== null) {
        this.loadMemberships();
      }
    },
    // "form.membershipId"() {
    //   if (this.form.membershipId !== null) {
    //     this.loadMembershipPrograms();
    //   }
    // },
    // "form.membershipProgramId"() {
    //   if (this.form.membershipProgramId !== null) {
    //     this.loadMembershipCategoryType();
    //   }
    // },
    "form.packageId"() {
      if (this.form.appealId !== null) {
        this.loadMembershipCategoryType();
        this.form.membershipCategoryId = this.packages.find(item => item.id === this.form.packageId).defaultMembershipCategoryId;
      }
    },
    form: {
      handler: function(newItem, oldCar) {
        this.$emit("updateItem", newItem);
      },
      deep: true
    },
    item: {
      handler: function(newItem) {
        this.form = newItem;
      },
      deep: true
    }
  },
  methods: {
    formatAmount() {
      if (this.form.amount != undefined && this.form.amount != null && this.form.amount !== '' && !isNaN(this.form.amount))
        this.form.amount = parseFloat(this.form.amount).toFixed(2);
    },
    formatReceiptAmount() {
      if (this.form.receiptAmount != undefined && this.form.receiptAmount != null && this.form.receiptAmount !== '' && !isNaN(this.form.receiptAmount))
        this.form.receiptAmount = parseFloat(this.form.receiptAmount).toFixed(2);
    },
    updateConstituent(constituent) {
      this.form.constituentId = constituent.id;
      this.constituentSelected = constituent;
      this.constituentName = this.constituentSelected.name;
      this.showFind = false;
    },
    setShowNote() {
      this.$store.commit("batch/showNote", true);
    },
    updateNote(value) {
      this.form.referenceComment = value;
    },
    loadAppeals() {
      axios
        .get("Appeal/campaign/" + this.form.campaignId)
        .then(res => {
          if (res && res.data) {
            this.appeals = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadPackages() {
      axios
        .get("Package/appeal/" + this.form.appealId)
        .then(res => {
          if (res && res.data) {
            this.packages = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadMemberships() {
      axios
        .get("Membership/SelectList", { params: { constituentId: this.form.constituentId } })
        .then(res => {
          if (res && res.data) {
            this.form.membershipId = null;
            this.form.membershipProgramId = null;
            this.form.membershipCategoryId = null;

            this.memberships = res.data;
            this.memberships.unshift({id: -1, name: "New"}) //If we want to create a new membership

            // const global = this.memberships.find(item => item.isActive == true);
            // this.form.membershipId = global.id;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadMembershipPrograms() {
      axios
        .get("MembershipProgram/SelectList", { params: { membershipId: this.form.membershipId } })
        .then(res => {
          if (res && res.data) {
            this.form.membershipProgramId = null;
            this.form.membershipCategoryId = null;

            this.membershipPrograms = res.data;

            const currentValue = this.membershipPrograms.find(item => item.isDefault == true);
            if (currentValue != undefined)
            {
              this.form.membershipProgramId = currentValue.id;
              this.loadMembershipCategoryType();
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadMembershipCategoryType() {
      axios
        .get("MembershipCategory/SelectList", { params: { programId: this.form.membershipProgramId, membershipId: this.form.membershipId } })
        .then(res => {
          if (res && res.data) {
            this.form.membershipCategoryId = null;

            this.membershipCategories = res.data;

            const currentValue = this.membershipCategories.find(item => item.isSelected == true);
            if (currentValue != undefined)
              this.form.membershipCategoryId = currentValue.id;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadMembershipTransactionTypes() {
      axios
        .get("MembershipDetailType")
        .then(res => {
          if (res && res.data) {
            this.membershipTransactionTypes = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
