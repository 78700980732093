<template>
  <div class="constituents-addresses_container">
    <v-col cols="12">
      <v-data-table
        class="hide-centered-filterarrow elevation-1 rounded-0"
        :headers="headers"
        :items="donations"
        :options.sync="options"
        height="75vh"
        :server-items-length="totalDonations"
        :loading="loading"
        dense
      >
        <template v-slot:item.totalAmount="{ item }">{{ item.totalAmount | amount }}</template>
        <template v-slot:item.constituent_IsGivingAnonymously="{ item }">
          <v-icon x-small :class="item.constituent_IsGivingAnonymously ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
        </template>
        <template v-slot:item.constituent_IsDoNotSolicit="{ item }">
          <v-icon x-small :class="item.constituent_IsDoNotSolicit ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
        </template>
        <template v-slot:item.constituent_IsDeceased="{ item }">
          <v-icon x-small :class="item.constituent_IsDeceased ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </div>
</template>
<script>
export default {
  name: "ReportConstituentResults",
  components: {},
  props: ["result", "loading"],
  data() {
    return {
      donations: [],
      totalDonations: 0,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        size: 10,
        sortBy: [],
        sortDesc: true
      },
      headers: [
        {
          text: this.$t("page.reports.constituent.id"),
          value: "constituent_Id"
        },
        {
          text: this.$t("page.reports.constituent.firstName"),
          value: "first_Name",
          width: 140
        },
        {
          text: this.$t("page.reports.constituent.lastName"),
          value: "last_Name"
        },
        {
          text: this.$t("page.reports.constituent.type"),
          value: "constituent_Type"
        },
        {
          text: this.$t("page.reports.constituent.primaryEmailAddresses"),
          value: "primary_Email_Address"
        },
        {
          text: this.$t("page.reports.constituent.country"),
          value: "country",
          width: 150
        },
        {
          text: this.$t("page.reports.constituent.language"),
          value: "preferred_Language"
        },
        {
          text: this.$t("page.reports.constituent.constituentCode"),
          value: "constituent_Code"
        },
        {
          text: this.$t("page.reports.constituent.isDeceased"),
          value: "constituent_IsDeceased"
        },
        {
          text: this.$t("page.reports.constituent.isDoNotSolicit"),
          value: "constituent_IsDoNotSolicit"
        },
        {
          text: this.$t("page.reports.constituent.isGivingAnonymously"),
          value: "constituent_IsGivingAnonymously"
        },
        {
          text: this.$t("page.reports.constituent.donationTotalAmount"),
          value: "totalAmount"
        },
        {
          text: this.$t("page.reports.constituent.numberOfDonations"),
          value: "numberOfDonations"
        }
      ]
    };
  },
  computed: {},
  filters: {
    amount: value => {
      if (!value) return 0;
      return value.toLocaleString("en-US", { minimumFractionDigits: 2 });
    }
  },
  watch: {
    options: {
      handler(v) {
        this.$emit("changePagination", v);
      },
      deep: true
    },
    result: {
      handler(v) {
        this.donations = v.records || [];
        this.totalDonations = v.totalCount || 0;
      },
      deep: true
    }
  },
  methods: {
    getDate(date) {
      return date
        ? this.$moment(date)
            .local()
            .format(this.$store.getters.generalDateFormat)
        : "N/A";
    }
  }
};
</script>
