export const ActionEdit = "dialog-action-edit";
export const ConstituentActivityEdit = "dialog-activity-edit";
export const AddressEdit = "dialog-address-edit";
export const ContactEdit = "dialog-link-edit";
export const ConstituentCreateContactEdit = "dialog-constituent-create-contact-edit";
export const ConstituentFilter = "dialog-constituent-filter";
export const ConstituentIndividualCreate = "dialog-constituent-individual-create";
export const ConstituentOrganisationCreate = "dialog-constituent-organisation-create";
export const DonationEdit = "dialog-donation-edit";
export const MembershipEdit = "dialog-membership-edit";
export const GlobalSearch = "dialog-global-search";
export const ImportConstituents = "dialog-import-constituents";
export const NoteEdit = "dialog-note-edit";
export const NoteDelete = "dialog-note-delete";
export const RelationshipEdit = "dialog-relationship-edit";
export const DeleteDialogConfirmation = "delete-confirmation";
export const CommunicationDialogEdit = "dialog-communication-edit";
export const ShowNoteCommunicationDialog = "dialog-communication-show-note";
