<template>
  <v-card height="92vh" elevation="0" class="report_result-table_container">
    <v-row class="justify-space-between mt-0">
      <v-col cols="10">
        <v-btn class="mr-2" icon plain :to="{ name: 'builder' }">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="font-weight-bold">{{ $t("page.reports.membership.NewReportTitle") }}</span>
      </v-col>
      <v-col cols="2" class="d-flex justify-end pt-1 align-center">
        <v-btn depressed color="secondary" small @click="drawer = true">
          {{ $t("app.actions.filters") }}
        </v-btn>
        <v-btn :loading="loadingExcel" icon color="green" large @click="exportData">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <report-membership-filters @search="search" @hideFilters="drawer = false" :drawer="drawer" v-model="filters"></report-membership-filters>
    <report-membership-results class="pa-2" @changePagination="changePagination" :result="result" :loading="loading"></report-membership-results>
  </v-card>
</template>
<script>
import axios from "../../../plugins/axios";
import ReportMembershipFilters from "./ReportMembershipFilters.vue";
import ReportMembershipResults from "./ReportMembershipResults.vue";

export default {
  name: "ReportMembership",
  components: { ReportMembershipFilters, ReportMembershipResults },
  data() {
    return {
      result: null,
      mounted: false,
      options: {},
      drawer: false,
      loading: false,
      loadingExcel: false,

      filters: {
        joinDate: {
          startDate: null,
          endDate: null
        },
        startDate: {
          startDate: null,
          endDate: null
        },
        expiryDate: {
          startDate: null,
          endDate: null
        },
        program: {
          operator: null,
          values: []
        },
        category: {
          operator: null,
          values: []
        },
        transactionType: {
          operator: null,
          values: []
        },
        dues: {
          operator: 3,
          value: null,
          lowerValue: null,
          upperValue: null
        },
        standing: {
          value: false
        },
        onlyShowRecentDetail: {
          value: false
        },
        campaign: {
          operator: null,
          values: []
        },
        fund: {
          operator: null,
          values: []
        },
        appeal: {
          operator: null,
          values: []
        },
        package: {
          operator: null,
          values: []
        },
        country: {
          operator: null,
          values: []
        },
        isDeceased: {
          value: false
        },
        isDoNotSolicit: {
          value: false
        },
        isGivingAnonymously: {
          value: false
        },
        preferredLanguage: {
          operator: null,
          values: []
        },
        constituentCode: {
          operator: null,
          values: []
        }
      }
    };
  },
  computed: {
    title() {
      return this.$t("page.reports.membership.titleReport");
    }
  },
  watch: {},
  methods: {
    changePagination(value) {
      this.options = value;
      if (this.mounted) {
        this.search();
      } else {
        this.mounted = true;
      }
    },
    exportData() {
      this.loadingExcel = true;
      const filters = this.buildPayload();
      axios
        .post("/membership/export", filters, { responseType: "blob" })
        .then(res => {
          const fileName = this.title;
          this.loadingExcel = false;
          this.downloadExcel(fileName, res.data);
          this.$store.commit("notification/showSnackbar", {
            message: "Success"
          });
        })
        .catch(err => {
          this.loadingExcel = false;
          console.log(err.message);
          this.$store.commit("notification/showSnackbar", {
            message: `Fail: ${err.message}`
          });
        });
    },
    downloadExcel(fileName, data) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
    search() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const sortOrder = sortDesc ? "DESC" : "ASC";
      const filters = this.buildPayload();
      axios
        .post("/membership/search", filters, { params: { page, size: itemsPerPage, sortBy, sortOrder } })
        .then(res => {
          this.result = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    buildPayload() {
      const filters = {};

      if (this.filters.onlyShowRecentDetail.value) {
        filters.onlyShowRecentDetail = this.filters.onlyShowRecentDetail.value === 1 ? true : false;
      }
      if (this.filters.joinDate.startDate || this.filters.joinDate.endDate) {
        filters.joinDate = {};
        filters.joinDate.startDate = this.filters.joinDate.startDate;
        filters.joinDate.endDate = this.filters.joinDate.endDate;
      }
      if (this.filters.startDate.startDate || this.filters.startDate.endDate) {
        filters.startDate = {};
        filters.startDate.startDate = this.filters.startDate.startDate;
        filters.startDate.endDate = this.filters.startDate.endDate;
      }
      if (this.filters.expiryDate.startDate || this.filters.expiryDate.endDate) {
        filters.expiryDate = {};
        filters.expiryDate.startDate = this.filters.expiryDate.startDate;
        filters.expiryDate.endDate = this.filters.expiryDate.endDate;
      }

      if (this.filters.program.values.length != 0 && this.filters.program.operator) {
        filters.membershipProgram = {};
        filters.membershipProgram.operator = this.filters.program.operator;
        filters.membershipProgram.values = this.filters.program.values;
      }
      if (this.filters.category.values.length != 0 && this.filters.category.operator) {
        filters.membershipCategory = {};
        filters.membershipCategory.operator = this.filters.category.operator;
        filters.membershipCategory.values = this.filters.category.values;
      }
      if (this.filters.transactionType.values.length != 0 && this.filters.transactionType.operator) {
        filters.membershipTransactionType = {};
        filters.membershipTransactionType.operator = this.filters.transactionType.operator;
        filters.membershipTransactionType.values = this.filters.transactionType.values;
      }
      if (this.filters.country.values.length != 0 && this.filters.country.operator) {
        filters.country = {};
        filters.country.operator = this.filters.country.operator;
        filters.country.values = this.filters.country.values;
      }
      if (this.filters.preferredLanguage.values.length != 0 && this.filters.preferredLanguage.operator) {
        filters.preferredLanguage = {};
        filters.preferredLanguage.operator = this.filters.preferredLanguage.operator;
        filters.preferredLanguage.values = this.filters.preferredLanguage.values;
      }
      if (this.filters.constituentCode.values.length != 0 && this.filters.constituentCode.operator) {
        filters.constituentCode = {};
        filters.constituentCode.operator = this.filters.constituentCode.operator;
        filters.constituentCode.values = this.filters.constituentCode.values;
      }

      if (this.filters.dues.lowerValue || this.filters.dues.upperValue) {
        filters.dues = {};
        filters.dues.operator = this.filters.dues.operator;
        filters.dues.upperValue = this.filters.dues.upperValue;
        filters.dues.lowerValue = this.filters.dues.lowerValue;
      }
      if (this.filters.dues.value) {
        filters.dues = {};
        filters.dues.operator = this.filters.dues.operator;
        filters.dues.value = this.filters.dues.value;
      }
      if (this.filters.isDeceased.value) {
        filters.isDeceased = {};
        filters.isDeceased.value = this.filters.isDeceased.value === 1 ? true : false;
      }
      if (this.filters.isDoNotSolicit.value) {
        filters.isDoNotSolicit = {};
        filters.isDoNotSolicit.value = this.filters.isDoNotSolicit.value === 1 ? true : false;
      }
      if (this.filters.isGivingAnonymously.value) {
        filters.isGivingAnonymously = {};
        filters.isGivingAnonymously.value = this.filters.isGivingAnonymously.value === 1 ? true : false;
      }
      return filters;
    }
  },
  created() {}
};
</script>
