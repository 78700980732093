export const state = {
    selected: null,
    nameSelected: null,
    isEdit: false,
    fundKey: 0,
    campaignKey: 0,
    appealKey: 0,
    editDialog: false
};

export const mutations = {
    clearSelected(state) {
        state.selected = null;
    },
    setSelected(state, fundId) {
        state.selected = fundId;
    },
    setNameSelected(state, fundName) {
        state.nameSelected = fundName;
    },
    setEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setFundKey(state) {
        state.fundKey = state.fundKey + 1;
    },
    setCampaignKey(state) {
        state.campaignKey = state.campaignKey + 1;
    },
    setAppealKey(state) {
        state.appealKey = state.appealKey + 1;
    },
    setEditDialog(state, editDialog) {
        state.editDialog = editDialog;
    },
};

export const actions = {};

export const getters = {
    selected: state => state.selected,
    nameSelected: state => state.nameSelected,
    isEdit: state => state.isEdit,
    fundKey: state => state.fundKey,
    campaignKey: state => state.campaignKey,
    appealKey: state => state.appealKey,
    editDialog: state => state.editDialog
}