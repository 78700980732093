import Vue from "vue";

export default {
  computed: {
    isProduction() {
      return process.env.NODE_ENV == "production";
    }
  },
  methods: {
    alert(content) {
      if (typeof content == "object" || Array.isArray(content)) content = JSON.stringify(content);
      alert(content);
    },
    log(content) {
      console.log(content);
    },
    NYI({ caller, action, content }) {
      if (typeof content == "object" || Array.isArray(content)) content = JSON.stringify(content);

      if (content != null) Vue.$log.debug(" NYI", caller + ": " + action, "  |  ", content);
      else Vue.$log.debug(caller + ": " + action);
    }
  }
};
