<template>
  <div class="col-m-12 offset-m-1 col">
    <v-sheet class="constituents-list_container" elevation="0">
      <v-container class="mt-xm">
        <v-sheet class="mb-sm">
          <v-row>
            <v-col>
              <h1 class="constituents-list_title">{{$t("appbar.links.users")}}</h1>
            </v-col>
            <v-col cols="3 d-flex align-center flex-row-reverse">
              <v-btn class="float-right" @click="onAddUser" color="secondary" small>{{
                $t("page.userList.addUser")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
        <v-data-table
          :headers="headers"
          :items="users"
          item-key="id"
          :loading="loading"
          :key="userKey"
          sort-by="fullName"
          :items-per-page="10"
        >
          <template v-slot:item.createdOn="{item}">{{item.createdOn | moment('YYYY-MM-DD')}}</template>
          <template v-slot:item.lastLogin="{item}">{{item.lastLogin | moment('YYYY-MM-DD')}}</template>
          <template v-slot:item.action="{item}">
            <slot name="row-action">
              <v-icon small class="mr-2" @click="openEditDialog(item)">mdi-pencil</v-icon>
              <v-icon small @click="openDeleteConfirmDialog(item.id)">mdi-delete</v-icon>
            </slot>
          </template>
        </v-data-table>
      </v-container>
      <edit-user v-model="editDialog"></edit-user>
      <confirm-dialog :title="$t('page.userList.deleteUser')" :dialog="deleteDialog" @btnClicked="hasConfirmedDelete($event)"></confirm-dialog>
    </v-sheet>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import { mapGetters } from "vuex";
import EditUser from "./EditUser.vue";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog.vue";

export default {
  components: {EditUser, ConfirmDialog},
  data() {
    return {
      users: [],
      loading: false,
      deleteDialog: false,
      editDialog: false,
      userIdToDelete: null,
      headers: [
        {
          text: this.$t("page.userList.headers.fullName"),
          value: 'fullName'
        },
        {
          text: this.$t("page.userList.headers.email"),
          value: 'email'
        },

        {
          text: this.$t("page.userList.headers.role"),
          value: 'role'
        },
        {
          text: this.$t("page.userList.headers.created"),
          value: "createdOn",
        },
        {
          text: this.$t("page.userList.headers.lastLogin"),
          value: "lastLogin",
        },
        {
          text: this.$t("page.userList.headers.actions"),
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userKey: "user/userKey",
    }),
  },
  watch: {
    userKey() {
      this.loadUsers();
    },
  },
  methods: {
    loadUsers() {
      this.loading = true;
      axios
        .get("/user")
        .then(res => {
          this.users = res.data || [];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
     },
    onAddUser() {
      this.$store.commit("user/setEdit", false);
      this.$store.commit("user/setEditDialog", true);
    },
    openEditDialog(user) {
      this.$store.commit("user/setSelected", user.id);
      this.$store.commit("user/setSelectedName", user.fullName);
      this.$store.commit("user/setEdit", true);
      this.$store.commit("user/setEditDialog", true);
    },
    openDeleteConfirmDialog(userId) {
      this.userIdToDelete = userId;
      this.deleteDialog = true;
    },
    hasConfirmedDelete(isConfirmed) {
      if (isConfirmed) {
        axios
          .delete(`user/${this.userIdToDelete}`)
          .then((res) => {
            
            this.$store.commit("user/setUserKey");
            this.$store.commit("notification/showSnackbar", {
              message: "Success",
            });
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail",
            });
          });
      }
      this.addressIdToDelete = null;
      this.deleteDialog = false;
    }
  },
  created(){
    this.loadUsers();
  }
};
</script>
