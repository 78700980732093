import axios from "../../plugins/axios";

export const state = {
  activities: [],
  selectedActivity: null,
  activityRefreshKey: 0
};
export const mutations = {
  clearSelectedActivity(state) {
    state.selectedActivity = null;
  },
  setActivities(state, activities) {
    if (activities == null) activities = [];
    state.activities = activities;
  },
  setSelectedActivity(state, activity) {
    state.selectedActivity = activity;
  },
  setActivityRefreshKey(state) {
    state.activityRefreshKey = state.activityRefreshKey + 1;
  }
};
export const actions = {
  deleteActivity({ dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`constituentActivity/${id}`)
        .then(() => {
          dispatch("loadActivities");
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  loadActivities({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      const id = rootGetters["constituent/id"];
      if (id == null) {
        reject();
        return;
      }
      axios
        .get(`constituentActivity/constituent/${id}`)
        .then(res => {
          var activities = res.data.data;
          commit("setActivities", activities);
          resolve();
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  loadActivity({ commit }, activityId) {
    return new Promise((resolve, reject) => {
      if (activityId == null || activityId < 0) {
        reject();
        return;
      }

      axios
        .get(`constituentActivity/${activityId}`)
        .then(res => {
          var activity = res.data;
          commit("setSelectedActivity", activity);
          resolve();
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  saveActivity({ dispatch, rootGetters }, { id, activityId, packageId, roleId }) {
    return new Promise((resolve, reject) => {
      const constituentId = rootGetters["constituent/id"];
      const toSave = { constituentId, id, activityId, packageId, roleId };

      axios
        .post("constituentActivity", toSave)
        .then(() => {
          dispatch("loadActivities");
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
};
export const getters = {
  activities: state => state.activities,
  selected: state => state.selectedActivity,
  getActivityRefreshKey: state => state.activityRefreshKey
};
