<template>
  <v-container class="background">
    <v-card v-if="validToken" class="reset_password-card" elevation="0">
      <v-container>
        <div class="login-card_header mb-sm mt-md">
          <h2 class="login-card_header-title">{{ title }}</h2>
        </div>
        <v-form ref="form" v-model="form" @keyup.native.enter="resetPassword()">
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                class="login-card_password"
                requiered
                v-model="credentials.password"
                :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                :label="$t('page.login.password.title')"
                autocomplete
                @click:append="showPassword = !showPassword"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                class="login-card_password"
                requiered
                v-model="credentials.confirmationPassword"
                :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :rules="[rules.requiredConfitmation, rules.notMatch]"
                :type="showPassword ? 'text' : 'password'"
                :label="$t('page.login.confirmationPassword.title')"
                autocomplete
                @click:append="showPassword = !showPassword"
              />
            </v-col>
            <v-col cols="12 mt-sm">
              <v-btn width="100%" height="45px" color="secondary" class="login-card_login-btn" @click="resetPassword()">{{ buttonText }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <div class="d-flex justify-center">
      <h1 v-if="invalidToken">Your Reset Password request has expired. Please ask for another Reset Password e-mail.</h1>
    </div>
  </v-container>
</template>
<script>
import debug from "../../mixins/debug";
import axios from "../../plugins/axios";

import { openEyeIcon } from "../../assets/icons/login/icon-visible.svg";
import { closedEyeIcon } from "../../assets/icons/login/icon-invisible.svg";

export default {
  components: {},
  mixins: [debug],
  data() {
    return {
      icons: {
        openEyeIcon,
        closedEyeIcon
      },
      form: null,
      validToken: false,
      invalidToken: false,
      showPassword: false,
      token: null,
      routePath: null,
      title: null,
      buttonText: null,
      endpoint: null,
      successMessage: null,
      credentials: { password: "", confirmationPassword: "" },
      rules: {
        required: value => !!value || this.$t("page.login.password.errors.required"),
        requiredConfitmation: value => !!value || this.$t("page.login.confirmationPassword.errors.required"),
        min: v => (v != null && v.length >= 8) || this.$t("page.login.password.errors.passwordLength"),
        notMatch: v => (v != null && v == this.credentials.password) || this.$t("page.login.confirmationPassword.errors.notMatch")
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    resetPassword() {
      if (this.$refs.form.validate()) {
        const body = {
          token: this.token,
          password: this.credentials.password,
          confirmPassword: this.credentials.confirmationPassword
        };
        axios
          .post(`auth${this.endpoint}`, body)
          .then(res => {
            this.$router.push({ name: "Login" });
            this.$store.commit("notification/showSnackbar", {
              message: this.successMessage
            });
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail"
            });
          });
      }
    },
    fillFields() {
      this.endpoint = this.$router.currentRoute.path;
      if (this.endpoint === "/resetpassword") {
        this.title = this.$t("page.login.title.resetPassword");
        this.buttonText = this.$t("page.login.title.resetPassword");
        this.successMessage = this.$t("page.forgotPassword.message.success");
      } else {
        this.title = this.$t("page.login.title.activatePassword");
        this.buttonText = this.$t("page.login.title.activatePassword");
        this.successMessage = this.$t("page.activatePassword.message.success");
      }
    }
  },
  created() {
    this.token = this.$route.query.token;
    if (this.token) {
      axios
        .post("auth/validateresettoken", { token: this.token })
        .then(res => {
          this.validToken = true;
          this.fillFields();
        })
        .catch(() => {
          this.invalidToken = true;
        });
    } else {
      this.invalidToken = true;
    }
  }
};
</script>
