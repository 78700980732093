<template>
  <v-card height="92vh" elevation="0" class="report_result-table_container">
    <v-row class="justify-space-between mt-0">
      <v-col cols="10">
        <v-btn class="mr-2" icon plain :to="{ name: 'builder' }">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="font-weight-bold">{{ $t("page.reports.constituent.NewReportTitle") }}</span>
      </v-col>
      <v-col cols="2" class="d-flex justify-end pt-1 align-center">
        <v-btn depressed color="secondary" small @click="drawer = true">
          {{ $t("app.actions.filters") }}
        </v-btn>
        <v-btn :loading="loadingExcel" icon color="green" large @click="exportData">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <report-constituent-filters @search="search" @hideFilters="drawer = false" :drawer="drawer" v-model="filters"></report-constituent-filters>
    <report-constituent-results class="pa-2" @changePagination="changePagination" :result="result" :loading="loading"></report-constituent-results>
  </v-card>
</template>
<script>
import axios from "../../../plugins/axios";
import ReportConstituentFilters from "./ReportConstituentFilters.vue";
import ReportConstituentResults from "./ReportConstituentResults.vue";

export default {
  name: "ReportConstituent",
  components: { ReportConstituentFilters, ReportConstituentResults },
  data() {
    return {
      result: null,
      mounted: false,
      options: {},
      drawer: false,
      loading: false,
      loadingExcel: false,

      filters: {
        transactionDate: {
          startDate: null,
          endDate: null
        },
        donationType: {
          operator: null,
          values: []
        },
        donationSubtype: {
          operator: null,
          values: []
        },
        amount: {
          operator: 3,
          value: null,
          lowerValue: null,
          upperValue: null
        },
        isAnonymous: {
          value: false
        },
        isDeceased: {
          value: false
        },
        isDoNotSolicit: {
          value: false
        },
        isGivingAnonymously: {
          value: false
        },
        isMatchingGift: false,
        acknowledgeStatus: {
          operator: null,
          values: []
        },
        campaign: {
          operator: null,
          values: []
        },
        fund: {
          operator: null,
          values: []
        },
        appeal: {
          operator: null,
          values: []
        },
        package: {
          operator: null,
          values: []
        },
        letterCode: {
          operator: null,
          values: []
        },
        budgetCode: {
          operator: null,
          values: []
        },
        program: {
          operator: null,
          values: []
        },
        category: {
          operator: null,
          values: []
        },
        transactionType: {
          operator: null,
          values: []
        },
        membershipLength: {
          operator: 3,
          lowerValue: null,
          upperValue: null,
          value: null
        },
        country: {
          operator: null,
          values: []
        },
        preferredLanguage: {
          operator: null,
          values: []
        },
        constituentCode: {
          operator: null,
          values: []
        },
        constituentType: {
          operator: null,
          values: []
        }
      }
    };
  },
  computed: {
    title() {
      return this.$t("page.reports.constituent.titleReport");
    }
  },
  watch: {},
  methods: {
    changePagination(value) {
      this.options = value;
      if (this.mounted) {
        this.search();
      } else {
        this.mounted = true;
      }
    },
    exportData() {
      this.loadingExcel = true;
      const filters = this.buildPayload();
      axios
        .post("/constituent/search-export", filters, { responseType: "blob" })
        .then(res => {
          const fileName = this.title;
          this.loadingExcel = false;
          this.downloadExcel(fileName, res.data);
          this.$store.commit("notification/showSnackbar", {
            message: "Success"
          });
        })
        .catch(err => {
          this.loadingExcel = false;
          this.$store.commit("notification/showSnackbar", {
            message: `Fail: ${err.message}`
          });
        });
    },
    downloadExcel(fileName, data) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
    search() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const sortOrder = sortDesc ? "DESC" : "ASC";
      const filters = this.buildPayload();
      axios
        .post("/constituent/search-report", filters, { params: { page, size: itemsPerPage, sortBy, sortOrder } })
        .then(res => {
          this.result = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    buildPayload() {
      const filters = {};

      // Membership Filters
      if (this.filters.program.operator) {
        if (this.filters.program.operator == 3 || this.filters.program.operator == 4) {
          filters.membershipProgram = {};
          filters.membershipProgram.operator = this.filters.program.operator;
        } else if (this.filters.program.values.length != 0) {
          filters.membershipProgram = {};
          filters.membershipProgram.operator = this.filters.program.operator;
          filters.membershipProgram.values = this.filters.program.values;
        }
      }
      if (this.filters.category.operator) {
        if (this.filters.category.operator == 3 || this.filters.category.operator == 4) {
          filters.membershipCategory = {};
          filters.membershipCategory.operator = this.filters.category.operator;
        } else if (this.filters.category.values.length != 0) {
          filters.membershipCategory = {};
          filters.membershipCategory.operator = this.filters.category.operator;
          filters.membershipCategory.values = this.filters.category.values;
        }
      }
      if (this.filters.transactionType.operator) {
        if (this.filters.transactionType.operator == 3 || this.filters.transactionType.operator == 4) {
          filters.membershipTransactionType = {};
          filters.membershipTransactionType.operator = this.filters.transactionType.operator;
        } else if (this.filters.transactionType.values.length != 0) {
          filters.membershipTransactionType = {};
          filters.membershipTransactionType.operator = this.filters.transactionType.operator;
          filters.membershipTransactionType.values = this.filters.transactionType.values;
        }
      }
      if (this.filters.membershipLength.lowerValue || this.filters.membershipLength.upperValue) {
        filters.membershipLength = {};
        filters.membershipLength.operator = this.filters.membershipLength.operator;
        filters.membershipLength.upperValue = this.filters.membershipLength.upperValue;
        filters.membershipLength.lowerValue = this.filters.membershipLength.lowerValue;
      }
      if (this.filters.membershipLength.value) {
        filters.membershipLength = {};
        filters.membershipLength.operator = this.filters.membershipLength.operator;
        filters.membershipLength.value = this.filters.membershipLength.value;
      }

      // Donation Filters
      if (this.filters.transactionDate.startDate || this.filters.transactionDate.endDate) {
        filters.transactionDate = {};
        filters.transactionDate.startDate = this.filters.transactionDate.startDate;
        filters.transactionDate.endDate = this.filters.transactionDate.endDate;
      }
      if (this.filters.donationType.operator) {
        if (this.filters.donationType.operator == 3 || this.filters.donationType.operator == 4) {
          filters.donationType = {};
          filters.donationType.operator = this.filters.donationType.operator;
        } else if (this.filters.donationType.values.length != 0) {
          filters.donationType = {};
          filters.donationType.operator = this.filters.donationType.operator;
          filters.donationType.values = this.filters.donationType.values;
        }
      }
      if (this.filters.donationSubtype.operator) {
        if (this.filters.donationSubtype.operator == 3 || this.filters.donationSubtype.operator == 4) {
          filters.donationSubtype = {};
          filters.donationSubtype.operator = this.filters.donationSubtype.operator;
        } else if (this.filters.donationSubtype.values.length != 0) {
          filters.donationSubtype = {};
          filters.donationSubtype.operator = this.filters.donationSubtype.operator;
          filters.donationSubtype.values = this.filters.donationSubtype.values;
        }
      }
      if (this.filters.amount.lowerValue || this.filters.amount.upperValue) {
        filters.totalAmount = {};
        filters.totalAmount.operator = this.filters.amount.operator;
        filters.totalAmount.upperValue = this.filters.amount.upperValue;
        filters.totalAmount.lowerValue = this.filters.amount.lowerValue;
      }
      if (this.filters.amount.value) {
        filters.totalAmount = {};
        filters.totalAmount.operator = this.filters.amount.operator;
        filters.totalAmount.value = this.filters.amount.value;
      }
      if (this.filters.isAnonymous.value) {
        filters.isAnonymous = {};
        filters.isAnonymous.value = this.filters.isAnonymous.value === 1 ? true : false;
      }
      if (this.filters.isDeceased.value) {
        filters.isDeceased = {};
        filters.isDeceased.value = this.filters.isDeceased.value === 1 ? true : false;
      }
      if (this.filters.isDoNotSolicit.value) {
        filters.isDoNotSolicit = {};
        filters.isDoNotSolicit.value = this.filters.isDoNotSolicit.value === 1 ? true : false;
      }
      if (this.filters.isGivingAnonymously.value) {
        filters.isGivingAnonymously = {};
        filters.isGivingAnonymously.value = this.filters.isGivingAnonymously.value === 1 ? true : false;
      }
      if (this.filters.isMatchingGift) {
        filters.isMatchingGift = this.filters.isMatchingGift === 1 ? true : false;
      }
      if (this.filters.acknowledgeStatus.operator) {
        if (this.filters.acknowledgeStatus.operator == 3 || this.filters.acknowledgeStatus.operator == 4) {
          filters.acknowledgeStatus = {};
          filters.acknowledgeStatus.operator = this.filters.acknowledgeStatus.operator;
        } else if (this.filters.acknowledgeStatus.values.length != 0) {
          filters.acknowledgeStatus = {};
          filters.acknowledgeStatus.operator = this.filters.acknowledgeStatus.operator;
          filters.acknowledgeStatus.values = this.filters.acknowledgeStatus.values;
        }
      }
      if (this.filters.campaign.operator) {
        if (this.filters.campaign.operator == 3 || this.filters.campaign.operator == 4) {
          filters.campaign = {};
          filters.campaign.operator = this.filters.campaign.operator;
        } else if (this.filters.campaign.values.length != 0) {
          filters.campaign = {};
          filters.campaign.operator = this.filters.campaign.operator;
          filters.campaign.values = this.filters.campaign.values;
        }
      }
      if (this.filters.fund.operator) {
        if (this.filters.fund.operator == 3 || this.filters.fund.operator == 4) {
          filters.fund = {};
          filters.fund.operator = this.filters.fund.operator;
        } else if (this.filters.fund.values.length != 0) {
          filters.fund = {};
          filters.fund.operator = this.filters.fund.operator;
          filters.fund.values = this.filters.fund.values;
        }
      }
      if (this.filters.appeal.operator) {
        if (this.filters.appeal.operator == 3 || this.filters.appeal.operator == 4) {
          filters.appeal = {};
          filters.appeal.operator = this.filters.appeal.operator;
        } else if (this.filters.appeal.values.length != 0) {
          filters.appeal = {};
          filters.appeal.operator = this.filters.appeal.operator;
          filters.appeal.values = this.filters.appeal.values;
        }
      }
      if (this.filters.package.operator) {
        if (this.filters.package.operator == 3 || this.filters.package.operator == 4) {
          filters.package = {};
          filters.package.operator = this.filters.package.operator;
        } else if (this.filters.package.values.length != 0) {
          filters.package = {};
          filters.package.operator = this.filters.package.operator;
          filters.package.values = this.filters.package.values;
        }
      }
      if (this.filters.letterCode.operator) {
        if (this.filters.letterCode.operator == 3 || this.filters.letterCode.operator == 4) {
          filters.letterCode = {};
          filters.letterCode.operator = this.filters.letterCode.operator;
        } else if (this.filters.letterCode.values.length != 0) {
          filters.letterCode = {};
          filters.letterCode.operator = this.filters.letterCode.operator;
          filters.letterCode.values = this.filters.letterCode.values;
        }
      }
      if (this.filters.budgetCode.operator) {
        if (this.filters.budgetCode.operator == 3 || this.filters.budgetCode.operator == 4) {
          filters.budgetCode = {};
          filters.budgetCode.operator = this.filters.budgetCode.operator;
        } else if (this.filters.budgetCode.values.length != 0) {
          filters.budgetCode = {};
          filters.budgetCode.operator = this.filters.budgetCode.operator;
          filters.budgetCode.values = this.filters.budgetCode.values;
        }
      }

      // Constituent Filters
      if (this.filters.country.operator) {
        if (this.filters.country.operator == 3 || this.filters.country.operator == 4) {
          filters.country = {};
          filters.country.operator = this.filters.country.operator;
        } else if (this.filters.country.values.length != 0) {
          filters.country = {};
          filters.country.operator = this.filters.country.operator;
          filters.country.values = this.filters.country.values;
        }
      }
      if (this.filters.preferredLanguage.operator) {
        if (this.filters.preferredLanguage.operator == 3 || this.filters.preferredLanguage.operator == 4) {
          filters.preferredLanguage = {};
          filters.preferredLanguage.operator = this.filters.preferredLanguage.operator;
        } else if (this.filters.preferredLanguage.values.length != 0) {
          filters.preferredLanguage = {};
          filters.preferredLanguage.operator = this.filters.preferredLanguage.operator;
          filters.preferredLanguage.values = this.filters.preferredLanguage.values;
        }
      }
      if (this.filters.constituentCode.operator) {
        if (this.filters.constituentCode.operator == 3 || this.filters.constituentCode.operator == 4) {
          filters.constituentCode = {};
          filters.constituentCode.operator = this.filters.constituentCode.operator;
        } else if (this.filters.constituentCode.values.length != 0) {
          filters.constituentCode = {};
          filters.constituentCode.operator = this.filters.constituentCode.operator;
          filters.constituentCode.values = this.filters.constituentCode.values;
        }
      }
      if (this.filters.constituentType.operator) {
        if (this.filters.constituentType.operator == 3 || this.filters.constituentType.operator == 4) {
          filters.constituentType = {};
          filters.constituentType.operator = this.filters.constituentType.operator;
        } else if (this.filters.constituentType.values.length != 0) {
          filters.constituentType = {};
          filters.constituentType.operator = this.filters.constituentType.operator;
          filters.constituentType.values = this.filters.constituentType.values;
        }
      }

      return filters;
    }
  },
  created() {}
};
</script>
