<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card class="constituents-addresses_container">
      <v-card-title class="constituents-addresses_header">
        <h4 class="dontcutword constituents-details_title">{{ $t(title) }}</h4>
      </v-card-title>
      <v-card-text>
        {{ $t(text) }}
        <span v-for="(txt, index) in multilineText" :key="'mltext' + index"> <br />{{ txt }} </span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="showLeftBtn" color="red darken-1" text @click="btnClicked(false)">
          {{ $t(btnLeftLabel) }}
        </v-btn>
        <v-btn color="secondary darken-1" text @click="btnClicked(true)">
          {{ $t(btnRightLabel) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: "Are you sure you want to delete this entry?"
    },
    text: {
      type: String,
      default: ""
    },
    multilineText: {
      type: Array,
      // default: function() {
      //   return [""];
      // },
      required: false
    },
    btnLeftLabel: {
      type: String,
      default: "Cancel"
    },
    btnRightLabel: {
      type: String,
      default: "Confirm"
    },
    showLeftBtn: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    btnClicked(answer) {
      this.$emit("btnClicked", answer);
    }
  }
});
</script>
