import axios from '../../plugins/axios'

export const state = {
    relationship: {},
    relationships: [],
    relationshipTypes: [],
    reciprocalRelationshipTypes: [],
    relationshipRefreshKey: 0
};
export const mutations = {
    setRelationshipRefreshKey(state) {
        state.relationshipRefreshKey = state.relationshipRefreshKey + 1;
      },
   
    addRelationship(state, relationship) {
        state.relationships.push(relationship)
    },
    clearCurrentRelationship(state) {
        state.relationship = {
                dateFrom: null,
                dateTo: null
            }
    },
    setRelationship(state, relationship) {
        state.relationship = relationship
    },
    setRelationships(state, relationships) {
        if (relationships == null) {
            relationships = []
        }
        state.relationships = relationships
    },
    setReciprocalRelationshipTypes(state, types) {
        if (types == null)
            types = []
        state.reciprocalRelationshipTypes = types
    },
    setRelationshipTypes(state, types) {
        if (types == null)
            types = []
        state.relationshipTypes = types
    }
}
export const actions = {
    deleteRelationship({ dispatch, rootGetters }, { id }) {
        return new Promise(resolve => {
            axios.delete(`relationship/${id}`).then(() => {
                const constituentId = rootGetters['constituent/id']
                dispatch('loadRelationships', constituentId)
                resolve()
            })
        })
    },
    // loadRelationship({ commit }, { relationshipId, constituentId }) {
    //     return new Promise((resolve, reject) => {
    //         axios.get(`relationship/${relationshipId}/constituent/${constituentId}`).then(res => {
    //             const relationship = res.data
    //             commit('setRelationship', relationship)
    //             resolve();
    //         }).catch((e) => reject(e))
    //     })
    // },
    // loadRelationships({ commit }, constituentId) {
    //     return new Promise((resolve, reject) => {
    //         axios.get(`relationship/constituent/${constituentId}`).then(res => {
    //             let relationships = res.data
    //             commit('setRelationships', relationships)
    //             resolve()
    //         }).catch(e => reject(e))
    //     });
    // },
    // loadRelationshipTypes({ commit }, constituentTypeId) {
    //     return new Promise((resolve, reject) => {
    //         const params = { constituentTypeId }
    //         axios.get(`relationship/types`, { params }).then(res => {
    //             const types = res.data;
    //             commit('setRelationshipTypes', types);
    //             resolve()
    //         }).catch(e => reject(e))
    //     });
    // },
    // saveCurrent({ dispatch, rootGetters }, { id, isActive, dateFrom, dateTo, relationTypeName, relationTypeId, linkedConstituentId, linkedRelationTypeId, role, department, comment, isLinkedConstituent }) {
    //     return new Promise((resolve, reject) => {
    //         var constituentId = rootGetters['constituent/id']
    //         const toSave = { id, constituentId, isActive, dateFrom, dateTo, relationTypeName, relationTypeId, linkedRelationTypeId, linkedConstituentId, department, role, comment, isLinkedConstituent };

    //         axios.post(`relationship`, toSave).then(res => {
    //             dispatch('loadRelationships', constituentId)
    //             resolve()
    //         }).catch(e => reject(e))
    //     })
    // }
}
export const getters = {
    relationshipRefreshKey: state => state.relationshipRefreshKey,
    relationships: state => state.relationships,
    types: state => state.relationshipTypes,
    relationship: state => state.relationship,
    reciprocalRelationshipTypes: state => state.reciprocalRelationshipTypes
}