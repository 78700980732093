<template>
  <div>
    <v-container fluid>
      <v-toolbar elevation="0">
        <v-row>
          <v-col offset="10 pr-0 text-end">
            <v-btn color="secondary" small @click="onAddBenefit">{{$t('component.categoryDetails.addBenefit')}}</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table 
        :items="benefits" 
        item-key="id" 
        :headers="headers"
        :loading="loading" 
        :items-per-page="5"
      >
       <template v-slot:item.action="{item}">
          <slot name="row-action">
            <v-icon small class="" @click="onClickEdit(null, item)">mdi-pencil</v-icon>
            <v-icon small @click="onDeleteAppeal(item)">mdi-delete</v-icon>
          </slot>
        </template>
      </v-data-table>
    </v-container>
    <add-benfit-membeship-category-dialog 
      v-model="addBenefitDialog"
      :addBenefitDialog="addBenefitDialog"
      @close="addBenefitDialog = false"
    ></add-benfit-membeship-category-dialog>
    <confirm-dialog :dialog="deleteBenefitDialog" @btnClicked="hasConfirmedDelete($event)"></confirm-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from "../../plugins/axios";
import AddBenfitMembeshipCategoryDialog from './AddBenfitMembeshipCategoryDialog.vue'
import ConfirmDialog from '../Dialogs/ConfirmDialog.vue';

export default {
  components: { AddBenfitMembeshipCategoryDialog, ConfirmDialog },
  data: () => ({
    benefits: [],
    addBenefitDialog: false,
    deleteBenefitDialog: false,
    benefitToDelete: null,
    loading: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("component.categoryDetails.benefitName"),
          value: "benefitName"
        },
        {
          text: this.$t("app.common.actions"),
          value: "action",
          width: "10%",
          sortable: false,
          align: "center"
        },
      ];
    },
    ...mapGetters({
      benefitKey: "category/benefitKey",
      categoryId: "category/selected",
      isEdit: "category/isEdit",
    }),
  },
  watch: {
    benefitKey() {
      this.loadBenefits();
    }
  },
  methods: {
    loadBenefits() {
      this.loading = true;
      axios
        .get(`MembershipCategory/${this.categoryId}/Benefits`)
        .then(res => {
          this.benefits = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail"
          });
          this.loading = false;
        });
    },
    onAddBenefit() {
      this.addBenefitDialog = true;
    },
    onClickEdit(event, row) {
      this.$store.commit("category/setCategoryBenefitSelected", row);
      this.addBenefitDialog = true;
    },
    onDeleteAppeal(appeal) {
      this.deleteBenefitDialog = true;
      this.benefitToDelete = appeal;
    },
    hasConfirmedDelete(isConfirmed) {
      if (isConfirmed) {
        const benefitId = this.benefitToDelete.id;
        this.benefitToDelete.fundId = null;
        
        axios
          .delete(`MembershipCategoryBenefit/${benefitId}`)
          .then(() => {
            this.loadBenefits();
            this.$store.commit("notification/showSnackbar", {
              message: "Success"
            });
            this.deleteBenefitDialog = false;
            this.benefitToDelete = null;
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail"
            });
          });
      } else {
        this.deleteBenefitDialog = false;
        this.benefitToDelete = null;
      }
    },
  },
  created() {
    if(this.isEdit)
      this.loadBenefits();
  }
};
</script>