import PlaceholderLayout from "../../views/PlaceholderLayout";
import store from "../../store";

const ConstituentListPage = () => import("../../views/Constituent/ConstituentList");
const ConstituentLayout = () => import("../../views/Constituent/ConstituentLayout");
const ConstituentDetailsPage = () => import("../../views/Constituent/ConstituentDetails");
const ConstituentContactInfo = () => import("../../views/Constituent/ConstituentContactInfo");
const ConstituentAddressesPage = () => import("../../views/Constituent/ConstituentAddresses");
const ConstituentRelationshipsPage = () => import("../../views/Constituent/ConstituentRelationship");
const ConstituentDonationsPage = () => import("../../views/Constituent/ConstituentDonations");
const ConstituentNotesPage = () => import("../../views/Constituent/ConstituentNotes");
const ConstituentPledgesPage = () => import("../../views/Constituent/ConstituentPledges");
const ConstituentRecurringGiftsPage = () => import("../../views/Constituent/ConstituentRecurringGifts");
const ConstituentActionsPage = () => import("../../views/Constituent/ConstituentActions");
const ConstituentCommunicationsPage = () => import("../../views/Constituent/ConstituentCommunications");
const ConstituentMembershipsPage = () => import("../../views/Constituent/ConstituentMembership");
const ConstituentSubscriptionsPage = () => import("../../views/Constituent/ConstituentSubscriptions");
//const ConstituentBioUpperMenu = () => import("../../views/Constituent/ConstituentDetailsUpperMenu");
const ConstituentProfilePage = () => import("../../views/Constituent/ConstituentProfile");
const ConstituentActivitiesPage = () => import("../../views/Constituent/ConstituentActivities");

import * as Drawers from "../../utils/drawers";

export default {
  path: "/constituents",
  component: PlaceholderLayout,
  children: [
    {
      path: "create",
      components: {
        default: ConstituentLayout
      },
      props: { default: true, constituentId: -1 },
      children: [
        {
          path: "",
          name: "CreateConstituent",
          components: {
            default: ConstituentDetailsPage,
            //upperMenu: ConstituentBioUpperMenu
          }
        }
      ],
      beforeEnter: (to, from, next) => {
        var type = to.params.type || "Individual";
        Promise.all([
          store.commit("constituent/clearSelected"),
          store.commit("contactInformation/clearContactInformation"),
          store.commit("contactInformation/setContactInformations", []),
          store.commit("contactInformation/setPhoneNumbers"),
          store.commit("contactInformation/setEmailAddresses"),
          store.commit("contactInformation/setLinks"),
          store.commit("constituent/setSelected", { constituentType: type })
        ]).then(() => next());
        // .catch(x => {
        // if (process.env.NODE_ENV != 'production')
        //     console.log('navigation error: ', JSON.stringify(x, null, 2))
        // next()
        // });
      }
    },
    {
      path: ":constituentId",
      components: {
        default: ConstituentLayout
        //sidebar: ConstituentDetailsDrawer
      },
      props: { default: true, constituentId: route => ({ search: route.query.q }) },
      // meta: { drawer: Drawers.ConstituentNav },
      beforeEnter: (to, from, next) => {
        Promise.all([
          // store.commit("constituent/clearSelected"),
          // store.commit("contactInformation/clearContactInformation"),
          // store.commit("address/clearAddresses"),
          store.dispatch("constituent/setConstituentId", to.params.constituentId),  //On le laisse temporairement
          store.dispatch("constituent/setConstituentType", to.params.constituentId)
        ])
          .then(x => {
            //   store.dispatch("address/loadAddresses", {})
            //   store.dispatch("contactInformation/loadContactInformations", to.params.constituentId);
            next()
          })
          .catch(x => {
            if (process.env.NODE_ENV != "production") console.log("navigation error: ", JSON.stringify(x, null, 2));
            next();
          });
      },
      children: [
        {
          path: "contactinfo",
          name: "ConstituentContactInfo",
          component: ConstituentContactInfo
        },
        {
          path: "addresses",
          name: "ConstituentAddresses",
          component: ConstituentAddressesPage
        },
        {
          path: "bio",
          name: "ConstituentDetails",
          components: {
            default: ConstituentDetailsPage,
            //upperMenu: ConstituentBioUpperMenu
          }
        },
        {
          path: "relationships",
          name: "ConstituentRelationships",
          component: ConstituentRelationshipsPage
        },
        { path: "donations/:donationId?", name: "ConstituentDonations", component: ConstituentDonationsPage },
        { path: "notes", name: "ConstituentNotes", component: ConstituentNotesPage },
        { path: "actions", name: "ConstituentActions", component: ConstituentActionsPage },
        { path: "communications", name: "ConstituentCommunications", component: ConstituentCommunicationsPage },
        { path: "memberships", name: "ConstituentMemberships", component: ConstituentMembershipsPage },
        { path: "subscriptions", name: "ConstituentSubscriptions", component: ConstituentSubscriptionsPage },
        { path: "activities", name: "ConstituentActivities", component: ConstituentActivitiesPage },
        { path: "pledges", name: "ConstituentPledges", component: ConstituentPledgesPage },
        { path: "recurringGifts", name: "ConstituentRecurringGifts", component: ConstituentRecurringGiftsPage }
        //{ path: 'profile', name: 'ConstituentProfile', component: ConstituentProfilePage }
      ]
    },
    {
      path: "",
      name: "ConstituentList",
      components: {
        default: ConstituentListPage
      }
    }
  ]
};
