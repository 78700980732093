import axios from "../../plugins/axios";

export const state = {
  addresses: [],
  selectedAddress: null,
  types: [],
  addressesRefreshKey: 0
};

export const mutations = {
  setAddressesRefreshKey(state) {
    state.addressesRefreshKey = state.addressesRefreshKey + 1;
  },
  clearAddresses(state) {
    state.addresses = [];
  },
  setAddresses(state, addresses) {
    if (addresses == null) {
      addresses = [];
    }

    state.addresses = addresses;
  },
  setAddressTypes(state, types) {
    if (types == null) {
      types = [];
    }
    state.types = types;
  },
  setSelectedAddress(state, address) {
    state.selectedAddress = { ...address };
  },
  /**
   * Update the current address array with updated values and append new entries
   * @param {*} state
   * @param {Array} newAddresses
   */
  updateAddresses(state, newAddresses) {
    if (newAddresses == null) {
      newAddresses = [];
    }
    let addresses = state.addresses;
    newAddresses.forEach(address => {
      let idx = addresses.findIndex(x => x.id == address.id);

      if (idx == -1) {
        addresses.push(address);
        state.selectedAddress = address;
      } else {
        let temp = Object.assign(addresses[idx], address);
        addresses.splice(idx, 1, temp);
      }

      state.addresses = addresses;
    });
  }
};

export const actions = {
  setAddress({ commit }, payload) {
    commit("setSelectedAddress", payload);
  },
  setAddresses({ commit }, payload) {
    commit("setAddresses", payload);
  },
  loadAddress({ commit }, { addressId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`address/${addressId}`)
        .then(res => {
          let address = res.data;
          commit("setSelectedAddress", address);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  loadAddresses({ commit, rootGetters }, { constituentId }) {
    if (constituentId == null) {
      constituentId = rootGetters["constituent/id"];
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`address/constituent/${constituentId}`)
        .then(res => {
          let addresses = res.data;
          commit("setAddresses", addresses);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  loadAddressTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`address/types`)
        .then(res => {
          let types = res.data;
          commit("setAddressTypes", types);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  // loadPreferedAddress({ commit, rootGetters }, { constituentId }) {
  //   if (!constituentId) {
  //     constituentId = rootGetters["constituent/selected"].id;
  //   }
  //   return new Promise((resolve, reject) => {
  //     let req = axios
  //       .get(`/address/constituent/${constituentId}/prefered`)
  //       .then(res => {
  //         let address = res.data;
  //         commit("updateAddresses", [address]);
  //         resolve();
  //       })
  //       .catch(e => reject(e));
  //   });
  // },
  saveAddress({ commit }, { id, line1, line2, city, postalCode, stateId, addressTypeId, isPrimary, toDate, fromDate, constituentId, countryId }) {
    return new Promise((resolve, reject) => {
      let req;
      let address = {
        id,
        line1,
        line2,
        city,
        stateId,
        postalCode,
        addressTypeId,
        isPrimary: isPrimary == null ? false : isPrimary,
        toDate,
        fromDate,
        constituentId,
        countryId
      };
      if (id) {
        req = axios.put(`/address/${id}`, address);
      } else {
        req = axios.post(`/address`, address);
      }

      req
        .then(res => {
          let addressRes = res.data;
          commit("updateAddresses", [addressRes]);
          resolve();
        })
        .catch(e => reject(e));
    });
  },
  setAddressActive({ commit }, { addressId, isActive }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/address/${addressId}/set-active`, { isActive })
        .then(res => {
          let address = res.data;
          commit("updateAddresses", [address]);
          resolve();
        })
        .catch(e => reject(e)); 
    });
  },
  setPrimaryAddress({ commit }, { addressId, isPrimary }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/address/${addressId}/set-primary`, { isPrimary })
        .then(() => {
          resolve();
        })
        .catch(e => reject(e));
    });
  }
};

export const getters = {
  addressesRefreshKey: state => state.addressesRefreshKey,
  addresses: state => state.addresses,
  selectedAddress: state => state.selectedAddress,
  types: state => state.types,
  preferedAddress: state => (state.addresses ? state.addresses.find(x => x.isPrimary) : null)
};
