<template>
  <div>
    <v-card outlined class="mt-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <span class="text-uppercase pr-2 ma-auto">{{ $t("app.actions.filter") }}:</span>
            <v-btn elevation="0" small @click="onToggleShowActive" class="mr-2" :class="showActive ? 'selected' : ''">
              {{ $t("app.actions.showActive") }}
            </v-btn>
            <v-btn elevation="0" small @click="onToggleShowInactive" :class="showInactive ? 'selected' : ''">
              {{ $t("app.actions.showInactive") }}
            </v-btn>
            <v-spacer />
            <v-btn color="secondary" small @click="onAddCategory">{{ $t("app.actions.add") }}</v-btn>
          </v-col>
        </v-row>
        <v-data-table :items="categories" item-key="id" :headers="headers" :loading="loading" @dblclick:row="onClickDetail" :items-per-page="10">
          <template v-slot:item.action="{ item }">
            <slot name="row-action">
              <v-icon small class="" @click="onClickEdit(null, item)">mdi-pencil</v-icon>
              <v-icon small @click="onClickDelete(null, item)">mdi-delete</v-icon>
            </slot>
          </template>
          <template v-slot:item.isLifetimeMembership="{ item }">
            <v-icon x-small :class="item.isLifetimeMembership ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
          <template v-slot:item.isActive="{ item }">
            <v-icon x-small :class="item.isActive ? 'isActive' : 'isInactive'"> mdi-checkbox-blank-circle </v-icon>
          </template>
        </v-data-table>
        <edit-category-dialog v-model="editDialog" @close="dialog = false"></edit-category-dialog>
      </v-card-text>
    </v-card>
    <confirm-dialog :dialog="deleteDialog" @btnClicked="hasConfirmedDelete($event)"></confirm-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EditCategoryDialog from "../../components/ConfigCategory/EditCategoryDialog";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog.vue";
import axios from "../../plugins/axios";

export default {
  components: { EditCategoryDialog, ConfirmDialog },
  data() {
    return {
      editDialog: false,
      deleteDialog: false,
      isEdit: false,
      categories: [],
      categoryIdToDelete: null,
      loading: false,
      showInactive: false,
      showActive: true,
    };
  },
  computed: {
    ...mapGetters({
      categoryKey: "category/categoryKey",
    }),
    headers() {
      return [
        {
          text: this.$t("page.categoryConfig.name"),
          value: "categoryTypeName",
        },
        {
          text: this.$t("page.categoryConfig.program"),
          value: "membershipProgramName",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("page.categoryConfig.amount"),
          value: "amount",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("page.categoryConfig.maximumMembers"),
          value: "maximumMembers",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("page.categoryConfig.benefits"),
          value: "",
          align: "center",
        },
        {
          text: this.$t("page.categoryConfig.isLifetime"),
          value: "isLifetimeMembership",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("page.categoryConfig.active"),
          value: "isActive",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("app.common.actions"),
          value: "action",
          width: "10%",
          sortable: false,
          align: "center",
        },
      ];
    },
  },
  watch: {
    categoryKey() {
      this.loadCategories();
    },
  },
  methods: {
    loadCategories() {
      this.loading = true;
      axios
        .post("MembershipCategory/search", {
          getActive: this.showActive,
          getInactive: this.showInactive,
        })
        .then((res) => {
          this.categories = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("notification/showSnackbar", {
            message: "Fail",
          });
        });
    },
    setCategory(category) {
      const categoryId = category && category.id;
      const categoryName = category && category.categoryTypeName;

      this.$store.commit("category/setSelected", categoryId);
      this.$store.commit("category/setEdit", true);
      this.$store.commit("category/setEditDialog", true);
      this.$store.commit("category/setNameSelected", categoryName);
    },
    onClickEdit(event, row) {
      this.setCategory(row);
    },
    onClickDelete(event, row) {
      this.categoryIdToDelete = row && row.id;
      this.deleteDialog = true;
    },
    onClickDetail(event, row) {
      const category = row && row.item;
      this.setCategory(category);
    },
    onAddCategory() {
      this.$store.commit("category/setEdit", false);
      this.$store.commit("category/setEditDialog", true);
    },
    onToggleShowActive() {
      this.showActive = !this.showActive;
      this.loadCategories();
    },
    onToggleShowInactive() {
      this.showInactive = !this.showInactive;
      this.loadCategories();
    },
    hasConfirmedDelete(isConfirmed) {
      if (isConfirmed) {
        axios
          .delete(`MembershipCategory/${this.categoryIdToDelete}`)
          .then(() => {
            this.$store.commit("category/setCategoryKey");
            this.$store.commit("notification/showSnackbar", {
              message: "Success",
            });
            this.deleteDialog = false;
          })
          .catch(() => {
            this.$store.commit("notification/showSnackbar", {
              message: "Fail",
            });
          });
      } else {
        this.deleteDialog = false;
      }
    },
  },
  created() {
    this.loadCategories();
  },
};
</script>